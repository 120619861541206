import React, {Component} from "react";
import {Helmet} from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";

import "./PrivacyPolicy.css";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className='container-fluid px-0'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            Privacy Policy - Finance Plus | Real Estate, NHF, Mortgages &amp;
            Home Loans
          </title>
          <link
            rel='canonical'
            href='http://financeplus.ng/legal/privacy-policy'
          />
        </Helmet>
        <Header history={this.props.history} type='others' />
        <section id='fp-landing-faqs' className='fp-landing-faqs'>
          <div className='fp-landing-faqs-circle-pattern'></div>
          <div className='fp-landing-faqs-contents-header-wrapper'>
            <h2 className='text-sm-center text-md-center text-lg-center text-xs-center'>
              Privacy Policy
            </h2>
          </div>
          <div className='container'>
            <div className='fp-landing-faqs-contents-wrapper privacy'>
              <div className='row'>
                <div className='col-md-12'>
                  <h4>Our commitment to privacy</h4>

                  <p>
                    Your privacy is important to us. To better protect your
                    privacy we provide this notice explaining our information
                    practices and the choices you can make about the way your
                    information is collected and used. To make this notice easy
                    to find, we make it available on every pages of our website.
                  </p>

                  <h4>The information we collect</h4>

                  <p>
                    This notice applies to all information collected or
                    submitted on the Finance Plus’s website or via phone with a
                    Finance Plus representative. The types of information
                    collected are personal, billing and project related. We
                    might also collect information from you regarding third
                    parties in relation to you (i.e. hosting company etc.)
                  </p>

                  <h4>The way we use information</h4>
                  <div className='flat-divider d20px'></div>

                  <p>
                    We use the information you provide about yourself to provide
                    services. We do not share this information with outside
                    parties except to the extent necessary to complete your
                    request for our services. Finance Plus reserves the right to
                    reveal this information pursuant to a valid subpoena or
                    court order.We use return email addresses to answer the
                    email we receive for tech support, customer service,
                    tell-a-friend, email updates. Such addresses are not used
                    for any other purpose and are not shared with outside
                    parties. Finally, we never use or share the personally
                    identifiable information provided to us online, via phone,
                    or in person, in ways unrelated to the ones described above
                    without also providing you an opportunity to opt-out or
                    otherwise prohibit such unrelated uses.
                  </p>

                  <h4>Our commitment to children’s privacy</h4>

                  <p>
                    This website may provide links to third-party websites. Any
                    such links are provided solely as a convenience to you.
                    Finance Plus has no control over these websites or their
                    content and does not assume any responsibility or liability
                    for these websites. Finance Plus does not endorse or make
                    any representations about these websites, or any
                    information, materials, or products found thereon. If you
                    access any of the third-party websites linked on this Web
                    site, you do so at your own risk.
                  </p>

                  <h4>How you can access or correct your information</h4>

                  <p>
                    At any time, you may later review or update the personal
                    information we have collected online from you by contacting
                    us at{" "}
                    <a href='mailto:support@financeplus.ng'>
                      support@financeplus.ng
                    </a>
                    . To protect your privacy and security, we will also take
                    reasonable steps to verify your identity before granting
                    access or making corrections.
                  </p>

                  <h4>How to contact us</h4>

                  <p>
                    Should you have other questions or concerns about these
                    privacy policies, please contact us via email at{" "}
                    <a href='mailto:support@financeplus.ng'>
                      support@financeplus.ng
                    </a>
                  </p>
                  <div className='mt40 mb40 padd40'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer type='homepage' />
      </div>
    );
  }
}
