import React from 'react';
import * as Icon from 'react-feather';

import PreviewOtherEmploymentsTableRow from './PreviewOtherEmploymentsTableRow';


const EmploymentInfoPreviewBox = ({ data, goToComponent }) => {
  const {
    employer_name, employer_email, employer_address, employer_phone, other_employments
  } = data;

  return (
    <div
      data-label='Employment Information'
      className='fp-mortgage-landing-preview-card mt40'
    >
      <div className='row'>
        <div className='col-md-12 no-print'>
          <span
            className='fp-mortgage-application-action-btn float-right d-flex'
            onClick={ () => goToComponent(2) }
          >
            <Icon.Edit2
              color='#fff'
              size='18px'
              className='mr-1'
            />
            Edit
          </span>
        </div>
        <div className='col-md-4'>
          <span>Company Name</span>
          <p>{ employer_name }</p>
        </div>
        <div className='col-md-4'>
          <span>Company Email Address</span>
          <p>{ employer_email }</p>
        </div>
        <div className='col-md-4'>
          <span>Employer Phone No</span>
          <p>{ employer_phone }</p>
        </div>
        <div className='col-md-12'>
          <span>
            Address of Employer (If less than three years,
            give name of previous employer)
          </span>
          <p>
            { employer_address }
          </p>
        </div>
        <div className='col-md-12'>
          <span>Other Employments:</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Company Phone</th>
                <th>Company Address</th>
                <th>Years</th>
              </tr>
            </thead>
            <tbody>
              {
                other_employments.length ? other_employments.map((company) => {
                  return (
                    <PreviewOtherEmploymentsTableRow
                      company={ company } key={ company.id } />
                  );
                }) : (
                  <PreviewOtherEmploymentsTableRow
                    company={{ company_name: '-', company_phone: '-', company_address: '-', years_of_experience: '-' }} />
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
 
export default EmploymentInfoPreviewBox;