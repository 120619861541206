import React from 'react';
import { Link } from 'react-router-dom';
import { emailRegexString } from '../../utils/regexUtils';
import { validateForm } from '../../utils/authFormUtils';


const ForgetPasswordEmailForm = ({ email, setEmail, sendEmail }) => {
  const handleSubmit = (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    sendEmail();
  };
  
  return (
    <div className='fp-login-auth-page-landing'>
      <div className='row'>
        <div className='col-md-6 offset-md-3 mt-4'>
          <div className='fp-login-page-logo'></div>
          <p className='fp-forgot-password-page-subtitle'>
            Forgot Your Password?
          </p>
          <p className='fp-forgot-password-page-text'>
            Enter your email to receive instructions on how to reset
            your password.
          </p>
          <form
            noValidate
            onSubmit={handleSubmit}
            className='fp-login-form-wrapper'
          >
            <div className='form-group'>
              <label htmlFor='Email address'>Email address</label>
              <input
                type='email'
                className='form-control'
                placeholder='Email address'
                name='email'
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                pattern={emailRegexString}
                required
              />
              <div className='invalid-feedback'>
                Invalid email address
              </div>
            </div>
            <button className='fp-modal-login-button'>
              Send Reset Instructions
            </button>
            <div className='fp-create-account-wrapper mt-4'>
              Or return to {""}
              <Link to='/auth/login'>
                Log In.
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
 
export default ForgetPasswordEmailForm;