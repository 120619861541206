import React, { Component } from "react";
import { handleChange } from "../../utils/authFormUtils";
import { handleCurrencyInputChange } from "../../utils/eligibilityFormUtils";
import { formatCurrencyInput, clearCommas } from "../../utils/currencyUtils";
import { currencyRegexString } from "../../utils/regexUtils";
import { currencyFieldErrorMessage } from "../../utils/validationMessageUtils";

class OtherIncomeSourcesListItem extends Component {
  state = {
    id: this.props.data.id,
    source: this.props.data.source || "",
    amount: this.props.data.amount
      ? formatCurrencyInput(this.props.data.amount)
      : ""
  };

  /**
   * Handles changes in input fields
   * @param {object} event
   */
  handleChange = async event => {
    const {
      target: {
        dataset: { stateName }
      }
    } = event;
    stateName === "amount"
      ? await handleCurrencyInputChange(event, this)
      : await handleChange(event, this);
    this.updateInParent();
  };

  updateInParent = () => {
    const { sources, index, setParentState } = this.props;
    let { amount } = this.state;
    amount = clearCommas(amount);
    sources[index] = { ...this.state, amount };
    setParentState({ other_source_of_income: sources });
  };

  /**
   * Handles click events on the button
   * @param {object} event
   */
  handleSourcesButtonClick = ({ target }) => {
    const { sources, setParentState, index } = this.props;
    if (sources.length) {
      sources.splice(index, 1);
      return setParentState({ other_source_of_income: sources });
    }
  };

  render() {
    const { source, amount } = this.state;
    const { index, sources } = this.props;
    const validation = { required: true };
    const isLastAndOpen = index === sources.length - 1;

    return (
      <div
        className={`row no-gutters mt-2${!sources.length || index === sources.length - 1 ? ' last-child' : '' }`}
      >
        <div className={`col-md-5${!isLastAndOpen ? " disabled" : ""}`}>
          <input
            type='text'
            className='form-control fp-child-name'
            placeholder='Source'
            value={source}
            data-state-name='source'
            onChange={this.handleChange}
            disabled={!isLastAndOpen}
            {...validation}
          />
          <div className='invalid-feedback'>Value is required</div>
        </div>
        <div className={`col-md-5${!isLastAndOpen ? " disabled" : ""}`}>
          <input
            type='text'
            className='form-control fp-child-age'
            placeholder='Amount / Month (₦) '
            data-state-name='amount'
            value={amount}
            pattern={currencyRegexString}
            onChange={this.handleChange}
            disabled={!isLastAndOpen}
            {...validation}
          />
          <div className='invalid-feedback'>{currencyFieldErrorMessage}</div>
        </div>
        <div className='col-md-1 ml-4'>
          <button
            type='button'
            className='btn btn-primary h-100 w-100 theme-color'
            onClick={this.handleSourcesButtonClick}
          >
            -
          </button>
        </div>
      </div>
    );
  }
}

export default OtherIncomeSourcesListItem;
