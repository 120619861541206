import React from 'react';


const PreviewChildrenTableRow = ({ company }) => {
  const { company_name, company_phone, company_address, years_of_experience } = company;
  return (
    <tr>
      <td>{ company_name }</td>
      <td>{ company_phone }</td>
      <td>{ company_address }</td>
      <td>{ years_of_experience }</td>
    </tr>
  );
}
 
export default PreviewChildrenTableRow;