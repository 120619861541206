import React from 'react';
import { getStatesByCountryId, getCountryByName } from '../../utils/countryStateUtils';


const StateSelect = ({ nationality, value, handleChange }) => {
  let options = [
      <option value='' defaultValue key='0' >
        - Selected preferred option -
      </option>
    ];

  if (nationality) {
    const countryOfBirth = getCountryByName(nationality);
    const states = getStatesByCountryId(countryOfBirth.id);
    states.forEach(({ name, id }) => {
      options.push(<option value={ name } key={ id }>{ name }</option>)
    });
  }

  return (
    <select
      className='form-control'
      data-state-name='state_of_origin'
      required
      value={value}
      onChange={handleChange}
    >
      { options }
    </select>
  );
}
 
export default StateSelect;