import React, {Component} from "react";
import {Helmet} from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";

import "../ApplicationType/ApplicationType.css";

import FirstTrust from "../Resource/first-trust-theme-logo.png";
import StandardCharted from "../Resource/standard-chartered-theme-logo.png";
import StandardIBTC from "../Resource/stanbic-ibtc-theme-logo.png";

import FederalMortgageBank from "../Resource/federal-mortgage-bank-theme-logo.png";

import NMRCVerified from "../Resource/fp-nmrc-certified.png";

const styles = {
  NMRCVerifiedLogo: {
    backgroundImage: `url(${NMRCVerified})`
  }
};
class ApplicationTypeV2 extends Component {
  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            Mortgage Application Type - Finance Plus | Real Estate, Apartments,
            Mortgages &amp; Home Values
          </title>
          <link
            rel='canonical'
            href='http://financeplus.ng/mortgage/application-type'
          />
        </Helmet>
        <Header history={this.props.history} type='others' />
        <section id='fp-mortgage-application-type'>
          <div className='fp-mortgage-application-type-wrapper'>
            <div className='fp-mortgage-application-type-bg'></div>
            <div className='row'>
              <div className='col-md-6 fp-mortgage-application-type-wrapper-left'>
                <a href='/affordability-test'>
                  <div className='fp-mortgage-application-type-content-list'>
                    <div className='row'>
                      <div className='col-md-10 offset-md-1'>
                        <div className='fp-mortgage-application-type-icon regular'></div>
                        {/* <h2>Apply For</h2> */}
                        <h4>Mortgage</h4>
                        <p>Apply for a mortgage to the following lenders</p>
                        <hr />

                        <div className='fp-mortgage-application-type-mortgage-lenders'>
                          <div className='row'>
                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo first-trust'>
                              <img
                                className='img-responsive'
                                src={FirstTrust}
                                alt='First Trust'
                              />
                            </div>

                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo standard-chartered'>
                              <img
                                className='img-responsive'
                                src={StandardCharted}
                                alt='Standard Chartered'
                              />
                            </div>

                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo standard-ibtc'>
                              <img
                                className='img-responsive'
                                src={StandardIBTC}
                                alt='Standard IBTC'
                              />
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className='fp-mortgage-application-type-mortgage-nmrc-powered-wrapper'>
                          <div
                            className='fp-mortgage-application-type-mortgage-nmrc-powered-logo'
                            style={styles.NMRCVerifiedLogo}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-md-6 fp-mortgage-application-type-wrapper-right'>
                <a href='/nhf/affordability'>
                  <div className='fp-mortgage-application-type-content-list'>
                    <div className='row'>
                      <div className='col-md-10 offset-md-1'>
                        <div className='fp-mortgage-application-type-icon nhf'></div>
                        {/* <h2>Apply For</h2> */}
                        <h4>National Housing Funds (NHF) Loan</h4>
                        <p>For applicants that are NHF contributors</p>
                        <hr />

                        <div className='fp-mortgage-application-type-mortgage-lenders'>
                          <div className='row'>
                            <div className='col-md-4 offset-md-4 fp-mortgage-application-type-mortgage-lenders-logo federal-mortgage'>
                              {/* <img
                                className='img-responsive fmbn'
                                src={HomeBaseLogo}
                                alt='Home Base Mortgage Bank'
                              /> */}
                              <img
                                className='img-responsive fmbn'
                                src={FederalMortgageBank}
                                alt='Federal Mortgage Bank Nigeria'
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer type='others' />
      </div>
    );
  }
}

export default ApplicationTypeV2;
