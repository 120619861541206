import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";
import Header from "../Header";
import Footer from "../Footer";
import "./ContactPage.css";
import "../Header/Header.css";
import ContactForm from "./ContactForm";

class ContactPage extends Component {
  render() {
    return (
      <div className='container-fluid px-0'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            Contact Us - Finance Plus | Real Estate, NHF, Mortgages &amp; Home
            Loans
          </title>
          <link rel='canonical' href='http://financeplus.ng/contact' />
        </Helmet>
        <Header history={this.props.history} type='others' />
        <section id='fp-mortgage-application-contact-us-top-info'>
          <div className='fp-mortgage-application-contact-us-top-info-pattern'></div>
          <div className='container'>
            <div className='fp-mortgage-application-contact-us-top-info-wrapper'>
              <h4>Contact &amp; Support</h4>
              <h2 className='fp-desktop-header'>
                Get <br /> In Touch.
              </h2>
              <h2 className='fp-mobile-header'>Get In Touch.</h2>
            </div>
          </div>
        </section>
        <section id='fp-mortgage-application-contact-us-form-features'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='fp-mortgage-application-contact-us-features'>
                  <h2>Begin your journey to home ownership!</h2>
                  <div className='fp-mortgage-application-contact-us-features-wrapper'>
                    <div className='fp-mortgage-application-contact-us-features-list'>
                      <Icon.CheckCircle color='#00b1ab' size='50px' />
                      <div className='fp-mortgage-application-contact-us-features-list-text'>
                        <h4>Affordability Test</h4>
                        <span>
                          Check how much you can afford using our unique
                          algorithm.
                        </span>
                      </div>
                    </div>

                    <div className='fp-mortgage-application-contact-us-features-list'>
                      <Icon.CheckCircle color='#00b1ab' size='50px' />
                      <div className='fp-mortgage-application-contact-us-features-list-text'>
                        <h4>Eligibility Test</h4>
                        <span>
                          Check your eligibility status and get pre-qualified
                          for mortgage.
                        </span>
                      </div>
                    </div>

                    <div className='fp-mortgage-application-contact-us-features-list'>
                      <Icon.CheckCircle color='#00b1ab' size='50px' />
                      <div className='fp-mortgage-application-contact-us-features-list-text'>
                        <h4>Apply Online</h4>
                        <span>
                          Apply for mortgage in minutes and track your
                          application status online.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='fp-mortgage-application-contact-us-form'>
                  <div className='fp-mortgage-application-contact-us-form-text'>
                    <p>
                      For questions and other enquiries, please leave us a
                      message
                    </p>
                  </div>
                  <div className='fp-mortgage-application-contact-us-form-wrapper'>
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='fp-mortgage-application-contact-us'>
          <div className='container-fluid'>
            <div className='fp-mortgage-application-contact-us-header-text'>
              <div className='fp-mortgage-application-contact-us-info'>
                <div className='row'>
                  <div className='col-md-8 offset-md-2'>
                    <div className='row'>
                      <div className='col-md-6 fp-mortgage-application-contact-us-options'>
                        <h2>General communication</h2>
                        <p>
                          For general queries, Call +234 (0) 809 053 3000, +234
                          (0) 809 063 3000
                        </p>
                      </div>
                      <div className='col-md-6 fp-mortgage-application-contact-us-options'>
                        <h2>Help &amp; Support</h2>
                        <p>
                          We’re here to help! if you have technical issues,{" "}
                          <a href='mailto:support@financeplus.ng'>
                            support@financeplus.ng
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer type='homepage' />
        </section>
      </div>
    );
  }
}
export default ContactPage;
