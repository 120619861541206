import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import CircularLoader from "../CircularLoader";
import "./LenderCustomerTable.css";

import * as Icon from "react-feather";

class LenderCustomerTable extends Component {
  render() {
    return (
      <div className='container-fluid px-0'>
        <CircularLoader isLoading={this.props.isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='User' />
        <div className='fp-mortgage-content-wrapper'>
          <div className='fp-mortgage-breadcrumb with-button'>
            <div className='fp-mortgage-breadcrumb mt-2'>
              <Icon.User
                color='#bbbbbb'
                className='fp-mortgage-breadcrumb-icon'
              />
              <p>Customers</p>
            </div>
            <div className='fp-mortgage-breadcrumb-left'>
              <div className='fp-mortgage-breadcrumb-approved-applications'>
                <span>500</span>
                <h4>Loans approved</h4>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='fp-dashboard-section'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='fp-mortgage-applications-all'>
                    <div className='fp-mortgage-applications-multi-tab-actions-buttons'>
                      <div className='fp-mortgage-applications-multi-tab'>
                        <div className=''>
                          <Icon.Search
                            color='#bbbbbb'
                            className='fp-customer-search-icon'
                            size='20'
                          />
                          <input
                            type='text'
                            className='form-control fp-customer-search'
                          />
                        </div>
                      </div>
                      <div className='fp-mortgage-applications-actions-buttons'>
                        <Link
                          className='filter fp-dashboard-chat-message-actions'
                          to='/'
                        >
                          <Icon.Filter color='#8c8c8c' /> Filter
                        </Link>
                        <Link className='fp-dashboard-more-actions' to='/'>
                          Actions <Icon.MoreHorizontal color='#fff' />
                        </Link>
                      </div>
                    </div>

                    <div className='fp-mortgage-applications-list-wrapper'>
                      <ul className='responsive-table'>
                        <li className='table-header'>
                          <div className='col col-1'>Sn</div>
                          <div className='col col-2'>Customer</div>
                          <div className='col col-2'>Phone</div>
                          <div className='col col-2'>Job Status</div>
                          <div className='col col-2'>Credit Rating</div>
                          <div className='col col-2'>Applications</div>
                          <div className='col col-2'>Status</div>
                          <div className='col col-1'>Action</div>
                        </li>

                        <li className='table-row'>
                          <div className='col col-1' data-label='Sn'>
                            1
                          </div>
                          <div className='col col-2' data-label='Customer'>
                            Jude A. Abaga
                          </div>
                          <div className='col col-2' data-label='Phone'>
                            +2340123456789
                          </div>
                          <div className='col col-2' data-label='Job Status'>
                            Employed
                          </div>
                          <div className='col col-2' data-label='Credit Rating'>
                            8.90
                          </div>
                          <div className='col col-2' data-label='Applications'>
                            1
                          </div>
                          <div className='col col-2' data-label='Status'>
                            <span className='badge approved'>Approved</span>
                          </div>
                          <div className='col col-1' data-label='Actions'>
                            <Icon.Eye color='#555555' size='23' />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    ...ownProps
  };
};

export default connect(mapStateToProps, null)(LenderCustomerTable);
