import React, {Component} from "react";
import uuid from "uuid/v1";

import {handleChange, validateForm} from "../../utils/authFormUtils";
import MultiStepFormButtons from "../MultiStepFormButtons";
import { formatCurrencyInput, clearCommas } from "../../utils/currencyUtils";
import { handleCurrencyInputChange, handleSingleFieldBlur } from "../../utils/eligibilityFormUtils";
import ChildrenListItem from "./ChildrenListItem";
import {currencyRegexString, yobRegexString} from "../../utils/regexUtils";
import {requiredFieldErrorMessage} from "../../utils/validationMessageUtils";
import {DEFAULT_RADIO_VALUES} from "../../constants";
import ApplicationProgressBar from "./ApplicationProgressBar";

const knownHomeTypes = ["owned", "rented"];
const moneyFields = ["annual_rent_value", "spouse_annual_income"];
const maritalStatuses = ['single', 'married', 'divorced'];
const blankSpouseData = {
  spouse_name: '', spouse_employer: '', spouse_work_experience: '', spouse_profession: '', spouse_annual_income: ''
};

/**
 * i don't like what is happening this morning. shit a'int right
 */

class OtherInfoForm extends Component {
  state = {
    app_ref: this.props.app_ref,
    current_apartment_status: this.props.current_apartment_status,
    annual_rent_value: formatCurrencyInput(this.props.annual_rent_value),
    marital_status: this.props.marital_status,
    spouse_name: this.props.spouse_name,
    // spouse_address: this.props.spouse_address,
    spouse_employer: this.props.spouse_employer,
    spouse_work_experience: this.props.spouse_work_experience,
    spouse_profession: this.props.spouse_profession,
    spouse_annual_income: formatCurrencyInput(this.props.spouse_annual_income),
    children: this.props.children,
    have_children: this.props.have_children,
    next_of_kin_name: this.props.next_of_kin_name,
    next_of_kin_relationship: this.props.next_of_kin_relationship,
    next_of_kin_age: this.props.next_of_kin_age,
    next_of_kin_address: this.props.next_of_kin_address
  };

  clearCommas = () => {
    const clone = {...this.state};
    moneyFields.forEach(key => {
      clone[key] = clearCommas(clone[key]);
    });
    return clone;
  };

  componentWillUnmount() {
    const clone = this.clearCommas();
    this.props.setParentState(clone);
  }

  /**
   * Handles change event on the form's input fields
   */
  handleChange = event => {
    const {
      target: {
        value,
        dataset: {stateName}
      }
    } = event;
    if (stateName === "next_of_kin_age") {
      const nextOfKinAge = parseInt(value);
      if (nextOfKinAge < 1) return;
    }

    handleChange(event, this);
    if (stateName === 'have_children' && value === 'no') this.setState({ children: [] });
    else if (stateName === 'marital_status' && value !== 'married') this.setState(blankSpouseData);
  };

  handleBlur = ({ target }) => handleSingleFieldBlur(target, this);

  handleCurrencyInputChange = event => handleCurrencyInputChange(event, this);

  setStateValues = pairs => this.setState(pairs);

  /**
   * Hanldes change event on known home type radios
   * @param event
   */
  handleRadioChange = ({target}) => {
    const {
      value,
      dataset: {stateName},
      name
    } = target;
    if (name === "homeType" && !knownHomeTypes.includes(value)) return this.setState({ [stateName]: "" });
    else if (stateName === 'marital_status' && value !== 'married') this.setState(blankSpouseData);
    this.setState({ [stateName]: value });
  };

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    
    let {children} = this.state;
    children = [...children];
    const lastChild = {...children[children.length - 1]};
    if (lastChild) {
      delete lastChild.id;
      if (!Object.values(lastChild).join("")) {
        children.pop();
        await this.setState({children});
      }
    }
    await this.props.postStepApplication(...[this, this.clearCommas(), 3]);
  };

  addChild = ({target}) => {
    const {children} = this.state;
    const inputsParent = target
      .closest(".col-md-12")
      .querySelector(".row.last-child");
    const invalidInput = inputsParent.querySelector("input:invalid");
    if (invalidInput) {
      inputsParent.classList.add("was-validated");
      return invalidInput.focus;
    }

    const lastChild = children[children.length - 1];
    if (
      !lastChild ||
      !Object.values(lastChild)
        .join("")
        .trim()
    )
      return;
    this.setState({children: [...children, {id: uuid()}]});
  };

  render() {
    const {current_apartment_status, marital_status, children} = this.state;
    const isOwnedHome = current_apartment_status === "owned";
    const isRentedHome = current_apartment_status === "rented";
    const isOtherHomeType = !knownHomeTypes.includes(current_apartment_status);
    const isSingle = marital_status === "single";
    const isMarried = marital_status === "married";
    const isDivorced = marital_status === "divorced";

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className='row fp-mortgage-application'>
          <div className='col-md-10 offset-md-1'>
            <div className='fp-mortgage-landing-page-with-nhanla'>
              <ApplicationProgressBar
                step={this.props.componentIndex}
                parentClassName='col-md-10 offset-md-1'
              />
              <div className='row fp-personal-info-form'>
                <div className='col-md-10 offset-md-1'>
                  <span>Ordinary Mortgage | Personal Info</span>
                  <h2>
                    Hope you don’t mind me getting a little personal... Don’t
                    worry, it’s nothing outside what’s required.
                  </h2>

                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Current home type</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-4'>
                          <input
                            id='Owned'
                            type='radio'
                            name='homeType'
                            value='owned'
                            data-state-name='current_apartment_status'
                            checked={isOwnedHome}
                            onChange={this.handleRadioChange}
                          />
                          <label htmlFor='Owned'>Owned</label>
                        </div>

                        <div className='col-md-4'>
                          <input
                            id='Rented'
                            type='radio'
                            name='homeType'
                            value='rented'
                            data-state-name='current_apartment_status'
                            checked={isRentedHome}
                            onChange={this.handleRadioChange}
                          />
                          <label htmlFor='Rented'>Rented</label>
                        </div>

                        <div className='col-md-4'>
                          <input
                            id='Others'
                            type='radio'
                            name='homeType'
                            value='others'
                            data-state-name='current_apartment_status'
                            checked={isOtherHomeType}
                            onChange={this.handleRadioChange}
                          />
                          <label htmlFor='Others'>Others</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    {/* if others, the below form should be active otherwise hidden */}
                    {isOtherHomeType ? (
                      <div className='col-md-5'>
                        <label>If others, please specify</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='current_apartment_status'
                          value={this.state.current_apartment_status}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Please fill in your home category if not owned nor
                          rented
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* if others end */}

                    {!isOwnedHome ? (
                      <div className='col-md-7'>
                        <label>Annual Rent</label>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text px-3 bg-white'>
                              ₦
                            </span>
                          </div>
                          <input
                            name='monthly_income'
                            type='text'
                            className='form-control'
                            placeholder='Annual Rent'
                            data-state-name='annual_rent_value'
                            value={this.state.annual_rent_value}
                            pattern={currencyRegexString}
                            onChange={this.handleCurrencyInputChange}
                            required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text px-3 bg-theme-button-group'>
                              Annually
                            </span>
                          </div>
                          <div className='invalid-feedback'>
                            Please enter a value for the annual rent
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Marital Status</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-4'>
                          <input
                            id='Married'
                            type='radio'
                            name='maritalStatus'
                            value='married'
                            data-state-name='marital_status'
                            onChange={this.handleRadioChange}
                            checked={isMarried}
                          />
                          <label htmlFor='Married'>Married</label>
                        </div>

                        <div className='col-md-4'>
                          <input
                            id='Single'
                            type='radio'
                            name='maritalStatus'
                            value='single'
                            data-state-name='marital_status'
                            onChange={this.handleRadioChange}
                            checked={isSingle}
                          />
                          <label htmlFor='Single'>Single</label>
                        </div>

                        <div className='col-md-4'>
                          <input
                            id='Divorced'
                            type='radio'
                            name='maritalStatus'
                            value='divorced'
                            data-state-name='marital_status'
                            onChange={this.handleRadioChange}
                            checked={isDivorced}
                          />
                          <label htmlFor='Divorced'>Divorced /Separated</label>
                        </div>
                      </div>
                      {!maritalStatuses.includes(this.state.marital_status) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* if married, the below form group with class married should be
          active otherwise hidden */}
                  {isMarried ? (
                    <div className='form-group row married'>
                      <div className='col-md-6 mt-2'>
                        <label>Spouse’s Name</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*(?:\s[a-zA-Z]+(?:-[a-zA-Z]+)*)+$'
                          data-state-name='spouse_name'
                          value={this.state.spouse_name}
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          //required
                        />
                        <div className='invalid-feedback'>
                          At least two names, separated by a space and
                          consisting only of letters and (hyphen separated names
                          are allowed)
                        </div>
                      </div>

                      {/* <div className='col-md-6  mt-2'>
                        <label>Spouse’s Address</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Email Address'
                          data-state-name='spouse_address'
                          value={this.state.spouse_address}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Value is required
                        </div>
                      </div> */}
                      <div className='col-md-6  mt-2'>
                        <label>Spouse’s Employer</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Employer'
                          data-state-name='spouse_employer'
                          value={this.state.spouse_employer}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          //required
                        />
                        <div className='invalid-feedback'>
                          Value is required
                        </div>
                      </div>
                      <div className='col-md-6  mt-2'>
                        <label>No of Years Employed</label>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Years Employed'
                          data-state-name='spouse_work_experience'
                          value={this.state.spouse_work_experience}
                          onChange={this.handleChange}
                          //required
                        />
                        <div className='invalid-feedback'>
                          Please enter a valid number
                        </div>
                      </div>
                      <div className='col-md-6 mt-2'>
                        <label>Profession of Spouse</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Profession'
                          data-state-name='spouse_profession'
                          value={this.state.spouse_profession}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          //required
                        />
                        <div className='invalid-feedback'>
                          Value is required
                        </div>
                      </div>
                      <div className='col-md-6 mt-2'>
                        <label>Spouse's Annual Income</label>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text px-3 bg-white'>
                              ₦
                            </span>
                          </div>
                          <input
                            name='annual_income'
                            type='text'
                            className='form-control'
                            placeholder='Annual Income'
                            data-state-name='spouse_annual_income'
                            pattern={currencyRegexString}
                            value={this.state.spouse_annual_income}
                            onChange={this.handleCurrencyInputChange}
                            //required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text px-3 bg-theme-button-group'>
                              Yearly
                            </span>
                          </div>
                          <div className='invalid-feedback'>
                            Enter a valid income value
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* if married ends */}

                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Children/Other Dependents</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='hasChildren'
                            type='radio'
                            name='have_children'
                            data-state-name='have_children'
                            onChange={this.handleChange}
                            checked={this.state.have_children === "yes"}
                            value='yes'
                            required
                          />
                          <label htmlFor='hasChildren'>Yes</label>
                        </div>

                        <div className='col-md-6'>
                          <input
                            id='noChildren'
                            type='radio'
                            name='have_children'
                            data-state-name='have_children'
                            onChange={this.handleChange}
                            checked={this.state.have_children === "no"}
                            value='no'
                          />
                          <label htmlFor='noChildren'>No</label>
                        </div>
                      </div>
                      {!DEFAULT_RADIO_VALUES.includes(
                        this.state.have_children
                      ) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {this.state.have_children === "yes" ? (
                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <button
                          type='button'
                          className='btn btn-primary float-right'
                          onClick={this.addChild}
                        >
                          +
                        </button>
                        <label>Children/Other Dependents</label>
                        {(() => {
                          const childrenClone = children.length
                            ? [...children]
                            : [{id: uuid()}];
                          return childrenClone.map((child, index) => {
                            return (
                              <ChildrenListItem
                                key={child.id}
                                child={child}
                                setParentState={this.setStateValues}
                                children={childrenClone}
                                index={index}
                              />
                            );
                          });
                        })()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='form-group row mt-4'>
                    <div className='col-md-6'>
                      <label>Next-of-Kin’s Name</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Name'
                        pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*(?:\s[a-zA-Z]+(?:-[a-zA-Z]+)*)+$'
                        data-state-name='next_of_kin_name'
                        value={this.state.next_of_kin_name}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>
                        At least two names, separated by a space and consisting
                        only of letters and (hyphen separated names are allowed)
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <label>Next-of-Kin’s Relationship</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Relationship'
                        data-state-name='next_of_kin_relationship'
                        value={this.state.next_of_kin_relationship}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <div className='col-md-4'>
                      <label>Next-of-Kin’s Age</label>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Age'
                        data-state-name='next_of_kin_age'
                        value={this.state.next_of_kin_age}
                        onChange={this.handleChange}
                        required
                      />
                      <div className='invalid-feedback'>
                        Please enter a valid value for the age
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <label>Next-of-Kin Address</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Residential Address'
                        data-state-name='next_of_kin_address'
                        value={this.state.next_of_kin_address}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <MultiStepFormButtons
              type='Mortgage-Application'
              handleBackClick={this.props.goToPreviousComponent}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default OtherInfoForm;
