import React from "react";


const ChildrenListItem = ({
  child,
  setParentState,
  children,
  index
}) => {
  const bubblechild = (name, value) => {
    children[index] = { ...child, [name]: value };
    setParentState({ children });
  };

  const handleChange = ({ target: { name, value } }) => bubblechild(name, value);

  const handleBlur = ({ target: { name, value } }) => {
    value = value.trim();
    bubblechild(name, value);
  };

  /**
   * Handles click events on the button
   * @param {object} event
   */
  const handleChildrenButtonClick = () => {
    if (children.length) {
      children.splice(index, 1);
      return setParentState({ children });
    }
  };

  const { name, age, relationship } = child;
  const validation = {};
  if ([name, age, relationship].join('')) validation.required = true;
  const isLastAndOpen = index === children.length - 1;

  return (
    <div
      className={`row no-gutters mt-2${!children.length || index === children.length - 1 ? ' last-child' : '' }`}
    >
      <div className={`col-md-4${!isLastAndOpen ? " disabled" : ""}`}>
        <input
          type='text'
          className='form-control fp-child-name'
          placeholder='Name'
          pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*(?:\s[a-zA-Z]+(?:-[a-zA-Z]+)*)+$'
          name='name'
          value={name || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isLastAndOpen}
          {...validation}
        />
        <div className='invalid-feedback'>
          At least two names, separated by a space and consisting only of
          letters and (hyphen separated names are allowed)
        </div>
      </div>
      <div className={`col-md-2${!isLastAndOpen ? " disabled" : ""}`}>
        <input
          type='number'
          min='0'
          className='form-control fp-child-age'
          placeholder='Age'
          name='age'
          value={age || ""}
          onChange={handleChange}
          disabled={!isLastAndOpen}
          {...validation}
        />
        <div className='invalid-feedback'>Please input a valid number</div>
      </div>
      <div className={`col-md-3${!isLastAndOpen ? " disabled" : ""}`}>
        <input
          type='text'
          className='form-control fp-child-relationship'
          placeholder='Relationship'
          name='relationship'
          value={relationship || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isLastAndOpen}
          {...validation}
        />
        <div className='invalid-feedback'>Value is required</div>
      </div>
      <div className='col-md-2 ml-2'>
        <button
          type='button'
          className='btn btn-primary h-100 w-50 theme-color'
          onClick={handleChildrenButtonClick}
        >
          -
        </button>
      </div>
    </div>
  );
};

export default ChildrenListItem;
