import React, {useEffect} from "react";

import "./ForgetPassword.css";
import {clearErrorStore} from "../../utils/errorUtils";
import ForgetPasswordEmailForm from "./ForgetPasswordEmailForm";
import ForgetPasswordPasswordForm from "./ForgetPasswordPasswordForm";
import ErrorToaster from "../ErrorToaster";
import { useState } from "react";
import http from '../../config/axios.config';
import { connect } from "react-redux";
import CircularLoader from "../CircularLoader";
import Toaster from "../../Toaster";


const ForgetPassword = ({ isLoading, history }) => {
  useEffect(() => {
    document.title = "Forgot Password | Finance Plus";
    return clearErrorStore;
  }, []);

  const [showToast, setShowToast] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [code, setCode] = useState('');

  const setters = { setPassword, setRepeatPassword, setCode };

  const sendEmail = async () => {
    try {
      await http.post('/forgotpassword', { email });
      setHasSentEmail(true);      
    } catch (error) {}
  };

  const sendData = async () => {
    try {
      await http.post('/forgot-change-password', { email, code, password });
      setShowToast(true);
      const timeout = setTimeout(() => {
        history.push('/auth/login');
        clearTimeout(timeout);
      }, 2000);      
    } catch (error) {}
  };

  return (
    <div className='container-fluid p-0'>
      <CircularLoader {...{ isLoading }} />
      <ErrorToaster />
      <Toaster {...{showToast, content: 'Password changed successfully'}} />
      <section id='fp-login-auth-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-8 offset-md-2'>
              {
                hasSentEmail ? (
                  <ForgetPasswordPasswordForm {...{
                      setters, password, repeatPassword, code, sendData, setHasSentEmail
                    }}
                  />
                ) : (
                  <ForgetPasswordEmailForm {...{ email, setEmail, sendEmail }} />
                )
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ isLoading }, ownProps) => {
  return { isLoading, ...ownProps };
};
 
export default connect(mapStateToProps)(ForgetPassword);
