import React from "react";

import "./DashboardMortgageApplicationTable.css";
import ApplicationList from "../UserDashboard/ApplicationList";


const DashboardMortgageApplicationTable = ({ goToRoute, query }) => {
  return (
    <ApplicationList
      orientation='vertical'
      goToRoute={ goToRoute }
      query={ query }
    />
  );
}
 
export default DashboardMortgageApplicationTable;
