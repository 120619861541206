import React, {Component} from "react";

import Header from "../Header";
import Footer from "../Footer";
import "./AboutUs.css";

class AboutUs extends Component {
  render() {
    return (
      <div className='container-fluid px-0'>
        <Header history={this.props.history} type='others' />
        <section
          id='fp-mortgage-application-about-us-page'
          className='fp-mortgage-application-about-us-page'
        >
          <div className='container'>
            <div className='fp-mortgage-application-about-us-page-wrapper'>
              <h4>About Finance+</h4>
              <div className='row'>
                <div className='col-md-7 fp-mortgage-application-about-us-page-left'>
                  <div className='fp-mortgage-application-about-us-page-left-phone-display'></div>
                  {/* <p>
                    Discover great mortgage deals from trusted lenders across
                    the country, and get expert advice on the right option for
                    you. Best of all, we are absolutely free! Discover great
                    mortgage deals from trusted lenders across the country, and
                    get expert advice on the right option for you. Best of all,
                    we are absolutely free!
                  </p>
                  <p>
                    Discover great mortgage deals from trusted lenders across
                    the country, and get expert advice on the right option for
                    you. Best of all, we are absolutely free! Discover great
                    mortgage deals from trusted lenders across the country, and
                    get expert advice on the right option for you. Best of all,
                    we are absolutely free! Discover great mortgage deals from
                    trusted lenders across the country, and get expert advice on
                    the right option for you. Best of all, we are absolutely
                    free! Discover great mortgage deals from trusted lenders
                    across the country, and get expert advice on the right
                    option for you. Best of all, we are absolutely free!
                  </p>

                  <p>
                    Discover great mortgage deals from trusted lenders across
                    the country, and get expert advice on the right option for
                    you. Best of all, we are absolutely free! Discover great
                    mortgage deals from trusted lenders across the country, and
                    get expert advice on the right option for you. Best of all,
                    we are absolutely free!
                  </p> */}
                </div>
                <div className='col-md-5 fp-mortgage-application-about-us-page-right'>
                  <div className='fp-mortgage-application-about-us-page-right-wrapper'>
                    <h2>Key Benefits</h2>
                    <div className='fp-mortgage-application-about-us-page-right-wrapper-text'>
                      <ol>
                        <li>
                          Discover great mortgage deals from trusted lenders
                          across the country.
                        </li>
                        <li>Best of all, we are absolutely free!</li>
                        <li>
                          Discover great mortgage deals from trusted lenders
                          across the country.
                        </li>
                        <li>
                          Discover great mortgage deals from trusted lenders
                          across the country.
                        </li>
                        <li>
                          Discover great mortgage deals from trusted lenders
                          across the country.
                        </li>
                        <li>
                          Discover great mortgage deals from trusted lenders
                          across the country.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='fp-mortgage-application-about-us-page-support-info-location'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-7'>
                        <div className='fp-mortgage-application-about-us-page-support-info'>
                          <h4>Customer Service and Support</h4>
                          <ol className='mx-0'>
                            <li>
                              <a href='/'>Help site</a>. Discover great mortgage
                              deals from trusted lenders across the country, and
                              get expert advice on the right option for you.
                              Best of all, we are absolutely free!
                            </li>
                            <li>
                              <a href='/'>Contact us</a>. Discover great mortgage
                              deals from trusted lenders across the country, and
                              get expert advice on the right option for you.
                              Best of all, we are absolutely free!
                            </li>
                            <li>
                              Advert and partnership, press query or applying
                              for a job <a href='/'>find out more</a>
                            </li>
                          </ol>
                        </div>
                      </div>
                      <div className='col-md-5'>
                        <div className='fp-mortgage-application-about-us-page-support-location'>
                          <h4>Finance+ HQ</h4>
                          {/* <p>Get In Touch</p> */}
                          <address
                            style={{fontSize: "15.5px", lineHeight: "1.6"}}
                          >
                            15A Abayomi Durosinmi-Etti Street
                            <br /> Off T.F Kuboye Road, Lekki Phase 1 <br />
                            Ocean-Side, Lekki Lagos <br /> Nigeria. PO Box
                            151200 <br />
                            <br />
                            <a href='mailto:support@financeplus.ng'>
                              support@financeplus.ng
                            </a>
                            <br />
                            +234 (0) 809 053 3000, +234 (0) 809 063 3000
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='fp-mortgage-application-about-us-page-header-navigation'>
              <div className='container-fluid'>
                <div className='fp-mortgage-application-about-us-page-header-navigation-link'>
                  <ul>
                    <li>About Us</li>
                    <li>Our Team</li>
                    <li>Partners</li>
                    <li>Careers</li>
                    <li>Press</li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <div className='fp-mortgage-application-about-us-page-content-wrapper'>
              <div className='fp-mortgage-application-about-us-page-content-wrapper-img-bg'></div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6 offset-md-3'>
                    <div className='fp-mortgage-application-about-us-page-content-text-overlay'>
                      <h2>"A culture of thinkers &amp; makers"</h2>
                      <p>
                        Discover great mortgage deals from trusted lenders
                        across the country, and get expert advice on the best
                        option for you. Best of all, we are absolutely free! We
                        are absolutely free! Discover great mortgage deals from
                        trusted lenders across the country, and get expert
                        advice on the best option for you. Best of all, we are
                        absolutely free! We are absolutely free!
                      </p>

                      <p>
                        Discover great mortgage deals from trusted lenders
                        across the country, and get expert advice on the best
                        option for you. Best of all, we are absolutely free! We
                        are absolutely free! Discover great mortgage deals from
                        trusted lenders across the country, and get expert
                        advice on the best option for you. Best of all, we are
                        absolutely free! We are absolutely free!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <Footer type='others' />
      </div>
    );
  }
}
export default AboutUs;
