import React from 'react';
import { toTitleCase } from '../../utils/dashboardUtils';


const PreviewOutstandingLoansTableRow = ({ loan }) => {
  const { lender_name, loan_type, loan_frequency, outstanding_amount } = loan;
  return (
    <tr>
      <td>{ lender_name ? toTitleCase(lender_name) : '-' }</td>      
      <td>{ loan_type ? toTitleCase(loan_type) : '-' }</td>
      <td>{ loan_frequency ? toTitleCase(loan_frequency) : '-' }</td>      
      <td>{ outstanding_amount || '-' }</td>
    </tr>
  );
}
 
export default PreviewOutstandingLoansTableRow;