import React from 'react';

import './lightbox.css';
import Slide from './Slide';



const LightBox = ({ image, appRef, modalRef }) => {
  const closeModal = ({ target }) => {
    modalRef.current.style.display = 'none';
  };

  return (
      <div id="myModal" className="modal" ref={modalRef}>
        <span
          className="close cursor"
          onClick={ closeModal }
        >
          x
        </span>

        <div className="modal-content">
          <Slide image={ image } appRef={ appRef } />
          <div className="caption-container">
            <p id="caption">{ image.name }</p>
          </div>
        </div>
      </div>
  );
}
 
export default LightBox;