import React, { Component } from "react";
import "./DashboardBreadCrumb.css";

import * as Icon from "react-feather";

class DashboardBreadCrumb extends Component {
  render() {
    let breadcrumb;
    if (this.props.type === "Mortgage Application") {
      breadcrumb = (
        <div className='fp-mortgage-breadcrumb'>
          <Icon.File color='#bbbbbb' className='fp-mortgage-breadcrumb-icon' />
          <p>{this.props.title}</p>
        </div>
      );
    } else if (this.props.type === "Single Mortgage Application") {
      breadcrumb = (
        <div className='fp-mortgage-breadcrumb'>
          <Icon.File color='#bbbbbb' className='fp-mortgage-breadcrumb-icon' />
          <p>{this.props.title}</p>
        </div>
      );
    }
    return <div>{breadcrumb}</div>;
  }
}
export default DashboardBreadCrumb;
