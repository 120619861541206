import React from "react";

import { Link } from "react-router-dom";

import DashboardIcon from "../Resource/dashboardIcon.png";
import AllApplicationIcon from "../Resource/allApplicationsIcon.png";
import PendingApplicationIcon from "../Resource/pendingApplicationIcon.png";
import RejectedApplicationIcon from "../Resource/rejectedApplicationsIcon.png";
import ApprovedApplicationIcon from "../Resource/approvedLoansIcon.png";
import BrowserStorage from "../../utils/browserStorageUtils";
import CustomerIcon from "../Resource/customersIcon.png";
import LenderIcon from "../Resource/LenderProducts.png";


const UserNav = () => {
  const { usertype } = BrowserStorage.getUserData();
  const isLender = usertype === 'lender';
  const isAdmin = usertype === 'admin';
  return (
    <div className='fp-mortgage-nav-wrapper'>
      <Link className='fp-nav-link fp-active-nav' to={`/${usertype}/dashboard`}>
        <img className='active' src={DashboardIcon} alt='Dashboard' />
        Dashboard
      </Link>

      <div className='fp-nav-group'>
        <div className='fp-nav-group-subtile'>Loans</div>
        <div className='fp-nav-sub-group'>
          <Link className='fp-nav-link' to={`/${usertype}/dashboard/application`}>
            <img src={AllApplicationIcon} alt='All Applications' />
            All Applications
          </Link>
          <Link
            className='fp-nav-link'
            to={`/${usertype}/dashboard/application?status=pending`}
          >
            <img src={PendingApplicationIcon} alt='Pending Applications' />
            Pending Applications
          </Link>
          <Link
            className='fp-nav-link'
            to={`/${usertype}/dashboard/application?status=rejected`}
          >
            <img src={RejectedApplicationIcon} alt='Rejected Applications' />
            Rejected Applications
          </Link>
          <Link
            className='fp-nav-link border-line-top'
            to={`/${usertype}/dashboard/application?status=approved`}
          >
            <img src={ApprovedApplicationIcon} alt='Approved Applications' />
            Approved Applications
          </Link>
        </div>
      </div>

      {
        isLender || isAdmin ? (
          <div className='fp-nav-group'>
            <div className='fp-nav-group-subtile'>Customers</div>
            <div className='fp-nav-sub-group'>
              <Link className='fp-nav-link' to='/'>
                <img src={CustomerIcon} alt='All Customers' />
                All Customers
              </Link>

              {
                isAdmin ? (
                  <Link className='fp-nav-link' to='/'>
                    <img src={LenderIcon} alt='All Lenders' />
                    All Lenders
                  </Link>
                ) : ''
              }

            </div>
          </div>
        ) : ''
      }

      {/* <div className='fp-nav-group'>
        <div className='fp-nav-group-subtile'>Messaging</div>
        <div className='fp-nav-sub-group'>
          <Link className='fp-nav-link' to='/'>
            <img src={messengerIcon} alt='Messages' />
            Messages
          </Link>
        </div>
      </div> */}
    </div>
  );
}
 
export default UserNav;
