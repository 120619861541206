/**
 * Formats a string number value to two decimal places
 * @param {string} value
 * @returns {number}
 */
export const formatDecimals = (value) => {
  return parseFloat(parseFloat(value).toFixed(2));
};

/**
 * Clears comma from a string value
 * @param {string|number} value
 * @returns {string} cleaned value
 */
export const clearCommas = (value) => {
  const str = value.toString();
  return str.replace(/,/g, '');
}


/**
   * Formats input values for currency fields with comma separators
   * @param {string|number} value
   * @param {boolean} isInput
   * @returns {string} formatted value
   */
export const formatCurrencyInput = (value, isInput) => {
  // Convert to string if not of type string
  if (!value && value !== 0) return '';
  const str = value.toString();
  // If the last input is a point return the original string
  if (/\.(0)*$/.test(str) && isInput) return str;
  const trimmedString = str.trim();
  if (trimmedString === '') return trimmedString;
  const [whole, decimal] = str.split('.');
  const numArray = [parseInt(clearCommas(whole), 10).toLocaleString('en')];
  if (decimal) numArray.push(decimal);
  return numArray.join('.');
}
