import React, { Component } from "react";
import MultiStepFormButtons from "../MultiStepFormButtons";
import { formatCurrencyInput, clearCommas } from "../../utils/currencyUtils";
import { handleCurrencyInputChange } from "../../utils/eligibilityFormUtils";
import { handleChange, validateForm } from "../../utils/authFormUtils";
import { toTitleCase } from "../../utils/dashboardUtils";
import { currencyRegexString } from "../../utils/regexUtils";
import { currencyFieldErrorMessage, requiredFieldErrorMessage } from "../../utils/validationMessageUtils";
import ApplicationProgressBar from "./ApplicationProgressBar";


class LoanInfoStepForm extends Component {
  state = {
    app_ref: this.props.app_ref,
    down_payment: this.props.down_payment,
    agreed_to_terms: this.props.agreed_to_terms,
    // have_employer_guarantor: this.props.have_employer_guarantor,
    loan_amount: this.props.loan_amount,
    property_title: this.props.property_title,
    property_value: this.props.property_value,
    property_address: this.props.property_address,
    property_description: this.props.property_description
  };

  componentWillUnmount() {
    const data = {};
    ['down_payment', 'property_value', 'loan_amount'].forEach((key) => {
      data[key] = clearCommas(this.state[key]);
    });
    this.props.setParentState({ ...this.state, ...data });
  }

  /**
   * Handles change event on the input elements for the money value inputs
   */
  handleCurrencyInputChange = event => handleCurrencyInputChange(event, this);

  handleChange = event => handleChange(event, this);

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();    
    this.props.goToNextComponent();
  };

  render() {
    // const { have_employer_guarantor } = this.state;
    const { firstname } = this.props;
    // const hasEmployerGuarantee = have_employer_guarantor === "yes";

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className='row fp-mortgage-application'>
          <div className='col-md-10 offset-md-1'>
            <div className='fp-mortgage-landing-page-with-nhanla'>
              <ApplicationProgressBar
                step={this.props.componentIndex}
                parentClassName='col-md-10 offset-md-1'
              />
              <div className='row'>
                <div className='col-md-10 offset-md-1 fp-personal-info-form'>
                  <span>Ordinary Mortgage | Loan Info &amp; Declaration</span>
                  <h2>
                    Looking good {toTitleCase(firstname)}! Let’s talk about your finances,
                    our partner lenders will need the info below.
                  </h2>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Proposed Equity Contribution</label>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-3 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          name='monthly_income'
                          type='text'
                          className='form-control'
                          placeholder='Equity contribution'
                          data-state-name='down_payment'
                          pattern={currencyRegexString}
                          value={formatCurrencyInput(this.state.down_payment)}
                          onChange={this.handleCurrencyInputChange}
                          required
                        />
                        <div className='invalid-feedback'>
                          Please enter a valid number (sequence of digits)
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6 form-group'>
                      <label>Loan Amount</label>
                      <div className='input-group'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-4 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Loan Amount'
                          pattern={currencyRegexString}
                          maxLength='19'
                          value={formatCurrencyInput(this.state.loan_amount)}
                          data-state-name='loan_amount'
                          onChange={this.handleCurrencyInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <div className="col-md-4">
                      <label>Property Title</label>
                      <input
                        type="text"
                        className='form-control'
                        placeholder='Property title'
                        data-state-name='property_title'
                        value={this.state.property_title || ''}
                        onChange={this.handleChange}
                        required
                      />
                      <div className="invalid-feedback">{requiredFieldErrorMessage}</div>
                    </div>

                    <div className="col-md-4">
                      <label>Property Address</label>
                      <input
                        type="text"
                        className='form-control'
                        placeholder='Property address'
                        data-state-name='property_address'
                        value={this.state.property_address || ''}
                        onChange={this.handleChange}
                        required
                      />
                      <div className="invalid-feedback">{requiredFieldErrorMessage}</div>
                    </div>

                    <div className='col-md-4'>
                      <label>Property Value</label>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-3 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          name='property_value'
                          type='text'
                          className='form-control'
                          placeholder='Property value'
                          data-state-name='property_value'
                          pattern={currencyRegexString}
                          value={formatCurrencyInput(this.state.property_value)}
                          onChange={this.handleCurrencyInputChange}
                          required
                        />
                        <div className='invalid-feedback'>{currencyFieldErrorMessage}</div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-12">
                      <label>Property Description</label>
                      <div className="input-group mb-3">
                        <textarea
                          name="property_description"
                          className='form-control'
                          rows='6'
                          data-state-name="property_description"
                          onChange={this.handleChange}
                          //required
                        ></textarea>
                        <div className="invalid-feedback">{requiredFieldErrorMessage}</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Employer's guarantee</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='Yes'
                            type='checkbox'
                            name='have_employer_guarantor'
                            data-state-name='have_employer_guarantor'
                            value='yes'
                            checked={hasEmployerGuarantee}
                            onChange={({ target }) => {
                              this.setState({
                                have_employer_guarantor: target.checked
                                  ? "yes"
                                  : "no"
                              });
                            }}
                          />
                          <label htmlFor='Yes'>Guarantee of Employer</label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className='form-group'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='gridCheck'
                        data-state-name='agrees'
                        checked={this.state.agreed_to_terms === 'yes'}
                        value=''
                        required
                        onChange={({ target }) => {
                          this.setState({
                            agreed_to_terms: target.checked ? "yes" : "no"
                          });
                        }}
                      />
                      <label
                        className='form-check-label mt-3'
                        htmlFor='gridCheck'
                      >
                        I {toTitleCase(firstname)}, hereby declare that all information
                        provided in this application are correct and that all
                        documents submitted with this form are authentic. I
                        agree that Afreal Limited could cross-check the
                        information provided above and may request for
                        references from my employer and/or financial
                        institutions mentioned above. I therefore agree that any
                        material misstatement discovered renders my application
                        null and void.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <MultiStepFormButtons
              type='Mortgage-Application'
              handleBackClick={this.props.goToPreviousComponent}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default LoanInfoStepForm;
