import React from 'react';
import { formatCurrencyInput } from '../../utils/currencyUtils';


const PreviewOtherIncomeSourcesTableRow = ({ source: { source, amount } }) => {
  return (
    <tr>
      <td>{ source }</td>
      <td>{ amount !== '-' ? `₦ ${ formatCurrencyInput(amount) }` : amount }</td>
    </tr>
  );
}
 
export default PreviewOtherIncomeSourcesTableRow;