import React from 'react';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../../utils/dashboardUtils';
import { requiredFieldErrorMessage } from '../../utils/validationMessageUtils';
import { yobRegexString } from '../../utils/regexUtils';
import { createRef } from 'react';


const ForgetPasswordPasswordForm = ({
  setters, password, repeatPassword, code, sendData, setHasSentEmail
}) => {
  const group1Ref = createRef(null);
  const group2Ref = createRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const { target } = event;
    [group1Ref, group2Ref].forEach(({ current }) => current.classList.add('was-validated'));
    target.classList.add('been-validated');
    const invalidInput = target.querySelector('input:invalid');
    if (invalidInput) return invalidInput.focus();
    sendData();
  };

  const handleChange = ({ target: { name, value } }) => {
    setters[`set${toTitleCase(name)}`](value);
  }

  const handleCodeChange = ({ target }) => {
    const { value, dataset: { index } } = target;
    const splitted = code.split('');
    const prevValue = splitted[index];
    splitted[index] = value;
    setters.setCode(splitted.join(''));
    if (['', undefined].includes(prevValue) && value) {
      const nextInd = parseInt(index, 10) + 1;
      if (nextInd <= 5) target.parentNode.querySelector(`[data-index='${nextInd}'`).focus();
    }
  };

  const handleCodeKeyDown = ({ keyCode, target }) => {
    const { value, dataset: { index } } = target;
    if (keyCode === 8 && ['', undefined].includes(value)) {
      const prevInd = parseInt(index, 10) - 1;
      if (prevInd >= 0) target.parentNode.querySelector(`[data-index='${prevInd}'`).focus()
    }
  };

  return (
    <div className='fp-login-auth-page-landing mt-4'>
      <div className='row'>
        <div className='col-md-6 offset-md-3 mt-4'>
          <div className='fp-login-page-logo'></div>
          <p className='fp-forgot-password-page-subtitle'>
            Reset Password
          </p>
          <p className='fp-forgot-password-page-text'>
            Please verify the secret codes sent to your email
            address. Codes are valid for 24hrs.
          </p>
          <form
            noValidate
            onSubmit={handleSubmit}
            className='fp-login-form-wrapper'
          >
          <div className='form-group row'>
              <div className='col-md-12'>
                <label htmlFor=' Verification Code'>
                  Verification Code
                </label>
                <div className='code_group'>
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='0'
                    value={code[0] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='1'
                    value={code[1] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='2'
                    value={code[2] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='3'
                    value={code[3] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='4'
                    value={code[4] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                  <input
                    type='number'
                    maxLength='1'
                    className='form-control'
                    placeholder='0'
                    data-index='5'
                    value={code[5] || ''}
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeKeyDown}
                    required
                  />
                </div>
                {
                  !/^\d{6}$/.test(code) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : ''
                }
              </div>
            </div>

            <div className='form-group' ref={group1Ref}>
              <label htmlFor='Password'>Password</label>
              <input
                type='password'
                className='form-control'
                placeholder='Password'
                name='password'
                value={password}
                onChange={handleChange}
                minLength='6'
                required
              />
              <div className='invalid-feedback'>
                Password must be at least 6 characters long
              </div>
            </div>
            <div className='form-group' ref={group2Ref}>
              <label htmlFor='Confirm Password'>
                Confirm Password
              </label>
              <input
                type='password'
                className='form-control'
                placeholder='Confirm Password'
                name='repeatPassword'
                value={repeatPassword}
                pattern={password}
                onChange={handleChange}
                required
              />
              <div className='invalid-feedback'>
                Value must match original password
              </div>
            </div>
            <button className='fp-modal-login-button'>
              Reset Password
            </button>
            <div className='fp-create-account-wrapper my-4'>
              You didn't receive any code?{' '}
              <button
                type='button'
                onClick={() => setHasSentEmail(false)}
                style={{
                  background: 'none', border: 'none', color: 'inherit', textDecoration: 'underline'
                }}
              >
                Request Code
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
 
export default ForgetPasswordPasswordForm;