import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import * as Icon from "react-feather";

import "./NhfAffordability.css";

import Header from "../Header";
import DatePicker from "../DatePicker";
import http from "../../config/axios.config";
import {clearErrorStore} from "../../utils/errorUtils";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import setLoading from "../../store/actions/setLoading";
import { getYearFromDOB } from "../../utils/formatDate";
import fetchProperties from "../../utils/fetchProperties";
import BrowserStorage from "../../utils/browserStorageUtils";
import PropertySuggestionList from "../PropertySuggestionList";
import setEarningData from "../../store/actions/setEarningData";
import {handleCurrencyInputChange} from "../../utils/eligibilityFormUtils";
import setAffordabilityData from "../../store/actions/setAffordabilityData";
import { currencyRegexString, yobRegexString } from "../../utils/regexUtils";
import {currencyFieldErrorMessage} from "../../utils/validationMessageUtils";
import {PENDING_DESTINATION, DEFAULT_MORTGAGE_APPLICATION_DATA} from "../../constants";
import setMortgageApplicationData from "../../store/actions/setMortgageApplicationData";
import {formatCurrencyInput, clearCommas, formatDecimals} from "../../utils/currencyUtils";

class NhfAffordability extends Component {
  defaultState = {
    ...this.props.earningData,
    ...this.props.affordabilityData,
    submitCount: 0,
    rate: 6
  };

  state = {...this.defaultState};

  constructor(props) {
    super(props);
    this.form = createRef();
    this.loginButtonRef = createRef();
  }

  handleChange = event => handleChange(event, this);

  handleCurrentInputChange = event => handleCurrencyInputChange(event, this);

  componentDidUpdate(_, prevState) {
    const {dob, tenure: tenureString} = this.state;
    if (dob && prevState.dob !== dob) {
      const yob = getYearFromDOB(dob);
      const maxTenure = 60 - (new Date().getFullYear() - parseInt(yob, 10));
      const tenure = parseInt(tenureString, 10);
      const newTenure =
        maxTenure < 1
          ? 0
          : tenure >= maxTenure
          ? maxTenure
          : tenure < 1
          ? 1
          : tenure;
      this.setState({tenure: newTenure});
    }
  }

  /**
   * Handle click event on the "Check Again" button
   * Resets variables
   */
  handleCheckAgainClick = async () => {
    if (this.form.current) this.form.current.classList.remove("was-validated");
    const {setAffordabilityData, setEarningData} = this.props;
    await setAffordabilityData({
      loan_amount: "",
      dob: "",
      monthly_payment: "",
      properties: null,
      rate: 6,
      tenure: 20,
      maxLoanableAmount: "",
      other_obligations: "",
      isNhf: true
    });
    await setEarningData({monthly_income: "", additional_income: ""});
    const {earningData, affordabilityData} = this.props;
    this.setState({...affordabilityData, ...earningData, submitCount: 0});
  };

  componentDidMount() {
    const {
      affordabilityData: {isNhf},
      setMortgageApplicationData
    } = this.props;
    // Reset affordability to zeros
    setMortgageApplicationData({property_value: "", down_payment: ""});
    if (!isNhf) this.handleCheckAgainClick();
  };

  componentWillUnmount() {
    clearErrorStore();
  }

  evaluateLoanAmount = amount => {
    let {rate, tenure} = this.state;
    const loan_amount = amount > 15000000 ? 15000000 : amount;
    rate = rate / (12 * 100);
    tenure = tenure * 12;
    const monthly_payment =
      (loan_amount * rate * (1 + rate) ** tenure) / ((1 + rate) ** tenure - 1);
    return [{loan_amount, monthly_payment}];
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {target} = event;
    const invalidInput = validateForm(event);
    if (this.state.submitCount) this.goToEligibility({target});
    if (invalidInput) return invalidInput.focus();

    const {setAffordabilityData, setEarningData, setLoading} = this.props;
    const data = {...this.state};
    delete data.submitCount;
    delete data.monthly_payment;
    delete data.properties;
    delete data.loan_amount;
    data.monthly_income = clearCommas(data.monthly_income);
    data.additional_income = clearCommas(data.additional_income);
    data.other_obligations = clearCommas(data.other_obligations);
    data.yob = getYearFromDOB(data.dob);

    try {
      let {
        data: { data: {loan_amount, monthly_payment} }
      } = await http.post('/mortgage/affordabilitytest', data);
      [{loan_amount, monthly_payment}] = this.evaluateLoanAmount(loan_amount);
      loan_amount = formatDecimals(loan_amount);
      monthly_payment = formatDecimals(monthly_payment);
      const {monthly_income, additional_income, dob} = data;
      setAffordabilityData({
        loan_amount,
        monthly_payment,
        dob,
        properties: null,
        other_obligations: data.other_obligations,
        tenure: data.tenure,
        rate: data.rate,
        isNhf: true,
        maxLoanableAmount: loan_amount
      });

      this.setState({
        monthly_payment,
        loan_amount,
        submitCount: this.state.submitCount + 1
      });
      setEarningData({monthly_income, additional_income});
      setLoading(true);
      const {
        data: {
          data: {properties, found}
        }
      } = await fetchProperties(loan_amount);
      console.log(properties, found);

      const timeout = setTimeout(() => {
        this.props.setAffordabilityData({
          ...this.props.affordabilityData,
          properties: {properties, found}
        });
        this.setState({...this.props.affordabilityData});
        clearTimeout(timeout);
      }, 1500);
    } catch (error) {} finally { setLoading(false); }
  };

  goToEligibility = () => {
    if (!this.props.isAuthenticated) {
      BrowserStorage.setItem(PENDING_DESTINATION, "/nhf/eligibility");
      return this.loginButtonRef.current.click();
    } else this.props.history.push("/nhf/eligibility");
  };

  render() {
    const {
      monthly_income,
      additional_income,
      loan_amount,
      monthly_payment,
      tenure,
      dob,
      submitCount,
      properties,
      other_obligations
    } = this.state;
    const {
      isLoading,
      mortgageApplicationData,
      setMortgageApplicationData
    } = this.props;
    const monthlyIncomeSatisfiesMinimumWage =
      parseFloat(clearCommas(monthly_income)) >= 30000;

    let maxTenure = 30,
      minTenure = 1;
    if (dob) {
      const yob = getYearFromDOB(dob);
      maxTenure = 60 - (new Date().getFullYear() - parseInt(yob, 10));
      if (!(maxTenure >= 1)) {
        maxTenure = 0;
        minTenure = 0;
      } else if (maxTenure >= 30) {
        maxTenure = 30;
      }
    }
    return (
      <div className='container-fluid px-0 nhf-affordability-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            NHF Affordability Test - Finance Plus | Real Estate, NHF, Mortgages
            &amp; Home Loans
          </title>
          <link
            rel='canonical'
            href='http://financeplus.ng/nhf/affordability'
          />
        </Helmet>
        <Header
          history={this.props.history}
          type='others'
          loginButtonRef={this.loginButtonRef}
        />
        <section id='fp-nhf-affordability-eligibility-application'>
          <div className='container-fluid'>
            <div className='row p-0'>
              <div
                className={`col-md-5 fp-nhf-affordability-eligibility-application-form ${
                  properties && properties.properties.length ? "overlay" : ""
                }`}
              >
                {/* <div className='col-md-5 fp-nhf-affordability-eligibility-application-form'> */}
                <div className='row'>
                  <div className='col-md-10 offset-md-1 mt-2'>
                    <h2>How much can I afford?</h2>
                    <p>
                      Calculate the home loan you qualify for, and how much you
                      can expect to pay monthly on your home loan repayments.
                    </p>

                    <form
                      ref={this.form}
                      onSubmit={this.handleSubmit}
                      noValidate
                    >
                      <div className='form-group row '>
                        <div className='col-md-12 mt-1'>
                          <label>Net Monthly Earnings</label>
                          <div className='input-group'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text px-3 bg-white'>
                                ₦
                              </span>
                            </div>
                            <input
                              name='annual_income'
                              type='text'
                              className='form-control'
                              data-state-name='monthly_income'
                              pattern={currencyRegexString}
                              value={formatCurrencyInput(monthly_income)}
                              onChange={this.handleCurrentInputChange}
                              required
                            />
                            <div className='input-group-append'>
                              <span className='input-group-text px-3 bg-theme-button-group'>
                                Monthly
                              </span>
                            </div>
                            <div className='invalid-feedback'>
                              {currencyFieldErrorMessage}
                            </div>
                          </div>
                          {!monthlyIncomeSatisfiesMinimumWage ? (
                            <div>
                              <input
                                type='text'
                                className='form-control'
                                pattern={yobRegexString}
                                value={"bola"}
                                style={{display: "none"}}
                                onChange={() => {}}
                              />
                              <div className='invalid-feedback'>
                                Income must satisfy the minimum wage (₦ 30,000)
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className='form-group row '>
                        <div className='col-md-12 mt-1'>
                          <label>Additional Monthly income</label>
                          <div className='input-group'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text px-3 bg-white'>
                                ₦
                              </span>
                            </div>
                            <input
                              name='additional_income'
                              type='text'
                              className='form-control'
                              data-state-name='additional_income'
                              pattern={`${currencyRegexString}|0`}
                              value={formatCurrencyInput(additional_income)}
                              onChange={this.handleCurrentInputChange}
                            />
                            <div className='input-group-append'>
                              <span className='input-group-text px-3 bg-theme-button-group'>
                                Monthly
                              </span>
                            </div>
                            <div className='invalid-feedback'>
                              {currencyFieldErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='form-group row '>
                        <div className='col-md-12 mt-1'>
                          <label>
                            Current Monthly Loan Repayments (if any)
                          </label>
                          <div className='input-group'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text px-3 bg-white'>
                                ₦
                              </span>
                            </div>
                            <input
                              name='monthly_obligation'
                              type='text'
                              className='form-control'
                              data-state-name='other_obligations'
                              pattern={`${currencyRegexString}|0`}
                              value={formatCurrencyInput(other_obligations)}
                              onChange={this.handleCurrentInputChange}
                            />
                            <div className='input-group-append'>
                              <span className='input-group-text px-3 bg-theme-button-group'>
                                Monthly
                              </span>
                            </div>
                            <div className='invalid-feedback'>
                              Invalid input value
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='form-group row '>
                        <div className='col-md-12 mt-1'>
                          <label>Year of Birth</label>
                          {
                            (() => {
                              const getAppendants = (toggleDatePickerDisplay) => [
                                (
                                  <div className='input-group-append'>
                                    <span className='input-group-text bg-theme-button-group'>
                                      <Icon.Calendar color='#495057' onClick={toggleDatePickerDisplay} />
                                    </span>
                                  </div>
                                ),
                                (
                                  <div className='invalid-feedback'>
                                    Invalid input value
                                  </div>
                                ),
                                maxTenure ? (
                                  ""
                                ) : (
                                  <div className='too-old-option'>
                                    <p>
                                      You're too old to apply for an NHF loan and so
                                      cannot continue. We're sorry.
                                    </p>
                                  </div>
                                )
                              ];
                              const thisYear = new Date().getFullYear();
                              const minimumYear = thisYear - 59;
                              const maximumYear = thisYear - 10;

                              return (
                                  <DatePicker
                                    {...{
                                      value: dob,
                                      minimumYear,
                                      maximumYear,
                                      getAppendants,
                                      requireValue: true,
                                      format: "DD/MM/YYYY",
                                      inputClassName: 'form-control',
                                      parentClassName: 'input-group',
                                      handleDateChange: (dob) => this.setState({ dob }),
                                    }}
                                  />
                              );
                            })()
                          }
                        </div>
                      </div>

                      {maxTenure ? (
                        <div className='form-group row'>
                          <div className='col-md-12'>
                            <label>How long do you want this loan for?</label>
                            <div className='range'>
                              <input
                                type='range'
                                min={minTenure}
                                max={maxTenure}
                                data-state-name='tenure'
                                value={tenure}
                                onChange={this.handleChange}
                              />
                              <div className='d-flex justify-content-between'>
                                <span>{minTenure}</span>
                                <span>{maxTenure}</span>
                              </div>
                              <div className='range-output'>
                                <output
                                  className='output'
                                  name='output'
                                  htmlFor='range'
                                  style={{
                                    transform:
                                      "translate(" +
                                      (this.state.tenure - 0) * 14.716 +
                                      "px, 20px)"
                                  }}
                                >
                                  {this.state.tenure} years
                                </output>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className='form-group row'>
                        <div className='col-md-12 fp-affordability-test-application-form-buttons'>
                          <button
                            type='button'
                            className='btn fp-save-result-button'
                            onClick={this.handleCheckAgainClick}
                            disabled={isLoading}
                          >
                            <Icon.RefreshCw size='22px' color='#00b1ab' />
                          </button>
                          <button
                            type='submit'
                            className='btn fp-check-loan-availability'
                            disabled={isLoading || !maxTenure}
                          >
                            {submitCount
                              ? "Check Loan Eligibility"
                              : "Check Loan Affordability"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className='col-md-7 fp-nhf-affordability-eligibility-application-sidebar'> */}
              <div
                className={`col-md-7 fp-nhf-affordability-eligibility-application-sidebar ${
                  properties && properties.properties.length ? "show" : ""
                }`}
              >
                <div className='fp-nhf-affordability-eligibility-application-sidebar-wrapper'>
                  <div className='d-flex justify-content-between'>
                    <div className='fp-affordability-test-loanable-amount'>
                      <span>Your maximum loanable Amount</span>
                      <h2>₦ {formatCurrencyInput(loan_amount)}</h2>
                    </div>
                    <div className='fp-affordability-test-monthly-repayment'>
                      <span>Estimated Monthly Repayment</span>
                      <h2>₦ {formatCurrencyInput(monthly_payment)}</h2>
                    </div>
                  </div>

                  <hr />
                  {properties && properties.properties.length ? (
                    <div className='fp-affordability-test-result-summary'>
                      <div className='fp-affordability-test-result-summary-text'>
                        <h2>Look What we have found</h2>
                        <p>
                          {properties.found
                            ? "Based on your input, we found you the properties below on newhomes.ng, and I think you’d absolutely love them!"
                            : "We couldn't find properties for your desired location but suggest you take a look at the following."}
                        </p>
                      </div>
                      <div className='fp-affordability-test-property-display'>
                        <PropertySuggestionList
                          {...{
                            properties,
                            mortgageApplicationData,
                            setMortgageApplicationData,
                            goToEligibility: this.goToEligibility
                          }}
                        />

                        <div className='row'>
                          <div className='col-md-12 result fp-affordability-test-application-form-buttons'>
                            <button
                              type='button'
                              className='btn fp-save-result-button'
                              onClick={this.handleCheckAgainClick}
                              disabled={isLoading}
                            >
                              <Icon.RefreshCw size='22px' color='#00b1ab' />
                            </button>

                            <button
                              type='submit'
                              className='btn fp-check-loan-availability'
                              onClick={() => {
                                setMortgageApplicationData(
                                  DEFAULT_MORTGAGE_APPLICATION_DATA
                                );
                                this.goToEligibility();
                              }}
                              disabled={isLoading}
                            >
                              Proceed to check Loan Eligibility
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className='fp-affordability-test-result-bg-pattern'></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    ...ownProps,
    earningData: state.earningData,
    affordabilityData: state.affordabilityData,
    isAuthenticated: state.isAuthenticated,
    mortgageApplicationData: state.mortgageApplicationData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading(isLoading) {
      dispatch(setLoading(isLoading));
    },
    setAffordabilityData(affordabilityData) {
      dispatch(setAffordabilityData(affordabilityData));
    },
    setEarningData(earningData) {
      dispatch(setEarningData(earningData));
    },
    setMortgageApplicationData(mortgageApplicationData) {
      dispatch(setMortgageApplicationData(mortgageApplicationData));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NhfAffordability);
