import React from "react";
import countries from '../../utils/countries';
import { getCountryByName } from '../../utils/countryStateUtils';


const nigeria = getCountryByName('Nigeria');

const CountrySelect = ({ value, handleChange, stateName }) => {
  return (
    <select
      className='form-control'
      id='Country'
      data-state-name={stateName}
      required
      value={value}
      onChange={handleChange}
    >
      <option value='' defaultValue>
        - Selected preferred option -
      </option>
      <option value={ nigeria.name }>{ nigeria.name }</option>
      {
        countries.map(({ name, id }) => (
          <option value={ name } key={ id }>{ name }</option>
        ))
      }
    </select>
  );
};

export default CountrySelect;
