import React, { useState } from "react";
import http from "../../config/axios.config";


const AssignLender = ({ lenders, slug, isLoading }) => {
  const [selectedLender, setSelectLender] = useState(0);

  const handleSubmit = async event => {
    event.preventDefault();
    const url = `/admin/mortgage/assign-to-lender/${slug}/${selectedLender}`;
    try {
      await http.get(url);
      window.location.reload();      
    } catch (error) {}
  };

  return (
    <div className='modal-content'>
      <div className='modal-body fp-modal-body-wrapper'>
        <a
          href='/'
          role='button'
          className='close fp-login-modal-close lender '
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>×</span>
        </a>

        <div>
          <form onSubmit={handleSubmit}>
            <h4>Assign Lender</h4>
            <div className='form-group'>
              <select
                className='form-control'
                id='Lenders'
                onChange={({ target: { value } }) => setSelectLender(value)}
                disabled={isLoading}
              >
                <option value='' defaultValue>
                  - Select preferred lender -
                </option>
                {lenders.length
                  ? lenders.map(lender => {
                      return (
                        <option key={lender.id} value={lender.id}>
                          {lender.lender_name}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
            <button
              type='submit'
              disabled={isLoading}
              className='fp-modal-login-button'
            >
              Assign Lender
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignLender;
