import React, { Component } from 'react';
import { handleChange, validateForm } from '../../utils/authFormUtils';
import BrowserStorage from '../../utils/browserStorageUtils';
import { handleBlurByMass } from '../../utils/eligibilityFormUtils';
import http from '../../config/axios.config';
import { phoneRegexString } from '../../utils/regexUtils';


class UpdateAccountForm extends Component {
  state = {
    firstname: '', lastname: '', email: '', address: '',
    phone: '', employment_status: '', sex: '',
  };

  /**
   * Handles change event on the form fields
   * @param {object} event
   */
  handleChange = event => handleChange(event, this);
  
  handleBlur = () => handleBlurByMass(this, ['firstname', 'lastname', 'email', 'phone']);

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    this.updateProfile();
  };

  /**
   * Handles server request to update user profile
   * @param {object} formElement
   */
  updateProfile = async () => {
    const { setToasterState } = this.props;
    try {
      await http.post('/profile/update', { ...this.state });
      setToasterState(true, 'Account updated successfully');
      const timeout = setTimeout(() => {
        setToasterState(false, '');
        clearTimeout(timeout);
      }, 2000);      
    } catch (error) {}
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className='fp-mortgage-application-settings-form'>
        <h2 className='fp-mortgage-application-settings-form-title'>
          Update Account Settings
        </h2>
        <p className='fp-mortgage-application-settings-form-subtitle'>
          Update your account information, such as email and name.
        </p>
        <form onSubmit={this.handleSubmit} autoComplete noValidate>
          <div className='form-group row'>
            <div className='col-md-4'>
              <label>First Name</label>
              <input
                type='text'
                className='form-control'
                pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*$'
                value={ this.state.firstname }
                data-state-name='firstname'
                placeholder='First Name'
                onChange={ this.handleChange }
                onBlur={this.handleBlur}
                required
              />
              <div className='invalid-feedback'>
                First name must consist only of letters and is
                required (hyphen separated names are allowed)
              </div>
            </div>
  
            <div className='col-md-4'>
              <label>Middle Name</label>
              <input
                type='text'
                className='form-control'
                data-state-name='middlename'
                pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*$'
                value={ this.state.middlename }
                onChange={ this.handleChange }
                onBlur={this.handleBlur}
                placeholder='Middle Name'
              />
              <div className='invalid-feedback'>
                Middle name (optional) may consist only of letters
                and is required (hyphen separated names are allowed)
              </div>
            </div>
  
            <div className='col-md-4'>
              <label>Last Name</label>
              <input
                type='text'
                className='form-control'
                data-state-name='lastname'
                value={ this.state.lastname }
                pattern='^[a-zA-Z]+(?:-[a-zA-Z]+)*$'
                onChange={ this.handleChange }
                onBlur={this.handleBlur}
                placeholder='Last Name'
                required
              />
              <div className='invalid-feedback'>
                Last name must consist only of letters and is
                required (hyphen separated names are allowed)
              </div>
            </div>
          </div>
  
          <div className='form-group row'>
            <div className='col-md-12'>
              <label>Residential Address</label>
              <input
                type='text'
                className='form-control'
                data-state-name='address'
                value={ this.state.address }
                onChange={ this.handleChange }
                placeholder='Address'
                required
              />
              <div className='invalid-feedback'>
                Address is required
              </div>
            </div>
          </div>
  
          <div className='form-group row'>
            <div className='col-md-6'>
              <label>Email Address</label>
              <input
                type='text'
                className='form-control'
                pattern='^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$'
                value={ this.state.email }
                data-state-name='email'
                onChange={ this.handleChange }
                onBlu={this.handleBlur}
                placeholder='Email Address'
                required
              />
              <div className='invalid-feedback'>
                Invalid email address
              </div>
            </div>
  
            <div className='col-md-6'>
              <label>Phone Number</label>
              <input
                type='text'
                className='form-control'
                value={ this.state.phonenumber }
                onChange={ this.handleChange }
                data-state-name='phone'
                pattern={phoneRegexString}
                placeholder='Phone Number'
                onBlur={this.handleBlur}
              />
              <div className='invalid-feedback'>
                Invalid phone number
              </div>
            </div>
          </div>
  
          <div className='form-group row'>
            <div className='col-md-6'>
              <label>Gender</label>
              <select
                className='form-control'
                id='Sex'
                data-state-name='sex'
                value={ this.state.sex }
                onChange={ this.handleChange }
                required
              >
                <option value='' defaultValue>
                  - Select preferred option -
                </option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </select>
              <div className='invalid-feedback'>
                Gender is required
              </div>
            </div>
            <div className='col-md-6'>
              <label>Employment Status</label>
              <select
                className='form-control'
                id='FormControlSelect1'
                data-state-name='employment_status'
                value={ this.state.employment_status }
                onChange={ this.handleChange }
                required
              >
                <option value='' defaultValue>
                  - Select preferred option -
                </option>
                <option value='employed'>
                  Employed
                </option>
                <option value='unemployed'>Unemployed</option>
              </select>
              <div className='invalid-feedback'>
                Please select a valid option
              </div>
            </div>
          </div>
  
          <div className='form-group row'>
            <div className='col-md-12'>
              <button
                type='submit'
                disabled={isLoading}
                className='fp-update-account-details-button'
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
 
export default UpdateAccountForm;