import React from "react";
import { toTitleCase, formatDate } from "../../utils/dashboardUtils";
import { formatCurrencyInput } from "../../utils/currencyUtils";

const TrackFormResult = props => {
  const {
    app_ref,
    created_at,
    lender_rate,
    loan_amount,
    loan_tenure,
    mortgage_status_name
  } = props;

  return (
    <div className='modal-content'>
      <div className='fp-check-mortgage-application-status'>
        <div className='fp-login-page-logo result'></div>
        <h2>Mortgage Application Tracker</h2>
        <a
          href='/'
          role='button'
          className='close fp-login-modal-close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span className='text-white' aria-hidden='true'>
            ×
          </span>
        </a>
      </div>

      <div className='modal-body fp-check-mortgage-application-status-body-wrapper'>
        <div className='fp-check-mortgage-application-status-body-wrapper-bg'></div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='fp-mortgage-application-loan-info-display result'>
              <div className='fp-mortgage-application-reference-no-loan-status'>
                <div className='fp-mortgage-application-reference-no'>
                  <span>Reference</span>
                  <h4>{app_ref}</h4>
                </div>
                <div className='fp-mortgage-application-date'>
                  <span>Application Submitted</span>
                  <h4>{formatDate(created_at)}</h4>
                </div>
                <div
                  className={`fp-mortgage-application-status${
                    mortgage_status_name
                      ? ` ${mortgage_status_name.toLowerCase()}`
                      : ""
                  }`}
                >
                  <span>Loan Status</span>
                  <h4>
                    {mortgage_status_name
                      ? toTitleCase(mortgage_status_name)
                      : ""}
                  </h4>
                </div>
              </div>
              <div className='fp-mortgage-loan-amount-repayment-interest-rate'>
                <div className='fp-mortgage-loan-amount-wrap'>
                  <h2>{loan_amount ? formatCurrencyInput(loan_amount) : ""}</h2>
                  <span>Loan Amount</span>
                </div>
                <div className='fp-mortgage-repayment'>
                  <h2> {loan_tenure} years</h2>
                  <span>Repayment period</span>
                </div>
                <div className='fp-mortgage-loan-interest-rate'>
                  <h2>{lender_rate ? lender_rate : "N/A"}</h2>
                  <span>Interest rate</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackFormResult;
