import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

// Auth Routes
import LoginPage from "./containers/LoginPage";
import RegisterPage from "./containers/RegisterPage";
import ForgetPassword from "./containers/ForgetPassword";

// Normal Routes
import HomePage from "./containers/HomePage";
import ContactPage from "./containers/ContactPage";
import FaqsPage from "./containers/Faqs";
import AboutUs from "./containers/AboutUs";
import PrivacyPolicyPage from "./containers/PrivacyPolicy";

import AffordabilityTestPage from "./containers/AffordabilityTestPage";
import EligibilityTestPage from "./containers/EligibilityTestPage";
import ApplicationType from "./containers/ApplicationType";
import ApplicationTypeV2 from "./containers/ApplicationTypeV2";

// NHF Routes
import NhfAffordability from "./containers/NhfAffordability";
import NhfEligibility from "./containers/NhfEligibilityPage";

// User Dashboard Routes
import UserDashboard from "./containers/UserDashboard";
import SingleApplication from "./containers/SingleApplication";
import UserMortgageApplications from "./containers/UserMortgageApplications";
import GeneralSettings from "./containers/GeneralSettings";
import MortgageApplication from "./containers/MortgageApplication";
// import NhfMortgageApplication from "./containers/NhfMortgageApplication";
import DocumentUploadsPage from "./containers/DocumentUploadsPage";

// Lender Dashboard Routes
import LenderDashboard from "./containers/LenderDashboard";
import LenderMortgageApplicationTable from "./containers/LenderMortgageApplicationTable";
import LenderUserSingleApplication from "./containers/LenderUserSingleApplication";
import LenderCustomerTable from "./containers/LenderCustomerTable";

// Admin Dashboard Routes
import AdminDashboard from "./containers/AdminDashboard";
import AdminMortgageApplicationTable from "./containers/AdminMortgageApplicationTable";
import AdminUserSingleApplication from "./containers/AdminUserSingleApplication";
import {LOGIN_PAGE_URL, REGISTER_URL} from "./constants";
import Error404Page from "./containers/Error404Page";
import store from "./store";
import setLoading from "./store/actions/setLoading";
import MortgageApplicationUnprotected from "./containers/MortgageApplicationUnprotected";

class Routes extends Component {
  componentDidMount() {
    store.dispatch(setLoading(false));
  }
  
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path='/' component={HomePage} />

            <Route exact path='/company/about' component={AboutUs} />

            <Route exact path='/contact' component={ContactPage} />

            <Route exact path='/support/faqs' component={FaqsPage} />

            <Route
              exact
              path='/legal/privacy-policy'
              component={PrivacyPolicyPage}
            />

            {/* AUTH Routes */}
            <Route exact path={LOGIN_PAGE_URL} component={LoginPage} />

            <Route exact path={REGISTER_URL} component={RegisterPage} />

            <Route
              exact
              path='/auth/password/reset'
              component={ForgetPassword}
            />

            <Route
              exact
              path='/mortgage/application-type'
              component={ApplicationType}
            />
            <Route
              exact
              path='/mortgage/application-type/test'
              component={ApplicationTypeV2}
            />

            <Route
              exact
              path='/affordability-test'
              component={AffordabilityTestPage}
            />

            <Route
              exact
              path='/eligibility-test'
              component={EligibilityTestPage}
            />

            {/* NHF Routes */}
            <Route
              exact
              path='/nhf/affordability'
              component={NhfAffordability}
            />

            <Route exact path='/nhf/eligibility' component={NhfEligibility} />

            <Route
              exact
              path='/nhf/mortgage/application'
              component={MortgageApplication}
            />

            <Route
              exact
              path='/mortgage/application/:slug/edit'
              component={MortgageApplication}
            />

            {/* User Dashboard */}
            <Route exact path='/user/dashboard' component={UserDashboard} />
            <Route
              exact
              path='/user/dashboard/application/view/:slug'
              component={SingleApplication}
            />
            <Route
              exact
              path='/user/dashboard/application'
              component={UserMortgageApplications}
            />

            <Route
              exact
              path='/user/dashboard/settings'
              component={GeneralSettings}
            />

            <Route
              exact
              path='/user/dashboard/uploads'
              component={DocumentUploadsPage}
            />

            <Route
              exact
              path='/mortgage/application'
              component={MortgageApplication}
            />
            
            <Route
              exact
              path='/mortgage/application/redirect'
              component={MortgageApplicationUnprotected}
            />

            {/* Lender Dashboard */}
            <Route exact path='/lender/dashboard' component={LenderDashboard} />

            <Route
              exact
              path='/lender/dashboard/application'
              component={LenderMortgageApplicationTable}
            />

            <Route
              exact
              path='/lender/dashboard/application/view/:slug'
              component={LenderUserSingleApplication}
            />

            <Route
              exact
              path='/lender/dashboard/customers'
              component={LenderCustomerTable}
            />

            {/* Admin Dashboard */}
            <Route exact path='/admin/dashboard' component={AdminDashboard} />

            <Route
              exact
              path='/admin/dashboard/application'
              component={AdminMortgageApplicationTable}
            />

            <Route
              exact
              path='/admin/dashboard/application/view/:slug'
              component={AdminUserSingleApplication}
            />

            <Route component={Error404Page} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Routes;
