import React from 'react';
import { currencyRegexString } from '../../utils/regexUtils';
import { formatCurrencyInput } from '../../utils/currencyUtils';
import { handleCurrencyInputChange } from '../../utils/eligibilityFormUtils';


const BankAccountsListItem = ({ loan, index, loans, setOutstandingLoans }) => {
  const handleChange = async (event) => {
    const { target: { name, value } } = event;
    let formattedValue = [
      'outstanding_amount', 'existing_payment'
    ].includes(name) ? await handleCurrencyInputChange(event) : value;
    formattedValue = formattedValue ? formattedValue : value;
    loans[index] = { ...loan, [name]: formattedValue };
    setOutstandingLoans(loans);
  };

  const handleButtonClick = () => {
    if (loans.length) {
      loans.splice(index, 1);
      return setOutstandingLoans(loans);
    }
  };

  const { lender_name, outstanding_amount, loan_type, loan_frequency, existing_payment } = loan;
  return (
    <div
      className={`row no-gutters mt-2 p-0${!loans.length || index === loans.length - 1 ? ' last-child' : '' }`}
    >
      <div className='col-md-2'>
        <input
          type='text'
          className='form-control fp-child-name'
          placeholder='Lender name'
          name='lender_name'
          value={lender_name || ''}
          onChange={handleChange}
          required
        />
        <div className='invalid-feedback'>Value is required</div>
      </div>
      <div className='col-md-2'>
        <select
          name='loan_type'
          value={loan_type || ''}
          className='form-control fp-child-age'
          onChange={handleChange}
          required
        >
          <option value='' defaultValue>- Select type -</option>
          <option value="personal loan">Personal loan</option>
          <option value="mortgage">Mortgage</option>
          <option value="payday loan">Payday loan</option>
          <option value="car loan">Car loan</option>          
          <option value="others">Others</option>          
        </select>
        <div className='invalid-feedback'>Value is required</div>
      </div>
      <div className='col-md-2'>
        <input
          type='text'
          value={outstanding_amount ? formatCurrencyInput(outstanding_amount) : ''}
          className='form-control fp-child-age'
          placeholder='Outstanding Amount'
          name='outstanding_amount'
          pattern={currencyRegexString}
          onChange={handleChange}
          required
        />
        <div className='invalid-feedback'>
          Please enter a valid amount
        </div>
      </div>
      <div className='col-md-2'>
        <select
          name='loan_frequency'
          className='form-control fp-child-age'
          value={loan_frequency || ''}
          onChange={handleChange}
          required
        >
          <option value="" defaultValue>- Select frequency -</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="yearly">Yearly</option>
        </select>
        <div className='invalid-feedback'>Value is required</div>
      </div>
      <div className='col-md-2'>
        <input
          type='text'
          value={existing_payment ? formatCurrencyInput(existing_payment) : ''}
          className='form-control fp-child-age'
          placeholder='Existing payment'
          name='existing_payment'
          pattern={currencyRegexString}
          onChange={handleChange}
          required
        />
        <div className='invalid-feedback'>
          Please enter a valid amount
        </div>
      </div>
      <div className='col-md-1 ml-4'>
        <button
          type='button'
          className='btn btn-primary h-100 w-100 theme-color'
          onClick={handleButtonClick}
        >
          -
        </button>
      </div>
    </div>
  );
}

export default BankAccountsListItem;
