import React from "react";

import './circularLoader.css';

const CircularLoader = ({ background, isLoading }) => (
  <div
    className={`loader-wrapper${ isLoading ? '' : ' hide' }`}
    style={{ background: background ? background : '#99999933' }}
  >
    <div className="loader"></div>
  </div>
);

export default CircularLoader;
