import React, {Component} from "react";
import "../Buttons/Buttons.css";

import Warning from "../Resource/negative.png";

const styles = {
  WarningImage: {
    backgroundImage: `url(${Warning})`
  }
};

class EligibilityErrors extends Component {
  render() {
    const {errors, setParentState, componentIndex} = this.props;
    const errorTemplate = errors.length
      ? errors.map((msg, ind) => {
          return (
            <li key={ind}>
              <span>{msg}</span>
            </li>
          );
        })
      : "";
    return (
      <div className='fp-eligibility-test-application-form-wrapper result'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div
              className='fp-eligibility-test-warning-image'
              style={styles.WarningImage}
            ></div>
            <h2 className='fp-eligibility-test-error-text'>
              Sorry, you are not eligible.
            </h2>

            <p className='fp-eligibility-test-error-text-subtitle'>
              Sorry, you do not qualify for a home loan at the moment, because
              you do not meet all the necessary requirements.
            </p>

            <ul className='fp-error-container'>{errorTemplate}</ul>
            <div className='fp-eligibility-test-application-button-wrapper'>
              <button
                type='button'
                className='fp-send-email-with-results'
                onClick={() =>
                  setParentState({activeComponentIndex: componentIndex - 2})
                }
              >
                Back
              </button>
              <button
                type='button'
                className='fp-eligibility-apply-for-mortgage'
              >
                Speak with a representative
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EligibilityErrors;
