import React, { Component } from "react";

import * as Icon from "react-feather";
import MultiStepFormButtons from "../MultiStepFormButtons";
import CountrySelect from "./CountrySelect";
import { handleChange, validateForm } from "../../utils/authFormUtils";
import StateSelect from "./StateSelect";
import { requiredFieldErrorMessage, invalidDateErrorMessage } from "../../utils/validationMessageUtils";
import { dateRegexString, nameRegexString, phoneRegexString } from "../../utils/regexUtils";
import { handleSingleFieldBlur } from "../../utils/eligibilityFormUtils";
import ApplicationProgressBar from "./ApplicationProgressBar";
import DatePicker from "../DatePicker";

class PersonalInfoFormStepOne extends Component {
  state = {
    type: this.props.type,
    app_ref: this.props.app_ref,
    firstname: this.props.firstname,
    lastname: this.props.lastname,
    middlename: this.props.middlename,
    email: this.props.email,
    phone: this.props.phone || '',
    dob: this.props.dob,
    place_of_birth: this.props.place_of_birth,
    sex: this.props.sex,
    nationality: this.props.nationality,
    loan_tenure: this.props.loan_tenure,
    highest_education: this.props.highest_education,
    state_of_origin: this.props.state_of_origin,
    address: this.props.address,
    profession: this.props.profession,
    loan_amount: this.props.loan_amount,
    down_payment: this.props.down_payment,
    monthly_net_pay: this.props.monthly_net_pay,
    monthly_gross_pay: this.props.monthly_gross_pay,
    mother_middle_name: this.props.mother_middle_name,
    means_of_identification: this.props.means_of_identification,
    id_number: this.props.id_number,
    id_issue_date: this.props.id_issue_date,
    id_expire_date: this.props.id_expire_date,
    property_value: this.props.property_value,
    property_title: this.props.property_title,
    property_address: this.props.property_address,
    isLoggedIn: this.props.isAuthenticated,
    password: this.props.password
  };

  componentWillUnmount() {
    this.props.setParentState({ ...this.state });
  }

  handleBlur = ({ target }) => handleSingleFieldBlur(target, this);

  /**
   * Handles change event on the form fields
   * @param {object} event
   */
  handleChange = event => {
    const { target: { dataset: { stateName }, value } } = event;
    if (['dob', 'id_issue_date', 'id_expire_date'].includes(stateName)) {
      if (value.length > this.state[stateName].length && [2, 5].includes(value.length)) event.target.value += '/';
    }
    handleChange(event, this);
  };

  handleIDIssueDateChange = (id_issue_date) => this.setState({ id_issue_date });
  
  handleIDExpiryDateChange = (id_expire_date) => this.setState({ id_expire_date });

  /**
   * Sets user nationality
   */
  setNationality = ({ target: { value } }) => this.setState({ nationality: value });

  setStateOfOrigin = ({ target: { value } }) => this.setState({ state_of_origin: value });

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    const { postStepApplication, nhf_registration_number } = this.props;
    // eslint-disable-next-line no-sparse-arrays
    await postStepApplication(...[this, {...this.state}, , , nhf_registration_number])
  };

  render() {
    const { idCards } = this.props;
    const { isLoggedIn } = this.state;
    const thisYear = new Date().getFullYear();

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className='row fp-mortgage-application'>
          <div className='col-md-10 offset-md-1'>
            <div className='fp-mortgage-landing-page-with-nhanla'>
              <ApplicationProgressBar
                step={this.props.componentIndex}
                parentClassName='col-md-10 offset-md-1'
              />
              <div className='fp-personal-info-form'>
                <div className='row'>
                  <div className='col-md-10 offset-md-1'>
                    <span>Ordinary Mortgage | Personal Info</span>
                    <h2>
                      Alright then! I would like to know you better, please tell
                      me a little about yourself.
                    </h2>
                    <div className='form-group row'>
                      <div className='col-md-3'>
                        <label>First Name</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='firstname'
                          placeholder='First name'
                          pattern={nameRegexString}
                          value={this.state.firstname}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          First name must consist only of letters and is
                          required (hyphen separated names are allowed)
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <label>Last Name</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='lastname'
                          placeholder='Last name'
                          pattern={nameRegexString}
                          value={this.state.lastname}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Last name must consist only of letters and is required
                          (hyphen separated names are allowed)
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <label>Middle Name</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='middlename'
                          placeholder='Middle name'
                          pattern={nameRegexString}
                          value={this.state.middlename}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        <div className='invalid-feedback'>
                          Middle name (optional) may consist only of letters and
                          is required (hyphen separated names are allowed)
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <label>Mother's Maiden Name</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='mother_middle_name'
                          placeholder="Mother's maiden name"
                          pattern={nameRegexString}
                          value={this.state.mother_middle_name}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>{requiredFieldErrorMessage}</div>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <label>Address</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Address'
                          data-state-name='address'
                          value={this.state.address}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Address is required
                        </div>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className={`col-md-${isLoggedIn ? 4 : 3}`}>
                        <label>Email Address</label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Email Address'
                          data-state-name='email'
                          pattern='^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$'
                          value={this.state.email}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Invalid email address
                        </div>
                      </div>

                      {
                        isLoggedIn ? '' : (
                          <div className={`col-md-${isLoggedIn ? 4 : 3}`}>
                            <label>Password</label>
                            <input
                              type='password'
                              className='form-control'
                              placeholder='Password'
                              data-state-name='password'
                              minLength='6'
                              value={this.state.password}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              required
                            />
                            <div className='invalid-feedback'>
                              Password must be at least 6 characters long
                            </div>
                          </div>
                        )
                      }

                      <div className={`col-md-${isLoggedIn ? 4 : 3}`}>
                        <label>Phone</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Phone'
                          data-state-name='phone'
                          pattern={phoneRegexString}
                          value={this.state.phone}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Invalid phone number
                        </div>
                      </div>

                      <div className={`col-md-${isLoggedIn ? 4 : 3}`}>
                        <label>Date of Birth</label>
                        <div className='input-group'>
                          {
                              (() => {
                                const getAppendants = (toggleDatePickerDisplay) => [
                                  (
                                    <div className='input-group-append'>
                                      <span className='input-group-text bg-theme-button-group'>
                                        <Icon.Calendar color='#495057' onClick={toggleDatePickerDisplay} />
                                      </span>
                                    </div>
                                  ),
                                  (
                                    <div className='invalid-feedback'>
                                      Invalid date
                                    </div>
                                  )
                                ];
                                const thisYear = new Date().getFullYear();
                                const minimumYear = thisYear - 59;
                                const maximumYear = thisYear - 10;

                                return (
                                    <DatePicker
                                      {...{
                                        minimumYear,
                                        maximumYear,
                                        getAppendants,
                                        requireValue: true,
                                        format: "DD/MM/YYYY",
                                        value: this.state.dob,
                                        inputClassName: 'form-control',
                                        parentClassName: 'input-group',
                                        handleDateChange: (dob) => this.setState({ dob }),
                                      }}
                                    />
                                );
                              })()
                          }
                        </div>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-4'>
                        <label htmlFor='Sex'>Sex</label>
                        <select
                          className='form-control'
                          id='Sex'
                          data-state-name='sex'
                          value={this.state.sex}
                          onChange={this.handleChange}
                          required
                        >
                          <option value='' defaultValue>
                            - Select preferred option -
                          </option>
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                        <div className='invalid-feedback'>Sex is required</div>
                      </div>
                      <div className='col-md-4'>
                        <label>Place of Birth</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='place_of_birth'
                          placeholder='Place of birth'
                          value={this.state.place_of_birth}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Place of birth is required
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <label htmlFor='Country'>Nationality</label>
                        <CountrySelect
                          value={this.state.nationality}
                          handleChange={this.setNationality}
                          stateName={"nationality"}
                        />
                        <div className='invalid-feedback'>
                          Please select a valid country
                        </div>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-4'>
                        <label>State of Origin</label>
                        <StateSelect
                          value={this.state.state_of_origin}
                          handleChange={ this.setStateOfOrigin }
                          nationality={ this.state.nationality }
                        />
                        <div className='invalid-feedback'>
                          State of origin is required
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <label htmlFor='FormControlSelect1'>
                          Highest Educational Qualification
                        </label>
                        <select
                          className='form-control'
                          id='FormControlSelect1'
                          data-state-name='highest_education'
                          value={this.state.highest_education}
                          onChange={this.handleChange}
                          required
                        >
                          <option value='' defaultValue>
                            - Select preferred option -
                          </option>
                          {
                            [
                              'BSc', 'Ba', 'Msc', 'PhD', 'Doctoral degree', "Master's degree",
                              'Higher National Diploma', "Bachelor's degree with honours",
                              "Non-honours Bachelor's degree", 'Higher National Certificate'
                            ].map((cert, index) => <option value={cert} key={index} >{cert}</option>)
                          }
                        </select>
                        <div className='invalid-feedback'>
                          Please select a valid qualification
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <label>Profession</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='profession'
                          placeholder='Profession'
                          value={this.state.profession}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>
                          Profession is required
                        </div>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-3'>
                        <label>Means of Identification</label>
                        <select
                          className='form-control'
                          data-state-name='means_of_identification'
                          placeholder='Means of identification'
                          value={this.state.means_of_identification}
                          onChange={this.handleChange}
                          required
                        >
                          <option value='' defaultValue>
                            - Select preferred option -
                          </option>
                          {
                            idCards.map(({ id, name }) => <option value={name} key={id} >{name}</option>)
                          }
                        </select>
                        <div className='invalid-feedback'>{requiredFieldErrorMessage}</div>
                      </div>

                      <div className='col-md-3'>
                        <label>ID Number</label>
                        <input
                          type='text'
                          className='form-control'
                          data-state-name='id_number'
                          placeholder='ID number'
                          value={this.state.id_number}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>{requiredFieldErrorMessage}</div>
                      </div>

                      <div className='col-md-3'>
                        <label>Date of Issue</label>
                        {
                          (() => {
                            const getAppendants = (toggleDatePickerDisplay) => [
                              (
                                <div key={0} className='input-group-append'>
                                  <span className='input-group-text px-3 bg-theme-button-group'>
                                    <Icon.Calendar color='#00b1ab' onClick={toggleDatePickerDisplay} />
                                  </span>
                                </div>
                              ),
                              <div key={1} className='invalid-feedback'>{requiredFieldErrorMessage}</div>,
                            ];


                            return (
                                <DatePicker
                                  {...{
                                    getAppendants,
                                    requireValue: true,
                                    format: "DD/MM/YYYY",
                                    maximumYear: thisYear,
                                    inputClassName: 'form-control',
                                    parentClassName: 'input-group',
                                    value: this.state.id_issue_date,
                                    handleDateChange: this.handleIDIssueDateChange,
                                  }}
                                />
                            );
                          })()
                        }
                        {/* <input
                          type='text'
                          className='form-control'
                          data-state-name='id_issue_date'
                          placeholder='DD/MM/YYYY'
                          value={this.state.id_issue_date}
                          pattern={dateRegexString}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>{invalidDateErrorMessage}</div> */}
                      </div>

                      <div className='col-md-3'>
                        <label>Expiry Date</label>
                        {
                          (() => {
                            const getAppendants = (toggleDatePickerDisplay) => [
                              (
                                <div key={0} className='input-group-append'>
                                  <span className='input-group-text px-3 bg-theme-button-group'>
                                    <Icon.Calendar color='#00b1ab' onClick={toggleDatePickerDisplay} />
                                  </span>
                                </div>
                              ),
                              <div key={1} className='invalid-feedback'>{requiredFieldErrorMessage}</div>,
                            ];
                            return (
                                <DatePicker
                                  {...{
                                    getAppendants,
                                    requireValue: true,
                                    format: "DD/MM/YYYY",
                                    minimumYear: thisYear,
                                    inputClassName: 'form-control',
                                    parentClassName: 'input-group',
                                    value: this.state.id_expire_date,
                                    handleDateChange: this.handleIDExpiryDateChange,
                                  }}
                                />
                            );
                          })()
                        }
                        {/* <input
                          type='text'
                          className='form-control'
                          data-state-name='id_expire_date'
                          placeholder="DD/MM/YYYY"
                          value={this.state.id_expire_date}
                          pattern={dateRegexString}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          required
                        />
                        <div className='invalid-feedback'>{invalidDateErrorMessage}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <MultiStepFormButtons
              type='Mortgage-Application'
              handleBackClick={this.props.goToPreviousComponent}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default PersonalInfoFormStepOne;
