import React, { Component } from "react";
import { connect } from "react-redux";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
// import DashboardBreadCrumb from "../DashboardBreadCrumb";
import CircularLoader from "../CircularLoader";
import BioDataInfo from "../BioDataInfo";

import "./GeneralSettings.css";
import setErrors from "../../store/actions/setErrors";
import setLoading from "../../store/actions/setLoading";
import UpdateAccountForm from "./UpdateAccountForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import Toaster from "../../Toaster";
import { clearErrorStore } from "../../utils/errorUtils";
import ErrorToaster from "../ErrorToaster";

class GeneralSettings extends Component {
  state = { showToast: false, toastContent: "" };

  componentDidMount() {
    document.title = "General Settings | Finance Plus";
  };

  componentWillUnmount() {
    clearErrorStore();
  }

  setToasterState = (showToast, toastContent) =>
    this.setState({ showToast, toastContent });

  render() {
    const { isLoading } = this.props;
    const { showToast, toastContent } = this.state;
    return (
      <div className='container-fluid px-0'>
        <ErrorToaster />
        <Toaster content={toastContent} showToast={showToast} />
        <CircularLoader isLoading={isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='User' />
        <div className='fp-mortgage-content-wrapper'>
          <div className='container'>
            <div className='fp-dashboard-section'>
              <div className='row'>
                <div className='col-md-4 fp-mortgage-application-lender-info-wrapper'>
                  <BioDataInfo />
                  <div className='fp-mortgage-application-credit-score'>
                    <p>CREDIT RATING</p>
                    <h2>8.90</h2>
                    <div className='fp-mortgage-application-credit-score-icon'></div>
                  </div>
                </div>
                <div className='col-md-8'>
                  <UpdateAccountForm
                    isLoading={isLoading}
                    history={this.props.history}
                    setErrors={this.props.setErrors}
                    setLoading={this.props.setLoading}
                    setToasterState={this.setToasterState}
                    setIsAuthenticated={this.props.setIsAuthenticated}
                  />
                  <UpdatePasswordForm
                    isLoading={isLoading}
                    history={this.props.history}
                    setErrors={this.props.setErrors}
                    setLoading={this.props.setLoading}
                    setToasterState={this.setToasterState}
                    setIsAuthenticated={this.props.setIsAuthenticated}
                  />

                  <div className='fp-mortgage-application-settings-form mt-4'>
                    <h2 className='fp-mortgage-application-settings-form-title'>
                      Remove account
                    </h2>
                    <p className='fp-mortgage-application-settings-form-subtitle'>
                      Remove your account. It is important to notice that this
                      is an irreversible action. So act with caution.
                    </p>
                    <div>
                      <div className='form-group row'>
                        <div className='col-md-12'>
                          <a
                            href='#modalRemoveAccount'
                            data-toggle='modal'
                            className='fp-remove-account-button'
                          >
                            Remove Account
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='modal fade'
          id='modalRemoveAccount'
          role='dialog'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered wd-sm-400'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-body fp-modal-body-wrapper remove'>
                <a
                  href='/'
                  role='button'
                  className='close fp-login-modal-close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>×</span>
                </a>
                <h4>Remove Account</h4>
                <p>
                  This action is irreversible. Are you sure you want to proceed?
                </p>
                <button className='fp-remove-account-button'>
                  Remove Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errors: state.errors,
    isLoading: state.isLoading,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setErrors(errors) {
      dispatch(setErrors(errors));
    },
    setLoading(isLoading) {
      dispatch(setLoading(isLoading));
    },
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettings);
