import {createStore, applyMiddleware} from "redux";
import {persistStore, persistReducer} from "redux-persist";
// import storage from 'redux-persist/lib/storage';
import storage from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {BASE_URL} from "../constants";

const persistConfig = {key: "root", storage};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk.withExtraArgument({baseUrl: BASE_URL}))
);

export const persistedStore = persistStore(store);
export default store;
