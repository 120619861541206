import React, {Component} from "react";
import uuid from "uuid/v1";

import MultiStepFormButtons from "../MultiStepFormButtons";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import {formatCurrencyInput, clearCommas} from "../../utils/currencyUtils";
import {handleCurrencyInputChange} from "../../utils/eligibilityFormUtils";
import OtherIncomeSourcesListItem from "./OtherIncomeSourcesListItem";
import {toTitleCase} from "../../utils/dashboardUtils";
import {currencyRegexString, yobRegexString} from "../../utils/regexUtils";
// import BankAccountsListItem from "./BankAccountsListItem";
import OutstandingLoansListItem from "./OutstandingLoansListItem";
// import UserAssetsListItem from "./UserAssetsListItem";
import {
  currencyFieldErrorMessage,
  requiredFieldErrorMessage
} from "../../utils/validationMessageUtils";
import {DEFAULT_RADIO_VALUES} from "../../constants";
import ApplicationProgressBar from "./ApplicationProgressBar";

const moneyFields = [
  "total_annual_pay",
  "monthly_gross_pay",
  "monthly_net_pay",
  "monthly_expenses"
];
const arrayFieldRadios = {
  // 'have_user_assets': 'user_assets',
  'have_existing_obligation': 'outstanding_loans'
};


class FinancialInfoStepFormOne extends Component {
  state = {
    app_ref: this.props.app_ref,
    // have_user_assets: this.props.have_user_assets,
    // user_assets: this.props.user_assets,
    // bank_accounts: this.props.bank_accounts,
    outstanding_loans: this.props.outstanding_loans,
    total_annual_pay: formatCurrencyInput(parseFloat(this.props.monthly_net_pay) * 12) || "",
    monthly_gross_pay: formatCurrencyInput(this.props.monthly_gross_pay),
    monthly_net_pay: formatCurrencyInput(this.props.monthly_net_pay),
    monthly_expenses: formatCurrencyInput(this.props.monthly_expenses),
    other_source_of_income: this.props.other_source_of_income,
    have_existing_obligation: this.props.have_existing_obligation,
    have_expenses: Boolean(this.props.monthly_expenses)
  };

  clearCommas = () => {
    const clone = {...this.state};
    delete clone.have_expenses;
    moneyFields.forEach(key => {
      clone[key] = clearCommas(clone[key]);
    });
    return clone;
  };

  componentWillUnmount() {
    const clone = this.clearCommas();
    this.props.setParentState(clone);
  }

  setStateValues = pairs => this.setState(pairs);

  handleChange = event => {
    const {
      target: {
        dataset: { stateName },
        value
      }
    } = event;
    handleChange(event, this);

    if (Object.keys(arrayFieldRadios).includes(stateName) && value === 'no') {
      this.setState({ [arrayFieldRadios[stateName]]: [] });
    } else if (stateName === 'have_expenses' && value === 'no') this.setState({ 'monthly_expenses': '' });
  };

  handleCurrencyInputChange = event => {
    handleCurrencyInputChange(event, this);
    const {
      target: {
        dataset: {stateName},
        value
      }
    } = event;
    if (stateName === "monthly_net_pay") {
      const monthly_net_pay = parseInt(clearCommas(value), 10);
      let total_annual_pay = "";
      if (!isNaN(monthly_net_pay))
        total_annual_pay = formatCurrencyInput(monthly_net_pay * 12);

      this.setState({total_annual_pay});
    }
  };

  handleOutstandingLoanRadio = ({ target: { value } }) => {
    const data = { have_existing_obligation: value };
    if (value === 'no') data.outstanding_loans = [];
    this.setState(data);
  };

  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();

    let {other_source_of_income, /*user_assets, bank_accounts*/} = this.state;
    // user_assets = [...user_assets];
    // bank_accounts = [bank_accounts];
    other_source_of_income = [...other_source_of_income];
    const lastSource = {
      ...other_source_of_income[other_source_of_income.length - 1]
    };
    // const lastAsset = {...user_assets[user_assets.length - 1]};
    // const lastAccount = {...bank_accounts[bank_accounts.length - 1]};
    const slimUpdates = {};
    [
      // [lastAsset, user_assets, "user_assets"],
      // [lastAccount, bank_accounts, "bank_accounts"],
      [lastSource, other_source_of_income, "other_source_of_income"]
    ].forEach((lastEl, list, stateName) => {
      if (lastEl) {
        delete lastEl.id;
        if (!Object.values(lastEl).join("")) {
          list.pop();
          slimUpdates[stateName] = list;
        }
      }
    });
    if (Object.keys(slimUpdates).length) await this.setState(slimUpdates);
    await this.props.postStepApplication(...[this, this.clearCommas(), 4]);
  };

  addToList = ({target}, stateName) => {
    const {[stateName]: list} = this.state;
    const inputsParent = target.closest(".col-md-12").querySelector(".row.last-child");
    const invalidInput = inputsParent.querySelector("input:invalid");
    if (invalidInput) {
      inputsParent.classList.add("was-validated");
      return invalidInput.focus;
    }

    const lastChild = list[list.length - 1];
    if (!lastChild || !Object.values(lastChild).join("").trim()) return;
    this.setState({[stateName]: [...list, {id: uuid()}]});
  };

  render() {
    const {
      have_existing_obligation,
      other_source_of_income,
      // have_user_assets,
      // user_assets,
      // bank_accounts,
      outstanding_loans,
      have_expenses
    } = this.state;
    let {firstname, additional_income, /*banks*/} = this.props;
    const hasExistingObligation = have_existing_obligation === "yes";
    // const hasAssets = have_user_assets === "yes";
    additional_income =
      additional_income && parseFloat(clearCommas(additional_income));
    const theresAdditionalIncome =
      !isNaN(additional_income) && Boolean(additional_income);
    const hasExpenses =
      typeof have_expenses === "boolean"
        ? have_expenses
        : have_expenses === "yes";
    let otherIncomeSourcesTotal = (() => {
      return other_source_of_income.length
        ? other_source_of_income.reduce((acc, {amount}) => {
            const amountFloat = parseFloat(amount);
            return amountFloat ? amountFloat + acc : acc;
          }, 0)
        : 0;
    })();

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className='row fp-mortgage-application'>
          <div className='col-md-10 offset-md-1'>
            <div className='fp-mortgage-landing-page-with-nhanla'>
              <ApplicationProgressBar
                step={this.props.componentIndex}
                parentClassName='col-md-10 offset-md-1'
              />
              <div className='row'>
                <div className='col-md-10 offset-md-1 fp-personal-info-form'>
                  <span>Ordinary Mortgage | Financial Info</span>
                  <h2>
                    Looking good {toTitleCase(firstname)}! Let’s talk about your
                    finances, our partner lenders will need the info below.
                  </h2>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <span className='float-right text-black'>
                        Employment Income
                      </span>
                      <label>Total Annual Pay</label>
                      <div className='input-group mb-3 disabled'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-3 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          name='monthly_income'
                          type='text'
                          className='form-control'
                          placeholder='Annual Pay'
                          data-state-name='total_annual_pay'
                          value={this.state.total_annual_pay}
                          disabled
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text px-3 bg-theme-button-group'>
                            yearly
                          </span>
                        </div>
                        <div className='invalid-feedback'>
                          Please enter a valid number (sequence of digits)
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <span className='float-right text-black'>
                        Employment Income
                      </span>
                      <label>Monthly Gross Pay</label>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-3 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          name='monthly_income'
                          type='text'
                          className='form-control'
                          placeholder='Monthly income'
                          data-state-name='monthly_gross_pay'
                          pattern={currencyRegexString}
                          value={this.state.monthly_gross_pay}
                          onChange={this.handleCurrencyInputChange}
                          required
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text px-3 bg-theme-button-group'>
                            Monthly
                          </span>
                        </div>
                        <div className='invalid-feedback'>
                          {currencyFieldErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <span className='float-right text-black'>
                        Employment Income
                      </span>
                      <label>Monthly Net Pay</label>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text px-3 bg-white'>
                            ₦
                          </span>
                        </div>
                        <input
                          name='monthly_income'
                          type='text'
                          className='form-control'
                          placeholder='Monthly net'
                          data-state-name='monthly_net_pay'
                          value={this.state.monthly_net_pay}
                          disabled
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text px-3 bg-theme-button-group'>
                            Monthly
                          </span>
                        </div>
                        <div className='invalid-feedback'>
                          {currencyFieldErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>

                  {theresAdditionalIncome ? (
                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <span className='float-right text-black'>
                          {/* Sum the amount of all extra sources if there's any */}
                          Total: ₦{" "}
                          {formatCurrencyInput(otherIncomeSourcesTotal)}
                        </span>
                        <button
                          type='button'
                          className='btn btn-primary float-right'
                          onClick={event =>
                            this.addToList(event, "other_source_of_income")
                          }
                        >
                          +
                        </button>
                        <label>Other Source(s) of Income</label>
                        {(() => {
                          const otherIncomeSourcesClone = other_source_of_income.length
                            ? [...other_source_of_income]
                            : [{id: uuid()}];
                          return otherIncomeSourcesClone.map(
                            (source, index) => {
                              return (
                                <OtherIncomeSourcesListItem
                                  data={source}
                                  sources={otherIncomeSourcesClone}
                                  key={source.id}
                                  index={index}
                                  setParentState={this.setStateValues}
                                />
                              );
                            }
                          );
                        })()}
                        {additional_income !== otherIncomeSourcesTotal ? (
                          <div>
                            <input
                              type='text'
                              className='form-control'
                              pattern={yobRegexString}
                              value={"bola"}
                              style={{display: "none"}}
                              onChange={() => {}}
                            />
                            <div className='invalid-feedback'>
                              Other sources of income must sum up to exactly ₦
                              {`${formatCurrencyInput(additional_income)} `}
                              (your additional income from previous entries)
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Outstanding loans (if any)</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='have_existing_obligation_yes'
                            type='radio'
                            name='have_existing_obligation'
                            value='yes'
                            data-state-name='have_existing_obligation'
                            checked={hasExistingObligation}
                            onChange={this.handleOutstandingLoanRadio}
                          />
                          <label htmlFor='have_existing_obligation_yes'>
                            Yes
                          </label>
                        </div>

                        <div className='col-md-6'>
                          <input
                            id='have_existing_obligation_no'
                            type='radio'
                            name='have_existing_obligation'
                            value='no'
                            data-state-name='have_existing_obligation'
                            checked={
                              this.state.have_existing_obligation === "no"
                            }
                            onChange={this.handleOutstandingLoanRadio}
                          />
                          <label htmlFor='have_existing_obligation_no'>
                            No
                          </label>
                        </div>
                      </div>
                      {!DEFAULT_RADIO_VALUES.includes(
                        this.state.have_existing_obligation
                      ) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* {other_obligations ? (
                    <div className='form-group row'>
                      <div className='col-md-6'>
                        <label>Other Obligations</label>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text px-3 bg-white'>
                              ₦
                            </span>
                          </div>
                          <input
                            name='monthly_income'
                            type='text'
                            className='form-control'
                            placeholder='Monthly income'
                            value={formatCurrencyInput(other_obligations)}
                            disabled
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text px-3 bg-theme-button-group'>
                              Monthly
                            </span>
                          </div>
                          <div className='invalid-feedback'>
                            Please enter a valid number (sequence of digits)
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {hasExistingObligation ? (
                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <label>Outstanding Loan Details</label>
                        <button
                          type='button'
                          className='btn btn-primary float-right'
                          onClick={event =>
                            this.addToList(event, "outstanding_loans")
                          }
                        >
                          +
                        </button>
                        {(() => {
                          const outstandingLoans = outstanding_loans.length
                            ? outstanding_loans
                            : [{id: uuid()}];

                          return [...outstandingLoans].map((loan, index) => {
                            return (
                              <OutstandingLoansListItem
                                {...{
                                  loan,
                                  index,
                                  key: loan.id,
                                  loans: outstandingLoans,
                                  setOutstandingLoans: loans =>
                                    this.setState({outstanding_loans: loans})
                                }}
                              />
                            );
                          });
                        })()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='form-group row'>
                    {/* <div className='form-group row'> */}
                    <div className='col-md-12'>
                      <label>Other monthly expenses</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='have_expenses_yes'
                            type='radio'
                            name='have_expenses'
                            value='yes'
                            data-state-name='have_expenses'
                            checked={hasExpenses}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='have_expenses_yes'>Yes</label>
                        </div>

                        <div className='col-md-6'>
                          <input
                            id='have_expenses_no'
                            type='radio'
                            name='have_expenses'
                            value='no'
                            data-state-name='have_expenses'
                            checked={this.state.have_expenses === "no"}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='have_expenses_no'>No</label>
                        </div>
                      </div>
                      {!DEFAULT_RADIO_VALUES.includes(
                        this.state.have_expenses
                      ) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* </div> */}

                    {hasExpenses ? (
                      <div className='col-md-6'>
                        <label>Estimated Monthly Living Expenses</label>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text px-3 bg-white'>
                              ₦
                            </span>
                          </div>
                          <input
                            name='monthly_income'
                            type='text'
                            className='form-control'
                            placeholder=''
                            data-state-name='monthly_expenses'
                            pattern={currencyRegexString}
                            value={this.state.monthly_expenses}
                            onChange={this.handleCurrencyInputChange}
                            required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text px-3 bg-theme-button-group'>
                              Monthly
                            </span>
                          </div>
                          <div className='invalid-feedback'>
                            {currencyFieldErrorMessage}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Do you have assets?</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='have_user_assets_yes'
                            type='radio'
                            name='have_user_assets'
                            value='yes'
                            data-state-name='have_user_assets'
                            checked={hasAssets}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='have_user_assets_yes'>Yes</label>
                        </div>

                        <div className='col-md-6'>
                          <input
                            id='have_user_assets_no'
                            type='radio'
                            name='have_user_assets'
                            value='no'
                            data-state-name='have_user_assets'
                            checked={this.state.have_user_assets === "no"}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='have_user_assets_no'>No</label>
                        </div>
                      </div>
                      {!DEFAULT_RADIO_VALUES.includes(
                        this.state.have_user_assets
                      ) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}

                  {/* {hasAssets ? (
                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <label>
                          Assets/ Investments Owned (Real Estate, Equities,
                          Bonds, Vehicles, etc)
                        </label>
                        <button
                          type='button'
                          className='btn btn-primary float-right'
                          onClick={event =>
                            this.addToList(event, "user_assets")
                          }
                        >
                          +
                        </button>
                        {(() => {
                          const userAssets = user_assets.length
                            ? user_assets
                            : [{id: uuid()}];
                          return [...userAssets].map((asset, index) => {
                            return (
                              <UserAssetsListItem
                                {...{
                                  index,
                                  asset,
                                  key: asset.id,
                                  assets: userAssets,
                                  setAssets: assets =>
                                    this.setState({user_assets: assets})
                                }}
                              />
                            );
                          });
                        })()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Banking Details (Account Information)</label>
                      <button
                        type='button'
                        className='btn btn-primary float-right'
                        onClick={event =>
                          this.addToList(event, "bank_accounts")
                        }
                      >
                        +
                      </button>
                      {(() => {
                        const bankAccounts = bank_accounts.length
                          ? bank_accounts
                          : [{id: uuid()}];

                        return [...bankAccounts].map((account, index, arr) => {
                          return (
                            <BankAccountsListItem
                              {...{
                                banks,
                                index,
                                account,
                                key: account.id,
                                accounts: bankAccounts,
                                setBankAccounts: accounts =>
                                  this.setState({bank_accounts: accounts})
                              }}
                            />
                          );
                        });
                      })()}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <MultiStepFormButtons
              type='Mortgage-Application'
              handleBackClick={this.props.goToPreviousComponent}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default FinancialInfoStepFormOne;
