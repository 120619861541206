import React from 'react';
import * as Icon from 'react-feather';


const PersonalInformationPreviewBox = ({ data, goToComponent }) => {
  const {
    firstname, lastname, middlename, address,
    email, phone,  dob, sex, place_of_birth,
    nationality, state_of_origin,
    highest_education, profession
  } = data;

  return (
    <div
      data-label='Personal Information'
      className='fp-mortgage-landing-preview-card'
    >
      <div className='row'>
        <div className='col-md-12 no-print'>
          <span
            className='fp-mortgage-application-action-btn float-right d-flex'
            onClick={ () => goToComponent(1) }
          >
            <Icon.Edit2
              color='#fff'
              size='18px'
              className='mr-1'
            />
            Edit
          </span>
        </div>
        <div className='col-md-3'>
          <span>Title</span>
          <p>Mr</p>
        </div>
        <div className='col-md-3'>
          <span>First Name</span>
          <p>{ firstname }</p>
        </div>
        <div className='col-md-3'>
          <span>Last Name</span>
          <p>{ lastname }</p>
        </div>
        <div className='col-md-3'>
          <span>Middle Name</span>
          <p>{ middlename }</p>
        </div>
        <div className='col-md-6'>
          <span>Address</span>
          <p>
            { address }
          </p>
        </div>
        <div className='col-md-3'>
          <span>Email Address</span>
          <p>{ email }com</p>
        </div>
        <div className='col-md-3'>
          <span>Phone Number</span>
          <p>{ phone }</p>
        </div>
        <div className='col-md-3'>
          <span>Date of Birth</span>
          <p>{ dob }</p>
        </div>
        <div className='col-md-3'>
          <span>Sex</span>
          <p>{ sex }</p>
        </div>
        <div className='col-md-3'>
          <span>Place of Birth</span>
          <p>{ place_of_birth }</p>
        </div>
        <div className='col-md-3'>
          <span>Nationality</span>
          <p>{ nationality }</p>
        </div>
        <div className='col-md-3'>
          <span>State of Origin</span>
          <p>{ state_of_origin }</p>
        </div>
        <div className='col-md-6'>
          <span>Highest Educational Qualification</span>
          <p>{ highest_education }</p>
        </div>
        <div className='col-md-3'>
          <span>Profession</span>
          <p>{ profession }</p>
        </div>
      </div>
    </div>
  );
}
 
export default PersonalInformationPreviewBox;