import React, {Component} from "react";
import "../Buttons/Buttons.css";

import Medal from "../Resource/medal.png";
import {formatCurrencyInput} from "../../utils/currencyUtils";

const styles = {
  SuccessImage: {
    backgroundImage: `url(${Medal})`
  }
};

class EligibilitySuccess extends Component {
  render() {
    const {
      serverResponse: {monthly_payment, loan_amount},
      goToRoute,
      isNHF,
      setNhfRegNo,
      nhf_reg_number
    } = this.props;

    const monthlyPayment = monthly_payment ? monthly_payment : "";
    const propertyValue = loan_amount ? loan_amount : "";
    return (
      <div className='fp-eligibility-test-application-form-wrapper result'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div
              className='fp-eligibility-test-success-medal'
              style={styles.SuccessImage}
            ></div>
            <h2 className='fp-eligibility-test-success-text'>
              Your are Eligible!
            </h2>
            <div className='fp-affordability-test-payment-summary'>
              <div className='fp-loan-limit'>
                <span>Get up to</span>
                <h4>{formatCurrencyInput(propertyValue)}</h4>
              </div>
              {monthlyPayment ? (
                <div className='fp-monthly-repayment'>
                  <span>Est. monthly repayment</span>
                  <h4>{formatCurrencyInput(monthlyPayment)}</h4>
                </div>
              ) : (
                ""
              )}
            </div>
            <p className='fp-eligibility-test-success-text-subtitle'>
              Congratulations! You are eligible for a mortgage, and are well on
              your way to acquiring your new home. <a href='/'>Terms apply</a>.
              How would you like to proceed?
            </p>
            <div className='fp-eligibility-test-application-button-wrapper'>
              {/* <button type='button' className='fp-send-email-with-results'>
                Send email with results
              </button> */}
              <button
                type='button'
                className='fp-eligibility-apply-for-mortgage'
                onClick={() => {
                  if (isNHF) {
                    const {
                      yob,
                      property_value,
                      down_payment
                    } = this.props.getParentState();
                    const {
                      affordabilityData,
                      mortgageApplicationData,
                      setAffordabilityData,
                      setMortgageApplicationData
                    } = this.props;
                    setNhfRegNo(nhf_reg_number);
                    setAffordabilityData({
                      ...affordabilityData,
                      yob,
                      loan_amount
                    });
                    setMortgageApplicationData({
                      ...mortgageApplicationData,
                      property_value,
                      down_payment
                    });
                  }
                  goToRoute(`${isNHF ? "/nhf" : ""}/mortgage/application`);
                }}
              >
                Apply for mortgage
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EligibilitySuccess;
