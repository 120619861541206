import React from 'react';
import { formatCurrencyInput } from '../../utils/currencyUtils';
import { phoneRegexString, emailRegexString } from '../../utils/regexUtils';


const OverAgesModal = ({ monthly_income, dob }) => {
  return (
    <div
      className='modal fade'
      id='modalContactUS'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-dialog-centered wd-sm-600'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-body fp-affordability-test-contact-us-content-wrapper'>
            <div className='fp-not-too-old'></div>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <form
                    className='fp-login-form-wrapper'
                    noValidate
                  >
                    <div className='form-group row'>
                      <div className='col-md-12'>
                        <label htmlFor='Full Name'>Full Name</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Full Name'
                          required
                        />
                        <div className='invalid-feedback'>
                          Invalid Full Name
                        </div>
                      </div>
                    </div>
                    <div className='form-group row'>
                      <div className='col-md-6'>
                        <label htmlFor='Email Address'>Email Address</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Email Address'
                          pattern={emailRegexString}
                          required
                        />
                        <div className='invalid-feedback'>
                          Invalid Email Address
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='Phone Number'>Phone Number</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Phone Number'
                          pattern={phoneRegexString}
                          required
                        />
                        <div className='invalid-feedback'>
                          Invalid Phone Number
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <button
                          type='submit'
                          className='fp-not-too-old-button'
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='fp-affordability-test-contact-us-content'>
            <a
              href='/'
              role='button'
              className='close fp-login-modal-close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>×</span>
            </a>
            <div className='fp-nhf-affordability-info'>
              <div className='fp-other-info-required border-line-right'>
                <span>Loan Amount</span>
                <h4>N5,000,000</h4>
              </div>
              <div className='fp-other-info-required border-line-right'>
                <span>Net Monthly Earnings</span>
                <h4>N{formatCurrencyInput(monthly_income)}</h4>
              </div>
              <div className='fp-other-info-required'>
                <span>Date Of Birth</span>
                <h4>{dob}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default OverAgesModal;