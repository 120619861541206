import React from 'react';
import * as Icon from "react-feather";

import { formatCurrencyInput } from '../../utils/currencyUtils';
import { formatDate } from '../../utils/dashboardUtils';

const LenderDashboardApplicationListItem = ({ application, goToRoute }) => {
  return (
    <li className='table-row'>
      <div className='col col-2' data-label='Reference'>
        { application.app_ref }
      </div>
      <div className='col col-2' data-label='Customer'>
        {
          `${
            application.firstname ? application.firstname : ''} ${
              application.lastname ? application.lastname : ''}`
        }
      </div>
      <div
        className='col col-2'
        data-label='Loan Amount'
      >
        ₦ { application.loan_amount ? formatCurrencyInput(application.loan_amount) : ''}
      </div>
      <div className='col col-2' data-label='Date'>
        { formatDate(application.created_at, 'short') }
      </div>
      <div className='col col-2' data-label='Status'>
        <span className={`badge${
          application.mortgage_status_name ? ' ' + application.mortgage_status_name.toLowerCase() : ''
        }`}>{ application.mortgage_status_name }</span>
      </div>
      <div className='col col-1' data-label='Actions'>
        <Icon.Eye
          color='#555555'
          size='23'
          onClick={ () => goToRoute(`/lender/dashboard/application/view/${application.slug}`) }
        />
      </div>
    </li>
  );
}
 
export default LenderDashboardApplicationListItem;