import {
  SET_AFFORDABILITY_DATA,
  SET_ERRORS,
  SET_IS_AUTHENTICATED,
  SET_MORTGAGE_APPLICATION_DATA,
  SET_EARNING_DATA,
  IS_LOADING,
  SET_NHF_REG_NUMBER,
  DEFAULT_AFFORDABILITY_DATA,
  DEFAULT_EARNING_DATA,
  DEFAULT_MORTGAGE_APPLICATION_DATA,
  SET_EDIT_APPLICATION_DATA
} from '../../constants';

const initialState = {
  affordabilityData: { ...DEFAULT_AFFORDABILITY_DATA },
  earningData: { ...DEFAULT_EARNING_DATA },
  mortgageApplicationData: { ...DEFAULT_MORTGAGE_APPLICATION_DATA },
  editApplicationData: { app_ref: '' },
  errors: [],
  isAuthenticated: false,
  isLoading: false,
  nhf_reg_number: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AFFORDABILITY_DATA:
      return { ...state, affordabilityData: action.affordabilityData };
    case SET_ERRORS:
      return { ...state, errors: action.errors };
    case SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.isAuthenticated };
    case SET_EARNING_DATA:
      return { ...state, earningData: action.earningData };
    case SET_MORTGAGE_APPLICATION_DATA:
      return { ...state, mortgageApplicationData: action.mortgageApplicationData };
    case IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_NHF_REG_NUMBER:
      return { ...state, nhf_reg_number: action.nhf_reg_number };
    case SET_EDIT_APPLICATION_DATA:
      return { ...state, editApplicationData: action.editApplicationData };
    default:
      return state;
  }
};