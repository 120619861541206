import React from "react";

import * as Icon from "react-feather";

// import fbnMortgageIcon from "../Resource/fbnMortgageIcon.png";
import { toTitleCase } from "../../utils/dashboardUtils";
import BrowserStorage from "../../utils/browserStorageUtils";

// const styles = {
//   LenderImage: {
//     backgroundImage: /*`url(${data.lender_logo})`*/ `url(${fbnMortgageIcon})`
//   }
// };

const BioDataInfo = () => {
  const {
    fullName, email, phonenumber, dob, employment_status, address
  } = BrowserStorage.getUserData();
  return (
    <div className='fp-mortgage-application-lender-info'>
      <div className='fp-mortgage-lender-company-info'>
        <h2>Personal Information</h2>
      </div>
      <div className='fp-mortgage-lender-company-info-wrap'>
        <Icon.User
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-email'>
          <span>Name</span>
          <h4>
            { fullName }
          </h4>
        </div>
      </div>

      <div className='fp-mortgage-lender-company-info-wrap'>
        <Icon.MapPin
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-phone'>
          <span>Address</span>
          <h4>
            { address }
          </h4>
        </div>
      </div>

      <div className='fp-mortgage-lender-company-info-wrap'>
        <Icon.Phone
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-address'>
          <span>Phone Number</span>
          <h4>
            { phonenumber }
          </h4>
        </div>
      </div>

      <div className='fp-mortgage-lender-company-info-wrap'>
        <Icon.Mail
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-address'>
          <span>Email Address</span>
          <h4>
            { email }
          </h4>
        </div>
      </div>

      <div className='fp-mortgage-lender-company-info-wrap fp-border-line'>
        <Icon.Calendar
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-address'>
          <span>Date of Birth</span>
          <h4>
            { dob }
          </h4>
        </div>
      </div>

      <div className='fp-mortgage-lender-company-info mt-4'>
        <h2>Employment Information</h2>
      </div>

      <div className='fp-mortgage-lender-company-info-wrap'>
        <Icon.Briefcase
          className='fp-mortgage-lender-company-info-icon'
          color='#555555'
        />
        <div className='fp-mortgage-lender-company-address'>
          <span>Status </span>
          <h4>
            { toTitleCase(employment_status) }
          </h4>
        </div>
      </div>
    </div>
  );
};

export default BioDataInfo;
