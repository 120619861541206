import Axios from "axios";
import setAffordabilityData from "../actions/setAffordabilityData";
import { formatDecimals } from "../../utils/currencyUtils";
import setEarningData from "../actions/setEarningData";
import http from "../../config/axios.config";
import setLoading from "../actions/setLoading";
import fetchProperties from "../../utils/fetchProperties";

/**
 * Fetches data for affordability test from the server
 * @param {object} data
 * @param {function} doAfterSuccess
 */
export default (data, doAfterSuccess) => async (dispatch, getState) => {
  try {
    const { location, other_obligations, tenure, rate } = data;
    delete data.location;
    const { data: result } = await http.post('/mortgage/affordabilitytest', data);
    dispatch(setLoading(true));
    const {
      data: { loan_amount: loanAmount, monthly_payment: monthlyPayment }
    } = result;
    const loan_amount = formatDecimals(loanAmount);
    const monthly_payment = formatDecimals(monthlyPayment);
    const { data: { data: { properties, found } } } = await fetchProperties(loan_amount, location);
    console.log(properties)
    const { monthly_income, additional_income, dob } = data;
    dispatch(setAffordabilityData({
      loan_amount, monthly_payment, dob, properties: null, other_obligations,
      tenure, maxLoanableAmount: loan_amount, isNhf: false, rate
    }));
    const timeout = setTimeout(() => {
      const { affordabilityData } = getState();
      dispatch(setAffordabilityData({ ...affordabilityData, properties: { properties, found } }));
      clearTimeout(timeout);
    }, 1500);
    
    dispatch(setEarningData({ monthly_income, additional_income }));
    doAfterSuccess();
  } catch (error) {} finally { dispatch(setLoading(false)); }
};
