import React, {useState, useEffect} from "react";

import {Link} from "react-router-dom";
import * as Icon from "react-feather";
import VerticalApplicationListItem from "./VerticalApplicationListItem";


const VerticalApplicationList = ({
  applications,
  goToRoute,
  query,
  setLoading,
  setErrors,
  setEditApplicationData
}) => {
  const firstApplication = applications[0];
  const [activeTab, setActiveTab] = useState('mortgage');

  useEffect(() => {
    if (firstApplication) setActiveTab(firstApplication.type);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications.length]);
  
  return (
    <div className='fp-mortgage-applications-all'>
      <div className='fp-mortgage-applications-multi-tab-actions-buttons'>
        <div className='fp-mortgage-applications-multi-tab'>
          <span
            className={`${
              activeTab === "mortgage" ? "fp-active-menu-tab " : ""
            }tabs`}
            onClick={() => setActiveTab("mortgage")}
          >
            Ordinary Mortgage
          </span>
          <span
            className={`${
              activeTab === "nhf" ? "fp-active-menu-tab " : ""
            }tabs`}
            onClick={() => setActiveTab("nhf")}
          >
            NHF Loan
          </span>
        </div>
        <div className='fp-mortgage-applications-actions-buttons'>
          <Link className='fp-dashboard-chat-message-actions'>
            <Icon.MessageCircle color='#0073f0' /> Send Message
          </Link>
          <a
            className='fp-dashboard-more-actions text-white'
            href='/mortgage/application-type'
            target='_blank'
          >
            Apply for mortgage
          </a>
        </div>
      </div>

      <div className='fp-mortgage-applications-list-wrapper'>
        <ul className='responsive-table p-0'>
          <li className='table-header'>
            <div className='col col-2'>Reference</div>
            <div className='col col-3'>Application date</div>
            <div className='col col-3'>Loan Value</div>
            <div className='col col-2'>Status</div>
            <div className='col col-2'></div>
          </li>
          {applications && applications.length
            ? applications
                .filter(application => {
                  const queryValue = query.split("=")[1];
                  return !queryValue
                    ? true
                    : application.mortgage_status_name.toLowerCase() ===
                        queryValue;
                })
                .filter(application => application.type === activeTab)
                .map(application => (
                  <VerticalApplicationListItem
                    key={application.app_ref}
                    {...{
                      application,
                      goToRoute,
                      setLoading,
                      setErrors,
                      setEditApplicationData
                    }}
                  />
                ))
            : ""}
        </ul>
      </div>
    </div>
  );
};

export default VerticalApplicationList;
