import React, { Component } from "react";
import Nhanla from "../Resource/assistant-image.png";

const styles = {
  NhanlaImage: {
    backgroundImage: `url(${Nhanla})`
  }
};
class NhalaLandingPage extends Component {
  render() {
    return (
      <div className='col-md-8 offset-md-2'>
        <div className='fp-mortgage-landing-page-with-nhanla'>
          <div className='row'>
            <div className='col-md-8 offset-md-2'>
              <div
                className='fp-mortgage-application-nhanla'
                style={styles.NhanlaImage}
              ></div>
              <h2>
                Hi there! I’m Fala (Finance+ Automated Loan Assistant), here to
                help you find the best home loan. Ready to begin?
              </h2>
              <p>
                Before we proceed, there are a few things I would like you to
                know
              </p>
              <ul className='fp-mortgage-application-criteria'>
                <li>
                  <span>
                    Ensure all information provided are accurate to avoid loan
                    refusal.
                  </span>
                </li>
                <li>
                  <span>
                    Please ensure you have all{" "}
                    <a href='/'>required documents</a> for upload on hand.
                  </span>
                </li>
                <li>
                  <span>
                    All applications are subject to verification.
                    <a href='/'> Terms</a> and <a href='/'>Conditions</a> apply.
                  </span>
                </li>
              </ul>
              <div className='fp-lets-begin-nhanla-button-wrapper'>
                <button
                  type='button'
                  className='fp-lets-begin-nhanla-button'
                  onClick={this.props.goToNextComponent}
                  disabled={this.props.isLoading}
                >
                  {this.props.app_ref ? 'Continue my application' : 'I’m ready, let’s begin'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NhalaLandingPage;
