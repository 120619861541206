import React, { Component } from "react";
import { connect } from "react-redux";

import { Link as span } from "react-router-dom";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import DashboardBreadCrumb from "../DashboardBreadCrumb";

import CircularLoader from "../CircularLoader";

import "./LenderUserSingleApplication.css";

import "../Buttons/Buttons.css";
import * as Icon from "react-feather";

import { clearErrorStore } from "../../utils/errorUtils";
import { toTitleCase, formatDate } from "../../utils/dashboardUtils";
import { formatCurrencyInput } from "../../utils/currencyUtils";
import ApplicationFileIcon from "../ApplicationFileIcon";
import ErrorToaster from "../ErrorToaster";
import http from "../../config/axios.config";

class LenderUserSingleApplication extends Component {
  state = {
    application: null,
    files: []
  };

  componentDidMount() {
    this.fetchApplication();
  };

  componentWillUnmount() {
    clearErrorStore();
  }

  approveApplication = async () => {
    const { match: { params: { slug } } } = this.props;
    try {
      const {
        data: { data: application }
      } = await http.get(`/lender/approved-mortgage-application/${slug}`);
      this.setState({ application });      
    } catch (error) {}
  };

  declineApplication = async () => {
    const { match: { params: { slug } } } = this.props;
    try {
      const {
        data: { data: application }
      } = await http.get(`/lender/declined-mortgage-application/${slug}`);
      this.setState({ application });      
    } catch (error) {}
  };

  fetchApplication = async () => {
    const { match: { params: { slug } } } = this.props;
    try {
      const {
        data: { data: { mortgage: application, user_documents } }
      } = await http.get(`/lender/application-details/${slug}`);
      this.setState({ application, files: user_documents });      
    } catch (error) {}
  };

  render() {
    const { application, files } = this.state;
    return (
      <div className='container-fluid px-0'>
        <ErrorToaster />
        <CircularLoader isLoading={this.props.isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='Lender' />
        <div className='fp-mortgage-content-wrapper'>
          <DashboardBreadCrumb
            type='Single Mortgage Application'
            title='Application Details'
          />
          <div className='container-fluid'>
            <div className='fp-dashboard-section'>
              {application ? (
                <div className='row'>
                  <div className='col-md-4 fp-mortgage-application-lender-info-wrapper'>
                    <div className='fp-mortgage-application-lender-info'>
                      <div className='fp-mortgage-lender-company-info'>
                        <h2>Client Info</h2>
                        <Icon.Eye color='#828282' size='25' />
                      </div>
                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.User
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Name</span>
                          <h4>
                            {`${toTitleCase(
                              application.firstname
                            )} ${toTitleCase(application.lastname)}`}
                          </h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.Mail
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Email Address</span>
                          <h4>{application.email}</h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.Phone
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-phone'>
                          <span>Phone Number</span>
                          <h4>{application.phone}</h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.MapPin
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-address'>
                          <span>Client Address</span>
                          <h4>{application.address}</h4>
                        </div>
                      </div>
                    </div>
                    <div className='fp-mortgage-application-loan-value'>
                      <h2>{formatCurrencyInput(application.loan_amount)}</h2>
                      <p>Total loan value</p>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className='fp-mortgage-application-loan-info-display'>
                      <div className='fp-mortgage-application-reference-no-loan-status'>
                        <div className='fp-mortgage-application-reference-no'>
                          <span>Reference</span>
                          <h4>{application.app_ref}</h4>
                        </div>
                        <div className='fp-mortgage-application-date'>
                          <span>Application Submitted</span>
                          <h4>
                            {formatDate(
                              application.created_at,
                              "medium",
                              "short"
                            )}
                          </h4>
                        </div>
                        <div
                          className={`fp-mortgage-application-status ${application.mortgage_status_name.toLowerCase()}`}
                        >
                          <span>Loan Status</span>
                          <h4>{application.mortgage_status_name}</h4>
                        </div>
                      </div>
                      <div className='fp-mortgage-loan-amount-repayment-interest-rate'>
                        <div className='fp-mortgage-loan-amount-wrap'>
                          <h2>
                            {formatCurrencyInput(application.loan_amount)}
                          </h2>
                          <span>Loan Amount</span>
                        </div>
                        <div className='fp-mortgage-repayment'>
                          <h2>{application.loan_tenure} years</h2>
                          <span>Repayment period</span>
                        </div>
                        <div className='fp-mortgage-loan-interest-rate'>
                          <h2>
                            {application.lender
                              ? application.lender.lender_rate
                              : ""}
                          </h2>
                          <span>Interest rate</span>
                        </div>
                      </div>
                      <div className='fp-mortgage-loan-more-information'>
                        <div className='fp-mortgage-loan-more-information-right'>
                          <div className='fp-mortgage-loan-type-application-wrap'>
                            <div className='fp-mortgage-loan-type'>
                              <span>Loan Type</span>
                              <h4>{application.type}</h4>
                            </div>
                            <div className='fp-mortgage-loan-application-type'>
                              <p> Single Application</p>
                            </div>
                          </div>
                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Property Address</span>
                            <h4>{application.property_address}</h4>
                          </div>
                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Property Type</span>
                            <h4>Terrace Duplex | 3 Bedroom</h4>
                          </div>

                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Down Payment</span>
                            <h4>
                              ₦ {formatCurrencyInput(application.down_payment)}
                            </h4>
                          </div>

                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Comments</span>
                            <h4 className='text-break'>
                              All clear here! I see no reason why the loan
                              shouldn’t be approved. Papers check out, borrower
                              info, property info e.t.c. We recommend the loan.
                              Please proceed.
                            </h4>
                          </div>
                        </div>
                        <div className='fp-mortgage-loan-more-information-left'>
                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>Borrower Eligibility</h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>
                              All Required Documents
                            </h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>Borrower Verification</h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#bbbbbb' size='35' />
                            <h4 className='text-wrap'>Property Verification</h4>
                          </div>
                        </div>
                      </div>
                      <div className='fp-mortgage-loan-documents-uploads-actions'>
                        <div className='fp-mortgage-loan-documents-uploads-actions-right'>
                          {files
                            .filter(file => file.filename)
                            .map(file => {
                              return (
                                <ApplicationFileIcon
                                  data={file}
                                  key={file.id}
                                  appRef={application.app_ref}
                                />
                              );
                            })}
                        </div>
                        <div className='fp-mortgage-loan-documents-uploads-actions-left'>
                          <div className='dropdown'>
                            <span
                              className='fp-dashboard-more-actions'
                              id='dropdownMenu'
                              data-toggle='dropdown'
                            >
                              Action <Icon.MoreHorizontal color='#fff' />
                            </span>
                            <div
                              className='dropdown-menu fp-dashboard-bg-theme'
                              aria-labelledby='dropdownMenu'
                            >
                              <span
                                className='dropdown-item'
                                onClick={e => console.log("You saw it?!!!")}
                              >
                                <Icon.File
                                  color='#555555'
                                  size='20px'
                                  className='mr-2'
                                />
                                Request Documents
                              </span>
                              <div className='dropdown-divider'></div>
                              <span
                                className='dropdown-item'
                                onClick={this.approveApplication}
                              >
                                <Icon.CheckCircle
                                  color='#555555'
                                  size='20px'
                                  className='mr-2'
                                />
                                Approve Application
                              </span>
                              <div className='dropdown-divider'></div>
                              <span
                                className='dropdown-item'
                                onClick={this.declineApplication}
                              >
                                <Icon.Trash2
                                  color='#555555'
                                  size='20px'
                                  className='mr-2'
                                />
                                Reject Application
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ isLoading }, ownProps) => {
  return { isLoading, ...ownProps };
};

export default connect(mapStateToProps)(LenderUserSingleApplication);
