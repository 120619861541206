import React from 'react';
import * as Icon from 'react-feather';
import PreviewChildrenTableRow from './PreviewChildrenTableRow';
import { toTitleCase } from '../../utils/dashboardUtils';
import { formatCurrencyInput } from '../../utils/currencyUtils';


const OtherPersonalInfoPreviewBox = ({ data, goToComponent }) => {
  const {
    current_apartment_status, annual_rent_value, marital_status,
    spouse_name, spouse_employer, spouse_annual_income, spouse_profession,
    spouse_address, spouse_work_experience, children, next_of_kin_address,
    next_of_kin_name, next_of_kin_age, next_of_kin_relationship
  } = data;

  return (
    <div
      data-label='Other Personal Information'
      className='fp-mortgage-landing-preview-card mt40'
    >
      <div className='row'>
        <div className='col-md-12 no-print'>
          <span
            className='fp-mortgage-application-action-btn float-right d-flex'
            onClick={ () => goToComponent(3) }
          >
            <Icon.Edit2
              color='#fff'
              size='18px'
              className='mr-1'
            />
            Edit
          </span>
        </div>
        <div className='col-md-3'>
          <span>Current home type</span>
          <p>{ toTitleCase(current_apartment_status) }</p>
        </div>
        <div className='col-md-3'>
          <span>Others</span>
          <p>-</p>
        </div>
        <div className='col-md-3'>
          <span>Annual Rent</span>
          <p>
            {
              annual_rent_value ? `₦ ${formatCurrencyInput(annual_rent_value)}` : 'N/A'
            }
          </p>
        </div>
        <div className='col-md-3'>
          <span>Marital Status</span>
          <p>{ toTitleCase(marital_status) }</p>
        </div>
        <div className='col-md-3'>
          <span>Spouse’s Name</span>
          <p>{ spouse_name ? spouse_name : 'N/A' }</p>
        </div>
        <div className='col-md-3'>
          <span>Spouse’s Address</span>
          <p>{ spouse_address ? spouse_address : 'N/A' }</p>
        </div>
        <div className='col-md-3'>
          <span>Spouse’s Employer</span>
          <p>{ spouse_employer ? spouse_employer : 'N/A' }</p>
        </div>
        <div className='col-md-3'>
          <span>No of Years Employed</span>
          <p>{ spouse_work_experience ? spouse_work_experience : 'N/A' }</p>
        </div>
        <div className='col-md-3'>
          <span>Profession of Spouse</span>
          <p>{ spouse_profession ? spouse_profession : 'N/A' }</p>
        </div>
        <div className='col-md-3'>
          <span>Spouse's Annual Income</span>
          <p>{ spouse_annual_income ? `₦ ${formatCurrencyInput(spouse_annual_income)}` : 'N/A' }</p>
        </div>
        <div className='col-md-12'>
          <span>Children/Other Dependents:</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Relationship</th>
              </tr>
            </thead>
            <tbody>
              {
                children.length ? children.map((child) => {
                  return (
                    <PreviewChildrenTableRow
                      child={ child } key={ child.id } />
                  );
                }) : (
                  <PreviewChildrenTableRow
                    child={{ name: '-', age: '-', relationship: '-' }} />
                )
              }
            </tbody>
          </table>
        </div>
        <div className='col-md-4'>
          <span>Next-of-Kin’s Name</span>
          <p>{ next_of_kin_name }</p>
        </div>
        <div className='col-md-4'>
          <span>Next-of-Kin’s Relationship</span>
          <p>{ next_of_kin_relationship }</p>
        </div>
        <div className='col-md-4'>
          <span>Next-of-Kin’s Age</span>
          <p>{ next_of_kin_age }</p>
        </div>
        <div className='col-md-12'>
          <span>Next-of-Kin Address</span>
          <p>{ next_of_kin_address }</p>
        </div>
      </div>
    </div>
  );
}
 
export default OtherPersonalInfoPreviewBox;