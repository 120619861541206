import React, {Component} from "react";
import * as Icon from "react-feather";

import MultiStepFormButtons from "../MultiStepFormButtons";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import {DEFAULT_RADIO_VALUES} from "../../constants";
import {clearCommas} from "../../utils/currencyUtils";
import {requiredFieldErrorMessage} from "../../utils/validationMessageUtils";
import {yobRegexString} from "../../utils/regexUtils";
import { getYearFromDOB } from "../../utils/formatDate";
import http from "../../config/axios.config";

class LandingStepFormOne extends Component {
  state = {
    have_six_month_contribution: this.props.have_six_month_contribution,
    nhf_is_active: this.props.nhf_is_active,
    property_have_title: this.props.property_have_title,
    dob: this.props.dob,
    have_tax_clearance: this.props.have_tax_clearance
  };

  componentDidMount() { this.props.setErrors([]); }

  handleBackClick = () => {
    this.props.setParentState({
      activeComponentIndex: this.props.componentIndex - 1
    });
  };

  componentWillUnmount() {
    this.props.setParentState({...this.state});
  }

  handleChange = event => handleChange(event, this);

  /**
   * Handles form's submit event
   * @param {DOMEvent} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    await this.props.setParentState({...this.state});
    const data = this.props.getParentState();
    delete data.property_value;
    delete data.down_payment;
    data.loan_amount = clearCommas(data.loan_amount);
    data.yob = getYearFromDOB(data.dob);
    try {
      await http.post('/mortgage/nhf-eligibilitytest', data);
      this.props.setParentState({ activeComponentIndex: this.props.componentIndex + 1 });
    } catch (error) {}
  };

  render() {
    const {
      have_six_month_contribution,
      nhf_is_active,
      property_have_title,
      dob,
      have_tax_clearance
    } = this.state;

    return (
      <div className='fp-eligibility-test-application-form-wrapper other'>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='row'>
            <div className='col-md-9 offset-md-1 mt-2'>
              <div className='form-group row'>
                <div className='col-md-12 mt-1'>
                  <span className='fp-form-numbering'>Q2/7</span>
                  <label>Have you contributed for 6 months or more ?</label>
                  <div className='fp-home-type-checkbox'>
                    <div className='col-md-4'>
                      <input
                        id='Yes'
                        type='radio'
                        name='have_six_month_contribution'
                        value='yes'
                        data-state-name='have_six_month_contribution'
                        checked={have_six_month_contribution === "yes"}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor='Yes'>Yes</label>
                      <div className='invalid-feedback'>
                        Please choose a value
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <input
                        id='No'
                        type='radio'
                        name='have_six_month_contribution'
                        value='no'
                        data-state-name='have_six_month_contribution'
                        checked={have_six_month_contribution === "no"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor='No'>No</label>
                    </div>
                  </div>
                  {!DEFAULT_RADIO_VALUES.includes(
                    have_six_month_contribution
                  ) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-12 mt-1'>
                  <span className='fp-form-numbering'>Q3/7</span>
                  <label>Is your NHF account Currently Active ?</label>
                  <div className='fp-home-type-checkbox'>
                    <div className='col-md-4'>
                      <input
                        id='activeStatus_Yes'
                        type='radio'
                        name='accountStatus'
                        value='yes'
                        data-state-name='nhf_is_active'
                        checked={nhf_is_active === "yes"}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor='activeStatus_Yes'>Yes</label>
                      <div className='invalid-feedback'>
                        Please choose a value
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <input
                        id='activeStatus_No'
                        type='radio'
                        name='accountStatus'
                        value='no'
                        data-state-name='nhf_is_active'
                        checked={nhf_is_active === "no"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor='activeStatus_No'>No</label>
                    </div>
                  </div>
                  {!DEFAULT_RADIO_VALUES.includes(nhf_is_active) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-12 mt-1'>
                  <span className='fp-form-numbering'>Q4/7</span>
                  <label>Availability of good title ?</label>
                  <div className='fp-home-type-checkbox'>
                    <div className='col-md-4'>
                      <input
                        id='goodTitle_Yes'
                        type='radio'
                        name='goodTitle'
                        value='yes'
                        data-state-name='property_have_title'
                        checked={property_have_title === "yes"}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor='goodTitle_Yes'>Yes</label>
                      <div className='invalid-feedback'>
                        Please choose a value
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <input
                        id='goodTitle_No'
                        type='radio'
                        name='goodTitle'
                        value='no'
                        data-state-name='property_have_title'
                        checked={property_have_title === "no"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor='goodTitle_No'>No</label>
                    </div>
                  </div>
                  {!DEFAULT_RADIO_VALUES.includes(property_have_title) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className='form-group row '>
                <div className='col-md-12 mt-1'>
                  <span className='fp-form-numbering'>Q5/7</span>
                  <label>Date of Birth</label>
                  <div className='input-group'>
                    <input
                      name='dob'
                      type='text'
                      className='form-control'
                      value={dob}
                      placeholder='YYYY'
                      data-state-name='dob'
                      onChange={this.handleChange}
                      pattern='^\d{4}$'
                      disabled
                      required
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text bg-theme-button-group'>
                        <Icon.Calendar color='#495057' />
                      </span>
                    </div>
                    <div className='invalid-feedback'>
                      Please enter a valid date
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-12 mt-1'>
                  <span className='fp-form-numbering'>Q6/7</span>
                  <label>Do you have a Tax Clearance Certificate?</label>
                  <div className='fp-home-type-checkbox'>
                    <div className='col-md-4'>
                      <input
                        id='taxCertificate_Yes'
                        type='radio'
                        name='taxCertificate'
                        value='yes'
                        data-state-name='have_tax_clearance'
                        checked={have_tax_clearance === "yes"}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor='taxCertificate_Yes'>Yes</label>
                      <div className='invalid-feedback'>
                        Please choose a value
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <input
                        id='taxCertificate_NO'
                        type='radio'
                        name='taxCertificate'
                        value='no'
                        data-state-name='have_tax_clearance'
                        checked={have_tax_clearance === "no"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor='taxCertificate_NO'>No</label>
                    </div>
                  </div>
                  {!DEFAULT_RADIO_VALUES.includes(have_tax_clearance) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <MultiStepFormButtons
            type='Eligibility-Test'
            handleBackClick={this.handleBackClick}
          />
        </form>
      </div>
    );
  }
}

export default LandingStepFormOne;
