import React from 'react';


const PreviewChildrenTableRow = ({ child }) => {
  const { name, age, relationship } = child;
  return (
    <tr>
      <td>{ name }</td>
      <td>{ age }</td>
      <td>{ relationship }</td>
    </tr>
  );
}
 
export default PreviewChildrenTableRow;