import React, {Component} from "react";
import {handleCurrencyInputChange} from "../../utils/eligibilityFormUtils";
import {
  clearCommas,
  formatCurrencyInput,
  formatDecimals
} from "../../utils/currencyUtils";
import {
  MIN_EQUITY_PERCENTAGE,
  SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE
} from "../../constants";
import {currencyRegexString, yobRegexString} from "../../utils/regexUtils";
import {
  currencyFieldErrorMessage,
  requiredFieldErrorMessage
} from "../../utils/validationMessageUtils";
import { validateForm } from "../../utils/authFormUtils";

const radioValues = ["employed", "selfemployed"];

class LandingStepForm extends Component {
  state = {
    maxLoanableAmount: this.props.maxLoanAmount,
    property_value: formatCurrencyInput(this.props.property_value),
    down_payment: formatCurrencyInput(this.props.down_payment),
    loan_amount: formatCurrencyInput(this.props.loan_amount),
    employment_type: this.props.employment_type,
    equity_percentage:
      this.props.employment_type === "selfemployed"
        ? SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE
        : MIN_EQUITY_PERCENTAGE,
    tooLittleEquity: false,
    tooMuchEquity: false,
    minEquity: "",
    hasTooMuchLoanAmount: false,
    percentageEquity: ""
  };

  componentDidMount() {
    let {
      property_value,
      maxLoanableAmount,
      down_payment,
      equity_percentage
    } = this.state;
    if (property_value && maxLoanableAmount) {
      [down_payment, property_value, maxLoanableAmount] = [
        down_payment,
        property_value,
        maxLoanableAmount
      ].map(value => formatDecimals(clearCommas(value)));

      const propertyAndMaxLoanableDifference =
        property_value - maxLoanableAmount;
      const minEquity = equity_percentage * property_value;
      const propertySatisfyMaxLoanable =
        propertyAndMaxLoanableDifference >= minEquity;
      down_payment = propertySatisfyMaxLoanable
        ? propertyAndMaxLoanableDifference
        : down_payment > minEquity
        ? down_payment
        : minEquity;
      let loan_amount = formatDecimals(property_value - down_payment);
      [down_payment, loan_amount] = [down_payment, loan_amount].map(value =>
        formatCurrencyInput(formatDecimals(value))
      );

      const data = {};
      if (down_payment) data.down_payment = down_payment;
      if (loan_amount) data.loan_amount = loan_amount;
      if (Object.keys(data).length) this.setState(data);
    }
  }

  updateEquity = () => {
    let {property_value, down_payment, equity_percentage} = this.state;
    [property_value, down_payment] = [property_value, down_payment].map(value =>
      parseFloat(clearCommas(value))
    );
    const loan_amount = property_value - down_payment;
    let minEquity = equity_percentage * property_value;
    const tooLittleEquity = parseFloat(clearCommas(down_payment)) < minEquity;
    minEquity = formatDecimals(minEquity);
    if (loan_amount) {
      let {maxLoanableAmount} = this.state;
      maxLoanableAmount = parseFloat(maxLoanableAmount);
      const data = {};
      if (
        (!maxLoanableAmount || loan_amount <= maxLoanableAmount) &&
        loan_amount <= property_value - minEquity
      ) {
        data.loan_amount = formatCurrencyInput(loan_amount);
      }

      this.setState({...data, minEquity, equity_percentage, tooLittleEquity});
    }
  };

  handleChange = async event => {
    const {
      target: {
        value,
        dataset: {stateName}
      }
    } = event;
    await this.setState({
      [stateName]: value,
      equity_percentage:
        stateName === "employment_type" && value === "selfemployed"
          ? SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE
          : MIN_EQUITY_PERCENTAGE
    });
    this.updateEquity();
  };

  handleEquityPercentageChange = async event => {
    const {
      target: {value}
    } = event;
    await this.setState({percentageEquity: value});
    const {property_value} = this.state;
    let equity = (parseFloat(value) * clearCommas(property_value)) / 100;
    equity = Math.round(equity);
    this.handleCurrencyInputChange({
      target: {value: equity, dataset: {stateName: "down_payment"}}
    });
  };

  /**
   * Handles change event on the input field
   * @param {DOMEvent} event
   */
  handleCurrencyInputChange = async event => {
    await handleCurrencyInputChange(event, this);
    this.updateEquity();
  };

  handleBlur = () => {
    let {
      property_value,
      maxLoanableAmount,
      down_payment,
      equity_percentage
    } = this.state;
    [property_value, maxLoanableAmount, down_payment] = [
      property_value,
      maxLoanableAmount,
      down_payment
    ].map(value => formatDecimals(clearCommas(value)));

    const propertyAndMaxLoanableDifference = property_value - maxLoanableAmount;
    const minEquity = equity_percentage * property_value;
    const propertySatisfyMaxLoanable =
      propertyAndMaxLoanableDifference >= minEquity;
    down_payment = propertySatisfyMaxLoanable
      ? propertyAndMaxLoanableDifference
      : minEquity;
    let loan_amount = formatDecimals(property_value - down_payment);
    [down_payment, loan_amount] = [down_payment, loan_amount].map(value =>
      formatCurrencyInput(formatDecimals(value))
    );

    const data = {};

    if (down_payment) data.down_payment = down_payment;
    if (loan_amount) data.loan_amount = loan_amount;
    if (Object.keys(data).length) this.setState({down_payment, loan_amount});
  };

  /**
   * Handles form's submit event
   * @param {DOMEvent} event
   */
  handleSubmit = event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    let {
      minEquity,
      loan_amount,
      down_payment,
      property_value,
      maxLoanableAmount
    } = this.state;
    loan_amount = parseFloat(clearCommas(loan_amount));
    property_value = parseFloat(clearCommas(property_value));
    down_payment = parseFloat(clearCommas(down_payment));

    if (parseFloat(maxLoanableAmount) < loan_amount)
      return this.setState({hasTooMuchLoanAmount: true});
    else this.setState({hasTooMuchLoanAmount: false});
    if (down_payment + loan_amount !== property_value) {
      minEquity = formatDecimals(minEquity);
      this.setState({minEquity, tooLittleEquity: true});
      const loanAmountError = event.target.querySelector(".invalid-msg.hide");
      return loanAmountError ? loanAmountError.classList.remove("hide") : "";
    } else this.setState({tooLittleEquity: false});
    if (down_payment > property_value)
      return this.setState({tooMuchEquity: true});
    else this.setState({tooMuchEquity: false});

    // Go to the next form
    this.props.setParentState({
      activeComponentIndex: this.props.componentIndex + 1
    });
  };

  componentWillUnmount() {
    const {
      property_value,
      down_payment,
      loan_amount,
      employment_type,
      equity_percentage
    } = this.state;
    const data = {
      property_value: property_value ? clearCommas(property_value) : "",
      down_payment: down_payment ? clearCommas(down_payment) : "",
      loan_amount: loan_amount ? clearCommas(loan_amount) : "",
      employment_type,
      equity_percentage
    };
    this.props.setParentState(data);
  }

  render() {
    const {
      maxLoanableAmount,
      // tooLittleEquity,
      equity_percentage,
      tooMuchEquity,
      loan_amount,
      down_payment,
      property_value
    } = this.state;
    const tooLittleLoanAmount =
      maxLoanableAmount &&
      formatDecimals(clearCommas(property_value)) -
        formatDecimals(clearCommas(down_payment)) >
        parseFloat(maxLoanableAmount);
    const isEmployed = this.state.employment_type === "employed";

    return (
      <div className='fp-eligibility-test-application-form-wrapper landing'>
        <div className='row'>
          <div className='col-md-8 offset-md-2 mt-2'>
            <h2 className='fp-eligibility-test-application-form-title'>
              Check Eligibility &amp; Get Pre-qualified for a Mortgage
            </h2>
            <p className='fp-eligibility-test-application-form-subtitle'>
              You are one step closer to getting finance for your new home. Our
              eligibility test ensures you meet all the set criteria, needed to
              apply for a home loan. Enter your desired property value below to
              begin.
            </p>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className='form-group row'>
                <div className='col-md-12'>
                  <label>Employment Status</label>
                  <div className='row fp-radio-input-wrapper'>
                    <div className='col-md-6'>
                      <input
                        type='radio'
                        id='employed'
                        name='employmentStatus'
                        value='employed'
                        data-state-name='employment_type'
                        checked={isEmployed}
                        onChange={this.handleChange}
                      />
                      <label htmlFor='employed' className='fp-radio-wrapper'>
                        <h3>Employed</h3>
                      </label>
                    </div>

                    <div className='col-md-6'>
                      <input
                        type='radio'
                        id='selfemployed'
                        name='employmentStatus'
                        value='selfemployed'
                        data-state-name='employment_type'
                        checked={this.state.employment_type === "selfemployed"}
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor='selfemployed'
                        className='fp-radio-wrapper'
                      >
                        <h3>Self Employed</h3>
                      </label>
                    </div>
                  </div>
                  {!radioValues.includes(this.state.employment_type) ? (
                    <div>
                      <input
                        type='text'
                        className='form-control'
                        pattern={yobRegexString}
                        value={"bola"}
                        style={{display: "none"}}
                        onChange={() => {}}
                      />
                      <div className='invalid-feedback'>
                        {requiredFieldErrorMessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className='col-md-12'>
                  <label>Property Value?</label>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>₦</span>
                    </div>
                    <input
                      type='text'
                      className='form-control'
                      pattern={currencyRegexString}
                      maxLength='19'
                      value={this.state.property_value}
                      data-state-name='property_value'
                      onChange={this.handleCurrencyInputChange}
                      onBlur={this.handleBlur}
                      required
                    />
                    <div className='invalid-feedback'>
                      {currencyFieldErrorMessage}
                    </div>
                  </div>
                </div>

                <div className='col-md-8 mt-2'>
                  <label>Equity Contribution</label>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>₦</span>
                    </div>
                    <input
                      type='text'
                      className='form-control'
                      pattern={currencyRegexString}
                      maxLength='19'
                      value={this.state.down_payment}
                      data-state-name='down_payment'
                      onChange={this.handleCurrencyInputChange}
                      required
                    />
                    <div className='invalid-feedback'>
                      {currencyFieldErrorMessage}
                    </div>
                    {(() => {
                      const equityLessThanRequiredPercentage =
                        parseFloat(clearCommas(down_payment)) /
                          parseFloat(clearCommas(property_value)) <
                        equity_percentage;
                      return equityLessThanRequiredPercentage ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            Equity must be at least {equity_percentage * 100}%
                            of property value
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })()}

                    {tooLittleLoanAmount ? (
                      <div className={`invalid-msg hide`}>
                        Equity cannot be less than ₦
                        {formatCurrencyInput(
                          formatDecimals(
                            parseFloat(clearCommas(property_value)) -
                              parseFloat(maxLoanableAmount)
                          )
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {tooMuchEquity ? (
                      <div className='invalid-msg'>
                        Equity cannot be more than property value
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className='col-md-4 mt-2'>
                  <label>&nbsp;</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      value={
                        this.state.percentageEquity ||
                        (() => {
                          const percentage =
                            (parseFloat(clearCommas(down_payment)) /
                              parseFloat(clearCommas(property_value))) *
                            100;
                          return percentage ? Math.round(percentage) : "";
                        })() ||
                        ""
                      }
                      onChange={this.handleEquityPercentageChange}
                      pattern='^\d+(?:\.\d+)?$'
                    />
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>%</span>
                    </div>
                    <div className='invalid-feedback'>
                      Please enter a valid number
                    </div>
                  </div>
                </div>

                <div className='col-md-8 mt-2'>
                  <label>Loan Amount</label>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>₦</span>
                    </div>
                    <input
                      type='text'
                      className='form-control'
                      pattern={currencyRegexString}
                      maxLength='19'
                      value={this.state.loan_amount}
                      data-state-name='loan_amount'
                      onChange={this.handleCurrencyInputChange}
                      disabled
                    />
                    <div className='invalid-feedback'>
                      {currencyFieldErrorMessage}
                    </div>
                  </div>
                </div>

                <div className='col-md-4 mt-2'>
                  <label>&nbsp;</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      value={(() => {
                        const percentage =
                          (parseFloat(clearCommas(loan_amount)) /
                            parseFloat(clearCommas(property_value))) *
                          100;
                        return percentage ? Math.round(percentage) : "";
                      })()}
                      disabled
                    />
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>%</span>
                    </div>
                    <div className='invalid-feedback'>
                      {currencyFieldErrorMessage}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-12 fp-eligibility-test-application-form-button'>
                  <button
                    type='submit'
                    className='fp-eligibility-test-continue-button'
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default LandingStepForm;
