import React, { Component } from "react";

import { handleChange, validateForm } from "../../utils/authFormUtils";
import { handleCurrencyInputChange } from '../../utils/eligibilityFormUtils';
import { currencyRegexString } from "../../utils/regexUtils";
import { clearCommas, formatCurrencyInput, formatDecimals } from "../../utils/currencyUtils";

class LandingStepForm extends Component {
  state = {
    nhf_reg_number: this.props.nhf_reg_number,
    property_value: this.props.property_value,
    loan_amount: this.props.loan_amount,
    down_payment: this.props.down_payment
  };

  calculateEquity = (property_value) => {
    let down_payment;
    if (property_value <= 10000000) down_payment = 0;
    else if (property_value < 15000000) down_payment = 0.1 * property_value;
    else if (property_value < 16500000) down_payment = 1500000;
    else down_payment = 1500000 + (property_value - 16500000);
    return formatDecimals(down_payment);
  };

  getEquityAndLoanAmount = function  (property_value) {
    property_value = typeof property_value === 'string' ? parseFloat(clearCommas(property_value)) : property_value;
    let { affordabilityData: { maxLoanableAmount } } = this.props;
    maxLoanableAmount = parseFloat(maxLoanableAmount);
    let down_payment, loan_amount;
    if(!property_value) {
      down_payment = this.calculateEquity(maxLoanableAmount);
      loan_amount = maxLoanableAmount - down_payment;
      return { loan_amount: maxLoanableAmount, down_payment };
    }
    
    down_payment = this.calculateEquity(property_value);
    loan_amount = property_value - down_payment;
    if (loan_amount > maxLoanableAmount) {
      loan_amount = maxLoanableAmount;
      if (loan_amount < 10000000) down_payment = property_value - loan_amount;
      else {
        const diff = property_value - loan_amount;
        const tenPercent = 0.1 * property_value;
        if (diff > tenPercent) down_payment = diff;
        else down_payment = tenPercent;
      }
      down_payment = formatDecimals(down_payment);
    }
    loan_amount = property_value - down_payment;
    return { loan_amount, down_payment };
};

  componentDidMount() {
    let { property_value } = this.props;
    const { loan_amount, down_payment } = this.getEquityAndLoanAmount(property_value);
    this.setState({ loan_amount, down_payment });
  }

  componentWillUnmount() {
    this.props.setParentState({ ...this.state });
  };

  /**
   * Handles change event on the input field
   * @param {DOMEvent} event
   */
  handleChange = event => handleChange(event, this);

  handleCurrencyInputChange = event => {
    const { target: { value } } = event;
    const property_value = parseFloat(clearCommas(value));
    const { loan_amount, down_payment } = this.getEquityAndLoanAmount(property_value);
    handleCurrencyInputChange(event, this);
    handleCurrencyInputChange({
      target: { dataset: { stateName: 'loan_amount' }, value: loan_amount }
    }, this);
    handleCurrencyInputChange({
      target: { dataset: { stateName: 'down_payment' }, value: down_payment }
    }, this);
  };

  /**
   * Handles form's submit event
   * @param {DOMEvent} event
   */
  handleSubmit = event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    // Go to the next form
    this.props.setParentState({ activeComponentIndex: this.props.componentIndex + 1 });
  };

  render() {
    return (
      <div className='fp-eligibility-test-application-form-wrapper landing nhf'>
        <div className='row'>
          <div className='col-md-8 offset-md-2 mt-2'>
            <h2 className='fp-eligibility-test-application-form-title'>
              Check Eligibility &amp; Get Pre-qualified for a Mortgage
            </h2>
            <p className='fp-eligibility-test-application-form-subtitle'>
              You are one step closer to getting finance for your new home. Our
              eligibility test ensures you meet all the set criteria, needed to
              apply for a home loan. Enter your desired property value below to
              begin.
            </p>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className='form-group row'>
                <div className='col-md-12'>
                  <label>NHF Registration Number</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      data-state-name='nhf_reg_number'
                      value={this.state.nhf_reg_number}
                      onChange={this.handleChange}
                      required
                    />
                    <div className='invalid-feedback'>Value is required</div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-12">
                  <label>Property value</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className='form-control'
                      value={formatCurrencyInput(this.state.property_value)}
                      data-state-name='property_value'
                      pattern={currencyRegexString}
                      onChange={this.handleCurrencyInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Invalid value
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="form-group row">
                <div className="col-md-12">
                  <label>Loan amount</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className='form-control'
                      value={formatCurrencyInput(this.state.loan_amount)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-12">
                  <label>Equity Contribution</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className='form-control'
                      value={formatCurrencyInput(this.state.down_payment)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-12 fp-eligibility-test-application-form-button'>
                  <button
                    type='submit'
                    className='fp-eligibility-test-continue-button'
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default LandingStepForm;