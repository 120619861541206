import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as Icon from "react-feather";

import { handleChange, closeAuthFormModal, validateForm } from "../../utils/authFormUtils";
import { createRef } from "react";
import { clearErrorStore } from "../../utils/errorUtils";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.submitButtonRef = createRef(null);
  }

  state = { email: "", password: "", keepMeLoggedIn: true };
  /**
   * Handles change event on an input element
   * @param {DOMEvent} event
   */
  handleChange = event => handleChange(event, this);

  /**
   * Handles change event on an input element
   * @param {DOMEvent} event
   */
  handleSubmit = event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    const { current: submitButton } = this.submitButtonRef;
    const data = { ...this.state };
    delete data.keepMeLoggedIn;
    this.props.login(data, submitButton, this.props.history);
  };

  /**
   * Handles blur event on the component
   */
  handleBlur = () => clearErrorStore();

  /**
   * Handles click event on "Join Now"
   * @param {DOMEvent} event
   */
  handleJoinNowClick = event => {
    event.preventDefault();
    closeAuthFormModal(event.target);
    this.props.clickNavItem(event.target, "a.btn-register");
  };

  render() {
    const { errors, isLoading } = this.props;
    return (
      <div className='modal-content' onBlur={this.handleBlur}>
        <div className='modal-body fp-modal-body-wrapper'>
          <a
            href='/'
            role='button'
            className='close fp-login-modal-close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>×</span>
          </a>

          <div>
            <div className='fp-login-page-logo'></div>
            {errors && errors.length ? (
              <ul className='error-list fp-errors-display-list'>
                {errors.map((msg, index) => {
                  return (
                    <li className='error-item' key={index}>
                      <Icon.AlertCircle
                        className='mr-2'
                        size='12'
                        color='#f00'
                      />
                      {msg}
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
            <form
              className='fp-login-form-wrapper'
              onSubmit={this.handleSubmit}
              noValidate
            >
              <div className='form-group'>
                <label htmlFor='Email address'>Email address</label>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Email address'
                  data-state-name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete='username'
                  pattern='^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$'
                  required
                />
                <div className='invalid-feedback'>Invalid email address</div>
              </div>
              <div className='form-group'>
                <label htmlFor='Password' className='mg-b-0-f'>
                  Password
                </label>
                <input
                  type='password'
                  className='toggled form-control'
                  placeholder='Password'
                  data-state-name='password'
                  value={this.state.password}
                  onChange={this.handleChange}
                  autoComplete='current-password'
                  minLength='6'
                  required
                />
                <div className='invalid-feedback'>
                  Password must be at least 6 characters long
                </div>
              </div>

              <div className='fp-checkbox fp-keep-me-logged-in'>
                <input
                  type='checkbox'
                  name='keepMeLoggedIn'
                  data-state-name='keepMeLoggedIn'
                  onChange={this.handleChange}
                />
                <label htmlFor=' Keep me logged in' className='checkbox'>
                  Keep me logged in
                </label>
              </div>

              <div className='fp-login-options'>
                <div className='fp-forgotPassword'>
                  <Link to='/auth/password/reset'>Forgot password?</Link>
                </div>
              </div>
              <p className='fp-terms-privacy-policy'>
                By logging in, you agree to Newhomes's{" "}
                <a className='' href='/'>
                  Privacy Policy
                </a>{" "}
                and <a href='/'> Terms of Use</a>.
              </p>
              <button
                className='fp-modal-login-button'
                type='submit' disabled={isLoading}
                ref={this.submitButtonRef}
              >
                log in
              </button>
              <div className='fp-create-account-wrapper'>
                Not a member?
                <a href='/auth/register' onClick={this.handleSigninClick}>
                  {" "}
                  Join Now.
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
