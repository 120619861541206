import React from 'react';
import { useState } from 'react';
import { nameRegexString, emailRegexString } from '../../utils/regexUtils';
import { handleSingleFieldBlur } from '../../utils/eligibilityFormUtils';


const ContactForm = () => {
  const initialState = {firstName: "", lastName: "", message: "", email: ""};
  const [state, setState] = useState(initialState);
  const { firstName, lastName, message, email } = state;
  
  const handleChange = ({ target: { name, value } }) => setState({ ...state, [name]: value });

  const handleInputBlur = ({ target }) => {
    // eslint-disable-next-line no-sparse-arrays
    handleSingleFieldBlur(...[target, , (value) => handleChange({ target: { name: target.name, value } })]);
  }
  // const [refNumber, setRefNumber] = useState('');
  const handleSubmit = event => {
    event.preventDefault();
    const subject = "Some shit";
    const body = `Hi,<br><br>my name is ${firstName} ${lastName}.<br><br>And I say "${message}"`;

    window.open(
      `mailto:a@b.com?subject=${subject}&body=${body.replace(
        /<br>/g,
        "%0D%0A"
      )}`
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group row'>
        <div className='col-md-6'>
          <label>
            First Name <sup>*</sup>
          </label>
          <input
            type='text'
            placeholder='First name'
            name='firstName'
            value={firstName}
            pattern={nameRegexString}
            onChange={ handleChange }
            onBlur={handleInputBlur}
            required />
        </div>
        <div className='col-md-6'>
          <label>
            Last Name <sup>*</sup>
          </label>
          <input
            type='text'
            placeholder='Last name'
            name='lastName'
            value={lastName}
            pattern={nameRegexString}
            onChange={ handleChange }
            onBlur={handleInputBlur}
            required />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-md-12'>
          <label>
            Preferred Contact Email Address <sup>*</sup>
          </label>
          <input
            type='email'
            placeholder='you@mail.com'
            name='email'
            value={email}
            pattern={emailRegexString}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div className='col-md-6'>
          <label>
            Reference Number <sup>*</sup>
          </label>
          <input
            type='text'
            placeholder='Reference number'
            name='refNumber'
            value={refNumber}
            onChange={ handleChange } />
        </div> */}
      </div>
      <div className='form-group row'>
        <div className='col-md-12'>
          <label>
            Say something <sup>*</sup>
          </label>
          <textarea
            name='message'
            rows='6'
            placeholder='Enter incident details here.'
            onChange={handleChange}
            required
          >
            {message}
          </textarea>
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-md-12'>
          <button className='fp-mortgage-application-contact-us-form-submit-btn'>
            Contact Us
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
