import React, {Component} from "react";
import "../Buttons/Buttons.css";

import Medal from "../Resource/medal.png";
import {formatCurrencyInput} from "../../utils/currencyUtils";

const styles = {
  SuccessImage: {
    backgroundImage: `url(${Medal})`
  }
};

class EligibilitySuccess extends Component {
  render() {
    const {serverResponse, goToRoute} = this.props;
    const monthlyPayment = serverResponse ? serverResponse.monthly_payment : "";
    const loanAmount = serverResponse ? serverResponse.loan_amount : "";
    // const propertyValue = serverResponse ? serverResponse.property_value : '';
    return (
      <div className='fp-eligibility-test-application-form-wrapper result'>
        <div className='row'>
          <div className='col-md-10 offset-md-1'>
            <div
              className='fp-eligibility-test-success-medal'
              style={styles.SuccessImage}
            ></div>
            <h2 className='fp-eligibility-test-success-text'>
              Your are Eligible!
            </h2>
            <div className='fp-affordability-test-payment-summary'>
              <div className='fp-loan-limit'>
                <span>Get up to</span>
                <h4>{formatCurrencyInput(loanAmount)}</h4>
              </div>
              <div className='fp-monthly-repayment'>
                <span>Est. monthly repayment</span>
                <h4>{formatCurrencyInput(monthlyPayment)}</h4>
              </div>
            </div>

            <p className='fp-eligibility-test-success-text-subtitle'>
              Congratulations! You are eligible for a mortgage, and are well on
              your way to acquiring your new home. <a href='/'>Terms apply</a>.
              How would you like to proceed?
            </p>

            <div className='fp-eligibility-test-application-button-wrapper'>
              {/* <button type='button' className='fp-send-email-with-results'>
                Send email with results
              </button> */}
              <button
                type='button'
                className='fp-eligibility-apply-for-mortgage'
                onClick={() => goToRoute("/mortgage/application")}
              >
                Apply for mortgage
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EligibilitySuccess;
