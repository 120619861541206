import React, { Component } from "react";
import { connect } from "react-redux";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import DashboardBreadCrumb from "../DashboardBreadCrumb";

import CircularLoader from "../CircularLoader";

import AssignLender from "./AssignLender";

import "./AdminUserSingleApplication.css";

import "../Buttons/Buttons.css";
import * as Icon from "react-feather";

import { clearErrorStore } from "../../utils/errorUtils";
import { toTitleCase, formatDate, fetchApplicationData } from "../../utils/dashboardUtils";
import { formatCurrencyInput } from "../../utils/currencyUtils";
import ErrorToaster from "../ErrorToaster";
import http from "../../config/axios.config";

class AdminUserSingleApplication extends Component {
  state = {
    application: null,
    uploads: [],
    lenders: []
  };

  componentDidMount() {
    this.fetchApplicationData();
    this.fetchAllLenders();
  }

  componentWillUnmount() {
    clearErrorStore();
  }

  fetchApplicationData = async () => {
    const { match: { params: { slug } } } = this.props;
    const url = `/user/application/view/${slug}`;
    const data = await fetchApplicationData(url);
    const { application, doc_upload: uploads } = data;
    this.setState({ application, uploads });
  };

  fetchAllLenders = async () => {
    try {
      const { data: result } = await http.get('/all-lenders');
      this.setState({ lenders: result.data });
    } catch (error) {}
  };

  setStateValue = pairs => this.setState(pairs);

  render() {
    const { application, uploads } = this.state;
    console.log(uploads)
    let lender;
    if (application) lender = application.lender;
    const theresLender = lender && Object.keys(lender).length;
    const lenderFieldsWrapperClassName = `fp-mortgage-lender-company-info-wrap${
      theresLender ? "" : " hidden"
    }`;
    const {
      match: {
        params: { slug }
      }
    } = this.props;
    return (
      <div className='container-fluid px-0'>
        <ErrorToaster />
        <CircularLoader isLoading={this.props.isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='Admin' />
        <div className='fp-mortgage-content-wrapper'>
          <DashboardBreadCrumb
            type='Single Mortgage Application'
            title='Application Details'
          />
          <div className='container'>
            <div className='fp-dashboard-section'>
              {application ? (
                <div className='row'>
                  <div className='col-md-4 fp-mortgage-application-lender-info-wrapper'>
                    <div className='fp-mortgage-application-lender-info'>
                      <div className='fp-mortgage-lender-company-info'>
                        <h2>Client Info</h2>
                        <Icon.Eye color='#828282' size='25' />
                      </div>
                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.User
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Name</span>
                          <h4>
                            {`${
                              application.firstname
                                ? toTitleCase(application.firstname)
                                : ""
                            } ${
                              application.lastname
                                ? toTitleCase(application.lastname)
                                : ""
                            }`}
                          </h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.Mail
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Email Address</span>
                          <h4>{application.email}</h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap'>
                        <Icon.Phone
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-phone'>
                          <span>Phone Number</span>
                          <h4>{application.phone}</h4>
                        </div>
                      </div>

                      <div className='fp-mortgage-lender-company-info-wrap fp-border-line'>
                        <Icon.MapPin
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-address'>
                          <span>Client Address</span>
                          <h4>{application.address}</h4>
                        </div>
                      </div>

                      {/* Assign lender */}
                      {!theresLender ? (
                        <div className='fp-mortgage-lender-company-info'>
                          <h2>Assign lender</h2>
                          <a href='#modalSelectLender' data-toggle='modal'>
                            <Icon.Settings color='#828282' size='20' />
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {
                        !theresLender ? (
                        <div className='fp-mortgage-blank-lender-wrapper'>
                          <Icon.Archive color='#828282' size='200' />
                        </div>
                      ) : (
                        ""
                      )}

                      {/* To edit lender  */}

                      <div className='fp-mortgage-lender-company-info'>
                        <h2>Lender Details</h2>
                        {theresLender ? (
                          <a href='#modalSelectLender' data-toggle='modal'>
                            <Icon.Edit color='#828282' size='20' />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className={lenderFieldsWrapperClassName}>
                        <Icon.User
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Lenders Name</span>
                          <h4>{theresLender ? lender.lender_name : ""}</h4>
                        </div>
                      </div>

                      <div className={lenderFieldsWrapperClassName}>
                        <Icon.Mail
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-email'>
                          <span>Email Address</span>
                          <h4>{theresLender ? lender.lender_email : ""}</h4>
                        </div>
                      </div>

                      <div className={lenderFieldsWrapperClassName}>
                        <Icon.Phone
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-phone'>
                          <span>Phone Number</span>
                          <h4>{theresLender ? lender.lender_phone : ""}</h4>
                        </div>
                      </div>

                      <div className={lenderFieldsWrapperClassName}>
                        <Icon.MapPin
                          className='fp-mortgage-lender-company-info-icon'
                          color='#555555'
                        />
                        <div className='fp-mortgage-lender-company-address'>
                          <span>Company Address</span>
                          <h4>{theresLender ? lender.lender_address : ""}</h4>
                        </div>
                      </div>
                    </div>
                    <div className='fp-mortgage-application-loan-value'>
                      <h2>
                        {formatCurrencyInput(application.loan_amount)}
                      </h2>
                      <p>Total loan value</p>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className='fp-mortgage-application-loan-info-display'>
                      <div className='fp-mortgage-application-reference-no-loan-status'>
                        <div className='fp-mortgage-application-reference-no'>
                          <span>Reference</span>
                          <h4>{application.app_ref}</h4>
                        </div>
                        <div className='fp-mortgage-application-date'>
                          <span>Application Submitted</span>
                          <h4>
                            {formatDate(
                              application.created_at,
                              "medium",
                              "short"
                            )}
                          </h4>
                        </div>
                        <div
                          className={`fp-mortgage-application-status ${
                            application.mortgage_status_name.toLowerCase()}`}
                        >
                          <span>Loan Status</span>
                          <h4>{application.mortgage_status_name}</h4>
                        </div>
                      </div>
                      <div className='fp-mortgage-loan-amount-repayment-interest-rate'>
                        <div className='fp-mortgage-loan-amount-wrap'>
                          <h2>
                            {formatCurrencyInput(application.loan_amount)}
                          </h2>
                          <span>Loan Amount</span>
                        </div>
                        <div className='fp-mortgage-repayment'>
                          <h2>{application.loan_tenure} years</h2>
                          <span>Repayment period</span>
                        </div>
                        {application.interest ? (
                          <div className='fp-mortgage-loan-interest-rate'>
                            <h2>{application.interest}</h2>
                            <span>Interest rate</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='fp-mortgage-loan-more-information'>
                        <div className='fp-mortgage-loan-more-information-right'>
                          <div className='fp-mortgage-loan-type-application-wrap'>
                            <div className='fp-mortgage-loan-type'>
                              <span>Loan Type</span>
                              <h4>
                                {
                                  ((type) => type === 'nhf' ? type.toUpperCase() : toTitleCase(type))(application.type)
                                }
                              </h4>
                            </div>
                            <div className='fp-mortgage-loan-application-type'>
                              <p> Single Application</p>
                            </div>
                          </div>
                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Property Address</span>
                            <h4>{application.property_address}</h4>
                          </div>
                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Property Type</span>
                            <h4>Terrace Duplex | 3 Bedroom</h4>
                          </div>

                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Down Payment</span>
                            <h4>
                              ₦{" "}
                              {formatCurrencyInput(
                                application.down_payment
                              )}
                            </h4>
                          </div>

                          <div className='fp-mortgage-loan-property-address-wrap'>
                            <span>Comments</span>
                            <h4 className='text-break'>
                              All clear here! I see no reason why the loan
                              shouldn’t be approved. Papers check out, borrower
                              info, property info e.t.c. We recommend the loan.
                              Please proceed.
                            </h4>
                          </div>
                        </div>
                        <div className='fp-mortgage-loan-more-information-left'>
                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>Borrower Eligibility</h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>
                              All Required Documents
                            </h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#55af1a' size='35' />
                            <h4 className='text-wrap'>Borrower Verification</h4>
                          </div>

                          <div className='fp-mortgage-loan-requirement-status'>
                            <Icon.CheckCircle color='#bbbbbb' size='35' />
                            <h4 className='text-wrap'>Property Verification</h4>
                          </div>
                        </div>
                      </div>
                      <div className='fp-mortgage-loan-documents-uploads-actions'>
                        <div className='fp-mortgage-loan-documents-uploads-actions-right'>
                          <div className='fp-mortgage-loan-documents-uploads'>
                            <Icon.File color='#555555' size={40} />
                            <span className='fp-document-badge badge badge-primary'>
                              ID
                            </span>
                          </div>

                          <div className='fp-mortgage-loan-documents-uploads'>
                            <Icon.File color='#555555' size={40} />
                            <span className='fp-document-badge badge badge-primary'>
                              P-SLIP
                            </span>
                          </div>

                          <div className='fp-mortgage-loan-documents-uploads'>
                            <Icon.File color='#555555' size={40} />
                            <span className='fp-document-badge badge badge-primary'>
                              TITLE
                            </span>
                          </div>

                          <div className='fp-mortgage-loan-documents-uploads'>
                            <Icon.File color='#555555' size={40} />
                            <span className='fp-document-badge badge badge-primary'>
                              RECEIPT
                            </span>
                          </div>
                        </div>
                        {/* <div className='fp-mortgage-loan-documents-uploads-actions-left'>
                          <span className='fp-dashboard-more-actions'>
                            Actions <Icon.MoreHorizontal color='#fff' />
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='modalSelectLender'
          role='dialog'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered wd-sm-400'
            role='document'
          >
            <AssignLender
              slug={slug}
              lenders={this.state.lenders}
              isLoading={this.props.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ isLoading }, ownProps) => {
  return { isLoading, ...ownProps };
};


export default connect(mapStateToProps)(AdminUserSingleApplication);
