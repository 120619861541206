import React from 'react';

import './applicationProgressBar.css';


const ApplicationProgressBar = ({ step, parentClassName }) => {
  return (
    <div className={`application-progress-bar-wrapper${parentClassName ? ` ${parentClassName}` : ''}`}>
      {/* <p className='application-progress-enumerator'>{step}/5</p> */}
      <div className="application-progress-bar" style={{ width: `${step / 6 * 100}%` }}></div>
    </div>
  );
}
 
export default ApplicationProgressBar;