import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import "./DashboardHeader.css";

import * as Icon from "react-feather";

import FinancePlusLogo from "../Resource/finance-plus-long.png";
import ProgressBar from "../NProgress";
import {redirectUserWhenNotLoggedIn} from "../../utils/authFormUtils";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import BrowserStorage from "../../utils/browserStorageUtils";
import {logout, toTitleCase} from "../../utils/dashboardUtils";

class DashboardHeader extends Component {
  componentDidMount() {
    const token = BrowserStorage.getUserToken();
    if (token) this.props.setIsAuthenticated(true);
    else
      redirectUserWhenNotLoggedIn(
        this.props.history,
        this.props.setIsAuthenticated,
      );
  }

  /**
   * Handles click event on log out button
   * @param {DOMEvent} event
   */
  handleLogout = event => {
    event.preventDefault();
    logout(this.props.setIsAuthenticated, this.props.history);
  };

  render() {
    const {isLoading} = this.props;
    const userData = BrowserStorage.getUserData();
    const {usertype, initials, fullName} = userData;
    return (
      <div>
        <ProgressBar isLoading={isLoading} />
        <nav
          id='nav-scroll'
          className='navbar navbar-expand-md navbar-dark navbar-light bg-white sticky-navbar custom-navbar-two'
        >
          <div className='container-fluid'>
            <Link to='/'>
              <img
                className='navbar-brand'
                src={FinancePlusLogo}
                alt='Finance Plus'
              />
            </Link>
            <div className='fp-mobile-navigation-toggle'>
              {this.props.menuVisibility ? (
                <span onClick={this.props.toggleMenu}>
                  <Icon.AlignRight color='#121212' size='25px' />
                </span>
              ) : (
                <span onClick={this.props.toggleMenu}>
                  <Icon.AlignJustify color='#121212' size='25px' />
                </span>
              )}
            </div>

            <ul className='navbar-nav mr-auto p-1'>
              <li className='navbar-item fp-mortgage-search-form'>
                <div className='input-group stylish-input-group'>
                  <span className='str-search-icon'>
                    <Icon.Search color='#b9b7b7' className='fp-icon-trim' />
                  </span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search lender or customer'
                  />
                </div>
              </li>
            </ul>

            <ul className='navbar-nav ml-auto'>
              <li className='navbar-item fp-mortgage-notification-chat-profile-wrapper'>
                <div className='fp-mortgage-chat'>
                  <Icon.MessageSquare color='#b9b7b7' size='25px' />
                  {/* <span className='nav-unread badge badge-success badge-pill'></span> */}
                </div>
                <div className='fp-mortgage-notification'>
                  <Icon.Bell color='#b9b7b7' size='25px' />
                  {/* <span className='nav-unread badge badge-primary badge-pill'></span> */}
                </div>
                <div className='fp-divider'></div>
                <div className='fp-mortgage-user-profile-wrapper'>
                  <div className='fp-mortgage-user-details'>
                    <h2>{fullName}</h2>
                    <span>{toTitleCase(usertype)}</span>
                  </div>
                  <div className='fp-mortgage-user-avatar'>
                    <span className='fp-user-avatar-initial rounded-circle'>
                      {initials}
                    </span>
                  </div>
                  <div className=''>
                    <Link
                      className='navbar-nav-link'
                      to='/'
                      role='button'
                      data-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <Icon.MoreVertical color='#b9b7b7' />
                    </Link>
                    <div
                      className='dropdown-menu dropdown-menu-right dropdown-menu-arrow fp-mortgage-dropdown'
                      x-placement='bottom-end'
                    >
                      {usertype === "user" ? (
                        <Link
                          className='dropdown-item'
                          to='/user/dashboard/uploads'
                        >
                          <Icon.Paperclip
                            color='#555555'
                            size='18'
                            className='mr-2'
                          />
                          Uploads
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link
                        className='dropdown-item'
                        to='/user/dashboard/settings'
                      >
                        <Icon.Settings
                          color='#555555'
                          size='18'
                          className='mr-2'
                        />
                        Settings
                      </Link>
                      <Link
                        className='dropdown-item'
                        to='/'
                        onClick={this.handleLogout}
                      >
                        <Icon.LogOut
                          color='#555555'
                          size='18'
                          className='mr-2'
                        />
                        Sign out
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
