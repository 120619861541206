import React from "react";

import "./LenderContactDetails.css";
import * as Icon from "react-feather";


const LenderContactDetails = ({data}) => {
  const styles = {
    LenderImage: {
      backgroundImage: `url(${data.lender_logo})`
    }
  };

  const theresLender = data && Object.keys(data).length;
  return (
    <div className='fp-mortgage-application-lender-info'>
      <div className='fp-mortgage-lender-company-info mt-0 mb-2'>
        <h2>Lender Details</h2>
      </div>
      {!theresLender ? (
        <div className='fp-mortgage-blank-lender-wrapper'>
          <Icon.Archive color='#828282' size='200' />
        </div>
      ) : (
        ""
      )}
      {theresLender ? (
        <div>
          <div className='fp-mortgage-application-lender-content'>
            <div
              className='fp-mortgage-application-lender-icon'
              style={styles.LenderImage}
            ></div>
            <p>RC {data.lender_license ? data.lender_license : "N/A"}</p>
          </div>

          <div className='fp-mortgage-lender-company-info'>
            <h2>Company Info</h2>
          </div>
          <div className='fp-mortgage-lender-company-info-wrap'>
            <Icon.Mail
              className='fp-mortgage-lender-company-info-icon'
              color='#555555'
            />
            <div className='fp-mortgage-lender-company-email'>
              <span>Email Address</span>
              <h4>{data.lender_email}</h4>
            </div>
          </div>

          <div className='fp-mortgage-lender-company-info-wrap'>
            <Icon.Phone
              className='fp-mortgage-lender-company-info-icon'
              color='#555555'
            />
            <div className='fp-mortgage-lender-company-phone'>
              <span>Phone Number</span>
              <h4>{data.lender_phone}</h4>
            </div>
          </div>

          <div className='fp-mortgage-lender-company-info-wrap'>
            <Icon.Map
              className='fp-mortgage-lender-company-info-icon'
              color='#555555'
            />
            <div className='fp-mortgage-lender-company-address'>
              <span>Company Address</span>
              <h4>{data.lender_address}</h4>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LenderContactDetails;
