import React, { Component } from "react";

import Header from "../Header";
import Footer from "../Footer";

import "../ApplicationType/ApplicationType.css";

import FirstTrust from "../Resource/first-trust-theme-logo.png";
import StandardCharted from "../Resource/standard-chartered-theme-logo.png";
import StandardIBTC from "../Resource/stanbic-ibtc-theme-logo.png";

import FederalMortgageBank from "../Resource/federal-mortgage-bank-theme-logo.png";

class ApplicationTypeV2 extends Component {
  render() {
    return (
      <div className='container-fluid p-0'>
        <Header history={this.props.history} type='others' />
        <section id='fp-mortgage-application-type'>
          <div className='fp-mortgage-application-type-wrapper'>
            <div className='fp-mortgage-application-type-bg'></div>
            <div className='row'>
              <div className='col-md-6 fp-mortgage-application-type-wrapper-left'>
                <a href='/'>
                  <div className='fp-mortgage-application-type-content-list'>
                    <div className='row'>
                      <div className='col-md-10 offset-md-1'>
                        <div className='fp-mortgage-application-type-icon regular'></div>
                        <h2>Apply For</h2>
                        <h4>Regular Mortgage</h4>
                        <p>Apply for a mortgage to the following lenders</p>

                        <div className='fp-mortgage-application-type-mortgage-lenders'>
                          <div className='row'>
                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo first-trust'>
                              <img
                                className='img-responsive'
                                src={FirstTrust}
                                alt='First Trust'
                              />
                            </div>

                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo standard-chartered'>
                              <img
                                className='img-responsive'
                                src={StandardCharted}
                                alt='Standard Chartered'
                              />
                            </div>

                            <div className='col-md-4 fp-mortgage-application-type-mortgage-lenders-logo standard-ibtc'>
                              <img
                                className='img-responsive'
                                src={StandardIBTC}
                                alt='Standard IBTC'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-md-6 fp-mortgage-application-type-wrapper-right'>
                <a href='/'>
                  <div className='fp-mortgage-application-type-content-list'>
                    <div className='row'>
                      <div className='col-md-10 offset-md-1'>
                        <div className='fp-mortgage-application-type-icon nhf'></div>
                        <h2>Apply For</h2>
                        <h4>National Housing Funds (NHF) Loan</h4>
                        <p>For applicants that are NHF contributors</p>
                        <div className='fp-mortgage-application-type-mortgage-lenders'>
                          <div className='row'>
                            <div className='col-md-4 offset-md-4 fp-mortgage-application-type-mortgage-lenders-logo federal-mortgage'>
                              <img
                                className='img-responsive'
                                src={FederalMortgageBank}
                                alt='Federal Mortgage Bank Nigeria'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer type='others' />
      </div>
    );
  }
}

export default ApplicationTypeV2;
