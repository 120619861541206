import React, {Component} from "react";
import {Link} from "react-router-dom";
import UserNav from "./UserNav";

import FinancePlusLogo from "../Resource/finance-plus-long.png";
import * as Icon from "react-feather";

import "./DashboardSidebar.css";

class DashboardSidebar extends Component {
  render() {
    return (
      <div
        className={`fp-mortgage-sidebar ${
          this.props.menuVisibility ? "showMenu" : ""
        }`}
      >
        <Link to='/'>
          <img
            className='fp-mobile-navigation-user-avatar-navigation-logo'
            src={FinancePlusLogo}
            alt='Finance Plus'
          />
        </Link>

        {/* <span className='toggleCancel' onClick={this.props.toggleMenu}>
          <Icon.AlignRight color='#121212' size='25px' />
        </span> */}

        {this.props.menuVisibility ? (
          <span className='toggleCancel' onClick={this.props.toggleMenu}>
            <Icon.AlignRight color='#121212' size='25px' />
          </span>
        ) : (
          ""
        )}

        <div className='fp-mobile-navigation-user-avatar'>hello</div>
        {<UserNav />}
      </div>
    );
  }
}
export default DashboardSidebar;
