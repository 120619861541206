export const SET_AFFORDABILITY_DATA = "SET_AFFORDABILITY_DATA";
export const SET_MORTGAGE_APPLICATION_DATA = "SET_MORTGAGE_APPLICATION_DATA";
export const SET_ERRORS = "SET_ERRORS";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_EARNING_DATA = "SET_EARNING_DATA";
export const USER_TOKEN = "token";
export const PENDING_DESTINATION = "PENDING_DESTINATION";
export const IS_LOADING = "IS_LOADING";
export const USER_DATA = "USER_DATA";
export const DASHBOARD_URL = "/user/dashboard";
export const LENDER_DASHBOARD_URL = "/lender/dashboard";
export const ADMIN_DASHBOARD_URL = "/admin/dashboard";
export const LOGIN_PAGE_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const WELCOME_PAGE_URL = "/";
export const BASE_URL = "https://app.financeplus.ng/api";
export const MIN_EQUITY_PERCENTAGE = 0.2;
export const SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE = 0.35;
export const SET_NHF_REG_NUMBER = "SET_NHF_REG_NUMBER";
export const STORE_DATA = "STORE_DATA";
export const SET_EDIT_APPLICATION_DATA = "SET_EDIT_APPLICATION_DATA";
export const justgetittobuild = 9;

export const DEFAULT_AFFORDABILITY_DATA = {
  loan_amount: "",
  dob: "",
  monthly_payment: "",
  properties: null,
  rate: 18,
  have_additional_income: "",
  have_other_obligations: "",
  tenure: 20,
  maxLoanableAmount: "",
  other_obligations: "",
  isNhf: false,
  co_borrower: "",
  co_borrower_gross_income: "",
  co_borrower_additional_income: ""
};
export const DEFAULT_MORTGAGE_APPLICATION_DATA = {
  down_payment: "",
  property_value: "",
  property_title: "",
  property_address: ""
};
export const NON_PROTECTED_ROUTES = [
  "/affordability-test",
  "/nhf/affordability",
  "/contact",
  "/company/about",
  "/mortgage/application-type",
  "/auth/login",
  "/auth/register",
  "/",
  "",
  "/support/faqs",
  "/legal/privacy-policy"
];

export const DEFAULT_APP_DATA = {};
export const DEFAULT_EARNING_DATA = {monthly_income: "", additional_income: ""};
export const DEFAULT_RADIO_VALUES = ["yes", "no"];
