import React from "react";
import * as Icon from "react-feather";

import { formatDate, toTitleCase, fetchApplicationData, cleanApplicationData } from "../../utils/dashboardUtils";
import { formatCurrencyInput } from "../../utils/currencyUtils";
import BrowserStorage from "../../utils/browserStorageUtils";


// {...{ application, goToRoute, setLoading, setErrors, setEditApplicationData }}
const VerticalApplicationListItem = ({ application, goToRoute, setLoading, setErrors, setEditApplicationData }) => {
  const { usertype } = BrowserStorage.getUserData();
  const isUser = usertype === "user";
  const statusClass = " " + application.mortgage_status_name.toLowerCase();

  const fetchExistingApplicationData = async (slug) => {
    try {
      const url = `/user/application/view/${slug}`;
      const { application } = await fetchApplicationData(url);
      delete application.lender; delete application.user;
      cleanApplicationData(application);
      setEditApplicationData(application);
      goToRoute(`/mortgage/application/${slug}/edit`);
      } catch {}  
  };

  return (
    <li className={`table-row${statusClass}`}>
      <div className='col col-2' data-label='Reference No'>
        {application.app_ref}
      </div>
      {!isUser ? (
        <div className='col col-3' data-label='Customer'>
          {toTitleCase(application.firstname)}{" "}
          {toTitleCase(application.lastname)}
        </div>
      ) : (
        ""
      )}
      {!isUser ? (
        <div className='col col-3' data-label='Phone'>
          {application.phone}
        </div>
      ) : (
        ""
      )}
      <div className='col col-3' data-label='Application date'>
        {formatDate(application.created_at, "short")}
      </div>
      <div className='col col-3' data-label='Loan Amount'>
        ₦ {formatCurrencyInput(application.loan_amount)}
      </div>
      <div className='col col-2' data-label='Status'>
        <span className={`badge${statusClass}`}>
          {application.mortgage_status_name}
        </span>
      </div>
      <div className='col col-2' data-label='Action'>
        {
          (() => {
            const style = { color: '#555555', size: '23' };
            return (isUser && application.mortgage_status_name.toLowerCase() !== 'submitted') ? (
              <Icon.Edit
                {...style}
                onClick={() => fetchExistingApplicationData(application.slug)}
              />
            ) : (
              <Icon.Eye
                {...style}
                onClick={() => goToRoute(`/${usertype}/dashboard/application/view/${application.slug}`)}
              />
            )
          })()
        }
      </div>
    </li>
  );
};

export default VerticalApplicationListItem;
