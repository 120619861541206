import React, { Component } from "react";
import { connect } from "react-redux";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";

import CircularLoader from "../CircularLoader";
import "./AdminDashboard.css";
import "../Buttons/Buttons.css";

import setErrors from "../../store/actions/setErrors";
import setLoading from "../../store/actions/setLoading";
import AdminDashboardApplicationList from "./AdminDashboardApplicationList";
import { extractErrors, clearErrorStore } from "../../utils/errorUtils";
import { BASE_URL } from "../../constants";
import { formatDate, fetchApplications } from "../../utils/dashboardUtils";


class AdminDashboard extends Component {
  state = {
    applications: []
  };

  componentDidMount() {
    document.title = "Administrator Dashboard | Finance Plus";
    this.fetchApplications();
  }

  componentWillUnmount() {
    clearErrorStore();
  };

  /**
   * Fetches all applications from the server
   */
  fetchApplications = async () => {
    try {
      this.props.setLoading(true);
      const applications = await fetchApplications('/admin/mortgage/all-applications');
      this.setState({ applications });
    } catch (error) {}
  };
  


  render() {
    const { history: { push } } = this.props;
    return (
      <div className='container-fluid px-0'>
        <CircularLoader isLoading={this.props.isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='Admin' />

        <div className='fp-mortgage-content-wrapper'>
          <div className='fp-mortgage-breadcrumb with-button'>
            <div className='fp-mortgage-breadcrumb-right'>
              <h4>{ formatDate(new Date(), 'full') }</h4>
            </div>
            <div className='fp-mortgage-breadcrumb-left'>
              <div className='fp-mortgage-breadcrumb-open-applications'>
                <span>6,750</span>
                <h4>Open applications</h4>
              </div>
              <div className='fp-mortgage-breadcrumb-approved-applications'>
                <span>500</span>
                <h4>Loans approved</h4>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='fp-dashboard-section'>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='fp-admin-analytic-box'></div>
                </div>

                <div className='col-sm-4'>
                  <div className='fp-admin-analytic-box'></div>
                </div>

                <div className='col-sm-4'>
                  <div className='fp-admin-analytic-box'></div>
                </div>
              </div>
            </div>

            <div className='fp-dashboard-section'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='fp-mortgage-application-title-wrapper'>
                    <h2>Recent Transactions</h2>
                    <div className='fp-mortgage-application-menu-tabs'>
                      <span className='fp-active-menu-tabs'>
                        Ordinary Mortgage
                      </span>
                      <span className=''>NHF Mortgage</span>
                      <div className='d-flex'>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='fp-mortgage-application-contents'>
                        <div className='fp-user-mortgage-applications-wrapper'>
                          <AdminDashboardApplicationList
                            { ...this.state }
                            goToRoute={ push }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setErrors(errors) {
      dispatch(setErrors(errors));
    },
    setLoading(isLoading) {
      dispatch(setLoading(isLoading));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboard);
