import React, {Component} from "react";
import {connect} from "react-redux";

import NhalaLandingPage from "./NhalaLandingPage";
import PersonalInfoFormStepOne from "./PersonalInfoFormStepOne";
import EmploymentForm from "./EmploymentForm";
import OtherInfoForm from "./OtherInfoForm";
import FinancialInfoStepFormOne from "./FinancialInfoStepFormOne";
import LoanInfoStepForm from "./LoanInfoStepForm";
import "./MortgageApplication.css";

import Header from "../Header";
import "./MortgageApplication.css";
import "../Buttons/Buttons.css";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import BrowserStorage from "../../utils/browserStorageUtils";
import ApplicationPreview from "./ApplicationPreview";
import { STORE_DATA, BASE_URL } from "../../constants";
import Axios from "axios";
import { clearErrorStore } from "../../utils/errorUtils";
import setEditApplicationData from "../../store/actions/setEditApplicationData";
import ErrorToaster from "../ErrorToaster";
import http from "../../config/axios.config";
import { fetchExistingApplication } from "../../utils/dashboardUtils";
import { handleAuthSuccessServerCall } from "../../utils/authFormUtils";


class MortgageApplicationUnprotected extends Component {
  state = {
    app_ref: '',
    activeComponentIndex: 0,
    type: "",
    firstname: "",
    lastname: "",
    middlename: "",
    email: "",
    phone: "",
    dob: '',
    place_of_birth: "",
    sex: "",
    nationality: "",
    highest_education: "",
    state_of_origin: "",
    address: "",
    profession: "",
    employer_name: "",
    employer_address: "",
    employer_phone: "",
    employer_email: "",
    employment_present_position: "",
    work_experience: "",
    year_to_retirement: "",
    other_employments: [],
    outstanding_loans: [],
    // have_user_assets: "",
    // user_assets: [],
    // bank_accounts: [],
    current_apartment_status: "owned",
    annual_rent_value: "",
    marital_status: "",
    spouse_name: "",
    spouse_address: "",
    spouse_employer: "",
    spouse_work_experience: "",
    spouse_profession: "",
    spouse_annual_income: "",
    children: [],
    have_children: "",
    next_of_kin_name: "",
    next_of_kin_relationship: "",
    next_of_kin_age: "",
    next_of_kin_address: "",
    total_annual_pay: '',
    monthly_gross_pay: "",
    monthly_net_pay: '',
    monthly_expenses: "",
    other_source_of_income: [],
    have_existing_obligation: "",
    down_payment: '',
    agreed_to_terms: "",
    have_employer_guarantor: "",
    loan_amount: '',
    loan_tenure: '',
    banks: [],
    employment_is_confirmed: '',
    mother_middle_name: '',
    means_of_identification: '',
    id_number: '',
    id_issue_date: '',
    id_expire_date: '',
    idCards: [],
    property_title: '',
    property_value: '',
    property_address: '',
    property_description: '',
    employer_nhf_registration_number: '',
    password: '',
    gotToPreview: false
  };
  
  async componentDidMount() {
    document.title = "Mortgage Application | Finance Plus";
    const {
      match: { params: { slug } }, editApplicationData, setEditApplicationData
    } = this.props;

    window.addEventListener('beforeunload', () => setEditApplicationData({}));

    if (slug) {
      if (JSON.stringify(editApplicationData) !== '{}') await this.setState({ ...editApplicationData });
      else {
        const application = await fetchExistingApplication(slug, setEditApplicationData);
        this.setState({ ...application });
      }
    } else {
      const type = this.props.location.pathname.split("/")[1];
      const queryMapper = {};
      let data = {};
      // expects dob, monthly_net_pay, loan_amount, down_payment, property_value
      decodeURIComponent(this.props.location.search.slice(1)).split('&').forEach(el => {
        if (el) {
          const [key, value] = el.split('=')
          queryMapper[key] = value;
        }
      })
      data = queryMapper;
      const userData = BrowserStorage.getUserData();
      if (Object.values(userData).join('')) {
        const {
          phonenumber: phone,
          firstname,
          lastname,
          email
        } = userData;
        data = { ...data, phone, firstname, lastname, email, type };
      }
      console.log(data);
      this.setState(data);
    }

    try {
      const res = await Promise.all([
        Axios.get(`${BASE_URL}/all-banks`), Axios.get(`${BASE_URL}/all-idcards`)
      ]);
      const [{ data: { data: banks } }, { data: { data: idCards } }] = res;
      this.setState({ banks, idCards });
    } catch (error) {}
  };

  componentWillUnmount() {
    clearErrorStore();
    this.props.setEditApplicationData({});
  }

  /**
   * Sets the state of the component, to be passed as a prop to child components
   * to allow them manipulate the parent's state
   * @param {object} pairs
   */
  setStateValue = pairs => this.setState({...pairs});

  /**
   * Updates the active component index to go to the next component
   */
  goToNextChildComponent = () =>
    this.setState({
      activeComponentIndex: this.state.activeComponentIndex + 1
    });

  /**
   * Updates the active component index to go to the previous component
   */
  goToPreviousChildComponent = () =>
    this.setState({
      activeComponentIndex: this.state.activeComponentIndex - 1
    });

  goToPreviewComponent = () => this.setState({activeComponentIndex: 6});

  getState = () => {
    const clone = {...this.state};
    delete clone.activeComponentIndex;
    return clone;
  };

  postStepApplication = async (
    component, values, step, is_completed, nhf_registration_number, doAfterSuccess
    ) => {
      values.is_completed = is_completed ? 1 : 0;
      if (nhf_registration_number) values.nhf_registration_number = nhf_registration_number;
      try {
        await this.postApplicationData(component, values, step);
        if (doAfterSuccess) doAfterSuccess();
        else {
          if (this.state.gotToPreview) return this.goToPreviewComponent();
          this.goToNextChildComponent();
        }
      } catch (error) {}
  };

  postApplicationData = async (component, data, step = 1) => {
    const { app_ref: appRef } = data;
    const url = `/mortgage/application/saved${appRef ? `/${appRef}/${step}` : ''}`;
    const { data: { data: serverRes } } = await http.post(url, data);
    const { app_ref } = serverRes.application || serverRes;
    const { token, user } = serverRes;
    console.log(serverRes, user, token)
    if (!appRef) {
      if (user && token) this.props.handleAuthSuccessServerCall({ data: { token, user } }, this.props.history);
      await component.setState({ app_ref });
    }
  };

  /**
   * Retrieves the active child component
   * @returns {object} React component
   */
  getActiveComponent = () => {
    const {
      app_ref,
      firstname, //
      lastname, //
      middlename, //
      email, //
      phone, //
      dob, //
      type,
      place_of_birth, //
      sex, //
      nationality, //
      highest_education, //
      address, //
      state_of_origin, //
      profession, //
      employer_name, //
      employer_address, //
      employer_phone, //
      employer_email, //
      employment_present_position, //
      work_experience, //
      year_to_retirement, //
      other_employments,
      current_apartment_status, //
      annual_rent_value, //
      marital_status, //
      spouse_name, //
      spouse_address,
      spouse_employer, //
      spouse_work_experience,
      spouse_profession, //
      spouse_annual_income, //
      children, //
      have_children,
      next_of_kin_name, //
      next_of_kin_relationship, //
      next_of_kin_age,
      next_of_kin_address, //
      total_annual_pay, //
      monthly_gross_pay, //
      monthly_net_pay, //
      monthly_expenses, //
      other_source_of_income, //
      have_existing_obligation, //
      outstanding_loans,
      // have_user_assets,
      // user_assets,
      // bank_accounts,
      down_payment,
      agreed_to_terms, //
      have_employer_guarantor, //
      employment_is_confirmed,
      loan_amount,
      loan_tenure,
      gotToPreview,
      mother_middle_name,
      means_of_identification,
      id_number,
      banks,
      idCards,
      id_issue_date,
      id_expire_date,
      property_title,
      property_value,
      property_address,
      property_description,
      employer_nhf_registration_number,
      password
    } = this.state;
    const {
      isLoading,
      isAuthenticated,
      additional_income,
      other_obligations,
      setEditApplicationData,
      nhf_registration_number
    } = this.props;

    const activeComponentDetails = [
      {component: NhalaLandingPage, props: { isLoading, app_ref }},
      {
        component: PersonalInfoFormStepOne,
        props: {
          type,
          app_ref,
          password,
          isAuthenticated,
          nhf_registration_number,
          firstname, //
          lastname, //
          middlename, //
          loan_tenure,
          email, //
          phone, //
          dob, //
          place_of_birth, //
          sex, //
          nationality, //
          highest_education, //
          address, //
          state_of_origin, //
          profession, //
          loan_amount,
          down_payment,
          monthly_net_pay,
          monthly_gross_pay,
          mother_middle_name,
          means_of_identification,
          id_number,
          id_issue_date,
          id_expire_date,
          property_value,
          property_title,
          property_address,
          idCards,
          gotToPreview
        }
      },
      {
        component: EmploymentForm,
        props: {
          dob,
          type,
          app_ref,
          firstname,
          employer_name, //
          employer_phone, //
          employer_email, //
          employer_address, //
          employment_present_position, //
          work_experience, //
          year_to_retirement, //
          other_employments,
          employment_is_confirmed,
          employer_nhf_registration_number,
          gotToPreview
        }
      },
      {
        component: OtherInfoForm,
        props: {
          app_ref,
          current_apartment_status, //
          annual_rent_value, //
          marital_status, //
          spouse_name, //
          spouse_address,
          spouse_employer, //
          spouse_work_experience, //
          spouse_profession, //
          spouse_annual_income, //
          children, //
          have_children,
          next_of_kin_name, //
          next_of_kin_relationship, //
          next_of_kin_age,
          next_of_kin_address, //
          gotToPreview
        }
      },
      {
        component: FinancialInfoStepFormOne,
        props: {
          banks,
          app_ref,
          firstname,
          // have_user_assets,
          // user_assets,
          // bank_accounts,
          monthly_net_pay, //
          total_annual_pay, //
          monthly_expenses, //
          additional_income,
          monthly_gross_pay, //
          outstanding_loans,
          other_obligations,
          other_source_of_income, //
          have_existing_obligation, //
          gotToPreview
        }
      },
      {
        component: LoanInfoStepForm,
        props: {
          app_ref,
          down_payment,
          agreed_to_terms,
          firstname,
          // have_employer_guarantor,
          loan_amount,
          property_value,
          property_title,
          property_address,
          property_description,
          gotToPreview
        }
      },
      {
        component: ApplicationPreview,
        props: {
          data: {...this.state},
          submitData: {
            app_ref,
            down_payment,
            agreed_to_terms,
            have_employer_guarantor,
            loan_amount
          },
          setEditApplicationData,
          nhf_registration_number,
          goToRoute: this.props.history.replace
        }
      }
    ][this.state.activeComponentIndex];
    const ActiveComponent = activeComponentDetails.component;
    const props = activeComponentDetails.props;

    return (
      <ActiveComponent
        setParentState={this.setStateValue}
        componentIndex={this.state.activeComponentIndex}
        goToNextComponent={this.goToNextChildComponent}
        goToPreviousComponent={this.goToPreviousChildComponent}
        goToPreviewComponent={this.goToPreviewComponent}
        postStepApplication={this.postStepApplication}
        app_ref
        {...props}
      />
    );
  };

  render() {
    return (
      <div className='container-fluid px-0'>
        <Header history={this.props.history} type='others' avoidAuthRedirect={true} />
        <section id='fp-mortgage-application'>
          <ErrorToaster />
          <div className='container-fluid'>{this.getActiveComponent()}</div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    isAuthenticated: state.isAuthenticated,
    dob: state.affordabilityData.dob,
    loan_amount: state.affordabilityData.loan_amount,
    loan_tenure: state.affordabilityData.tenure,
    other_obligations: state.affordabilityData.other_obligations,
    down_payment: state.mortgageApplicationData.down_payment,
    property_value: state.mortgageApplicationData.property_value,
    property_title: state.mortgageApplicationData.property_title,
    property_address: state.mortgageApplicationData.property_address,
    monthly_income: state.earningData.monthly_income,
    additional_income: state.earningData.additional_income,
    nhf_registration_number: state.nhf_reg_number,
    editApplicationData: state.editApplicationData,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    },
    setEditApplicationData(editApplicationData) {
      dispatch(setEditApplicationData(editApplicationData));
    },
    handleAuthSuccessServerCall(res, history) {
      handleAuthSuccessServerCall(dispatch, res, history, true);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MortgageApplicationUnprotected);
