import React, { Component, createRef } from "react";
import { handleChange, validateForm } from "../../utils/authFormUtils";
import { handleSingleFieldBlur } from "../../utils/eligibilityFormUtils";
import { clearErrorStore } from "../../utils/errorUtils";
import ErrorToaster from "../ErrorToaster";
import http from "../../config/axios.config";

export default class TrackForm extends Component {
  state = { ref_number: "" };

  constructor(props) {
    super(props);
    this.modalCloseButton = createRef();
  };

  componentWillUnmount() { clearErrorStore(); };

  handleChange = event => handleChange(event, this);

  handleBlur = ({ target }) => handleSingleFieldBlur(target, this);

  openResultModal = () => {
    this.modalCloseButton.current.click();
    this.props.trackResultModalLink.current.click();
  };

  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    const { target } = event;
    if (invalidInput) return invalidInput.focus();
    try {
      const {
        data: { data: applicationTrackResult }
      } = await http.post('/mortgage/checkstatus', { ...this.state });
      this.props.parentComponent.setState({ applicationTrackResult });
      this.openResultModal(target);      
    } catch (error) {}
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className='modal-content'>
        <ErrorToaster />
        <div className='fp-check-mortgage-application-status'>
          <div className='fp-login-page-logo'></div>
          <h2>Mortgage Application Tracker</h2>
          <a
            href='/'
            role='button'
            ref={this.modalCloseButton}
            className='close fp-login-modal-close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span className='text-white' aria-hidden='true'>
              ×
            </span>
          </a>
        </div>

        <div className='modal-body fp-check-mortgage-application-status-body-wrapper'>
          <div className='fp-check-mortgage-application-status-body-wrapper-bg'></div>
          <p>Track your application progress with our helpful online tool.</p>
          <form
            className='fp-login-form-wrapper'
            noValidate
            onSubmit={this.handleSubmit}
          >
            <div className='form-group row'>
              <div className='col-md-10 offset-md-1'>
                <label htmlFor='Reference Number'>Reference Number</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Reference Number'
                  data-state-name='ref_number'
                  pattern='^(?:MORT|NHF|MOR)-[A-Z]+\d{6}$'
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  required
                />
                <div className='invalid-feedback'>Invalid Reference Number</div>
              </div>

              <div className='col-md-10 offset-md-1 mt-4'>
                <button
                  className='fp-modal-result-button'
                  type='submit'
                  disabled={isLoading}
                >
                  Track Status
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
