import React, {Component} from "react";
import * as Icon from "react-feather";

import MultiStepFormButtons from "../MultiStepFormButtons";
import {handleCurrencyInputChange} from "../../utils/eligibilityFormUtils";
import {formatCurrencyInput, clearCommas} from "../../utils/currencyUtils";
import {currencyRegexString, yobRegexString} from "../../utils/regexUtils";
import {
  currencyFieldErrorMessage,
  requiredFieldErrorMessage
} from "../../utils/validationMessageUtils";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import {getYearFromDOB} from "../../utils/formatDate";
import DatePicker from "../DatePicker";

const moneyFields = [
  "monthly_income",
  "additional_income",
  "property_value",
  "loan_amount",
  "down_payment"
];

class LandingStepFormOne extends Component {
  state = {
    dob: this.props.dob,
    tenure: this.props.tenure,
    monthly_income: formatCurrencyInput(this.props.monthly_income),
    additional_income: formatCurrencyInput(this.props.additional_income)
  };

  componentWillUnmount() {
    const { affordabilityData, setAffordabilityData, setParentState } = this.props;
    const {dob, tenure, monthly_income, additional_income} = this.state;
    const data = {
      dob,
      tenure,
      monthly_income: clearCommas(monthly_income),
      additional_income: clearCommas(additional_income)
    };
    setAffordabilityData({ ...affordabilityData, dob })
    setParentState(data);
  }

  /**
   * Handles change event on the input field
   * @param {DOMEvent} event
   */
  handleCurrencyInputChange = event => handleCurrencyInputChange(event, this);

  handleChange = event => handleChange(event, this);

  /**
   * Handles change event on the tenure range input
   * @param {object} event
   */
  handleTenureChange = ({target}) => {
    this.setState({tenure: target.value});
  };

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    await this.props.setParentState({...this.state});
    const data = this.props.getParentState();
    delete data.serverResponse;
    moneyFields.forEach(field => {
      data[field] = clearCommas(data[field]);
    });
    data.yob = getYearFromDOB(data.dob);
    await this.props.fetchEligibilityData(
      data,
      this.props.componentIndex,
      this.props.setParentState
    );
  };

  /**
   * Handles click event on the back button
   */
  handleBackClick = () =>
    this.props.setParentState({
      activeComponentIndex: this.props.componentIndex - 1
    });

  handleDateChange = dob => this.setState({dob});

  componentDidUpdate(_, prevState) {
    const {dob, tenure: tenureString} = this.state;
    if (dob && prevState.dob !== dob) {
      const yob = parseInt(getYearFromDOB(dob), 10);
      const maxTenure = 60 - (new Date().getFullYear() - yob);
      const tenure = parseInt(tenureString, 10);
      const newTenure =
        maxTenure < 1
          ? 0
          : tenure >= maxTenure
          ? maxTenure
          : tenure < 1
          ? 1
          : tenure;
      this.setState({tenure: newTenure});
    }
  }

  render() {
    const {monthly_income} = this.state;
    const monthlyIncomeSatisfiesMinimumWage =
      parseFloat(clearCommas(monthly_income)) >= 30000;
    const {dob} = this.state;
    let maxTenure = 30,
      minTenure = 1;
    if (dob) {
      const yob = parseInt(getYearFromDOB(dob), 10);
      maxTenure = 60 - (new Date().getFullYear() - yob);
      if (!(maxTenure >= 1)) {
        maxTenure = 0;
        minTenure = 0;
      } else if (maxTenure >= 30) {
        maxTenure = 30;
      }
    }

    return (
      <div className='fp-eligibility-test-application-form-wrapper other'>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='row'>
            <div className='col-md-10 offset-md-1 mt-2'>
              <div className='form-group p-0'>
                <span className='fp-form-numbering'>Q2/7</span>
                <label>What is your Gross monthly income?</label>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text px-4 bg-white'>₦</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={currencyRegexString}
                    maxLength='19'
                    data-state-name='monthly_income'
                    value={this.state.monthly_income}
                    onChange={this.handleCurrencyInputChange}
                    required
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text px-lg-5 bg-theme-button-group'>
                      Monthly
                    </span>
                  </div>
                  <div className='invalid-feedback invalid'>
                    {currencyFieldErrorMessage}
                  </div>
                </div>
                {!monthlyIncomeSatisfiesMinimumWage ? (
                  <div>
                    <input
                      type='text'
                      className='form-control input-1 invalid'
                      pattern={yobRegexString}
                      value={"bola"}
                      style={{display: "none"}}
                      onChange={() => {}}
                    />
                    <div className='invalid-feedback'>
                      Income must satisfy the minimum wage (₦ 30,000)
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className='form-group p-0'>
                <span className='fp-form-numbering'>Q3/7</span>
                <label>How much additional income do you earn monthly?</label>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text px-4 bg-white'>₦</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={`${currencyRegexString}|0`}
                    maxLength='19'
                    data-state-name='additional_income'
                    value={this.state.additional_income}
                    onChange={this.handleCurrencyInputChange}
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text px-lg-5 bg-theme-button-group'>
                      Monthly
                    </span>
                  </div>
                  <div className='invalid-feedback invalid'>
                    {currencyFieldErrorMessage}
                  </div>
                </div>
              </div>

              <div className='form-group p-0'>
                <span className='fp-form-numbering'>Q5/7</span>
                <label>Date of Birth</label>
                {(() => {
                  const getAppendants = toggleDatePickerDisplay => [
                    <div key={0} className='input-group-append'>
                      <span className='input-group-text px-3 bg-theme-button-group'>
                        <Icon.Calendar
                          color='#00b1ab'
                          onClick={toggleDatePickerDisplay}
                        />
                      </span>
                    </div>,
                    <div key={1} className='invalid-feedback'>
                      {requiredFieldErrorMessage}
                    </div>,
                    maxTenure ? (
                      ""
                    ) : (
                      <div key={2} className='too-old-option'>
                        <p>
                          You fall into a special mortgage category,
                          <a
                            className='fp-too-old-contact-modal text-danger'
                            href='#modalContactUS'
                            data-toggle='modal'
                          >
                            {" "}
                            Click here to continue
                          </a>
                        </p>
                      </div>
                    )
                  ];
                  const thisYear = new Date().getFullYear();
                  const minimumYear = thisYear - 59;
                  const maximumYear = thisYear - 10;

                  return (
                    <DatePicker
                      {...{
                        value: dob,
                        minimumYear,
                        maximumYear,
                        getAppendants,
                        requireValue: true,
                        format: "DD/MM/YYYY",
                        inputClassName: "form-control",
                        parentClassName: "input-group",
                        handleDateChange: this.handleDateChange
                      }}
                    />
                  );
                })()}
              </div>

              <div className='form-group p-0'>
                <span className='fp-form-numbering'>Q4/7</span>
                <label>How long do you want this loan for?</label>
                <div className='range'>
                  <input
                    data-state-name='tenure'
                    type='range'
                    min={minTenure}
                    max={maxTenure}
                    value={this.state.tenure}
                    onChange={this.handleTenureChange}
                  />
                  <div className='d-flex justify-content-between mt-1'>
                    <span>{minTenure}</span>
                    <span>{maxTenure}</span>
                  </div>
                  <div className='range-output'>
                    <output
                      className='output'
                      name='output'
                      htmlFor='range'
                      style={{
                        transform:
                          "translate(" +
                          (this.state.tenure - 5) * 18.316 +
                          "px, 20px)"
                      }}
                    >
                      {this.state.tenure} years
                    </output>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MultiStepFormButtons
            type='Eligibility-Test'
            handleBackClick={this.handleBackClick}
          />
        </form>
      </div>
    );
  }
}

export default LandingStepFormOne;
