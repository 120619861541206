import React, {Component} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Header from "../Header";
import "./NhfEligibilityPage.css";

import LandingStepForm from "./LandingStepForm";
import LandingStepFormOne from "./LandingStepFormOne";
import EligibilitySuccess from "./EligibilitySuccess";
import EligibilityErrors from "./EligibilityErrors";
import SaveResultSidebar from "../SaveResultSidebar";
import BrowserStorage from "../../utils/browserStorageUtils";
import {redirectUserWhenNotLoggedIn} from "../../utils/authFormUtils";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import setErrors from "../../store/actions/setErrors";
import setLoading from "../../store/actions/setLoading";
import setNhfRegNo from "../../store/actions/setNhfRegNo";
import setMortgageApplicationData from "../../store/actions/setMortgageApplicationData";
import setAffordabilityData from "../../store/actions/setAffordabilityData";
import {clearErrorStore} from "../../utils/errorUtils";

class NhfEligibilityPage extends Component {
  state = {
    activeComponentIndex: 0,
    nhf_reg_number: "",
    loan_amount: this.props.affordabilityData.loan_amount,
    have_six_month_contribution: "",
    nhf_is_active: "",
    property_have_title: "",
    dob: this.props.affordabilityData.dob,
    have_tax_clearance: "",
    property_value: this.props.mortgageApplicationData.property_value,
    down_payment: this.props.mortgageApplicationData.down_payment,
    errors: []
  };

  componentDidMount() {
    let { history, setIsAuthenticated, affordabilityData: { maxLoanableAmount } } = this.props;
    const token = BrowserStorage.getUserToken();
    if (!token) return redirectUserWhenNotLoggedIn(history, setIsAuthenticated);
    maxLoanableAmount = parseFloat(maxLoanableAmount);
    if (!maxLoanableAmount || isNaN(maxLoanableAmount)) history.replace('/nhf/affordability');
  }

  componentWillUnmount() {
    clearErrorStore();
  }

  /**
   * Sets component's state, can be passed into children components
   * to alter their parent's state
   * @param {object} pairs
   */
  setStateValues = async pairs => await this.setState(pairs);

  getData = () => {
    const data = {...this.state};
    delete data.activeComponentIndex;
    return data;
  };

  /**
   * Retrieves the active component and its props based on the active index
   * @returns {object} component details
   */
  getActiveComponentDetails = () => {
    const {
      nhf_reg_number,
      activeComponentIndex,
      have_six_month_contribution,
      nhf_is_active,
      property_have_title,
      dob,
      have_tax_clearance,
      loan_amount,
      property_value,
      down_payment
    } = this.state;
    const {
      history: {push: goToRoute},
      setNhfRegNo,
      mortgageApplicationData,
      setMortgageApplicationData,
      setAffordabilityData,
      affordabilityData
    } = this.props;

    return [
      {
        component: LandingStepForm,
        props: {
          loan_amount,
          down_payment,
          nhf_reg_number,
          property_value,
          affordabilityData,
          setAffordabilityData,
          mortgageApplicationData,
          setMortgageApplicationData
        }
      },
      {
        component: LandingStepFormOne,
        props: {
          have_six_month_contribution,
          nhf_is_active,
          property_have_title,
          dob,
          have_tax_clearance,
          getParentState: this.getData,
          setLoading: this.props.setLoading,
          setErrors: (errors) => this.setState({ errors })
        }
      },
      {
        component: EligibilitySuccess,
        props: {
          serverResponse: {loan_amount},
          isNHF: true,
          goToRoute,
          setNhfRegNo,
          nhf_reg_number,
          getParentState: this.getData,
          affordabilityData,
          mortgageApplicationData,
          setAffordabilityData,
          setMortgageApplicationData
        }
      },
      {component: EligibilityErrors, props: {errors: this.state.errors}}
    ][activeComponentIndex];
  };

  render() {
    const {
      component: ActiveComponent,
      props
    } = this.getActiveComponentDetails();
    const {affordabilityData} = this.props;
    const {property_value} = this.state;

    return (
      <div className='container-fluid px-0'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            NHF Eligibility Test - Finance Plus | Real Estate, NHF, Mortgages
            &amp; Home Loans
          </title>
          <link
            rel='canonical'
            href='http://financeplus.ng/nhf/affordability'
          />
        </Helmet>{" "}
        <Header
          history={this.props.history}
          type='others'
          loginButtonRef={this.loginButtonRef}
        />
        <section id='fp-eligibility-test-application'>
          <div className='container-fluid'>
            <div className='row p-0'>
              <div className='col-md-7 fp-eligibility-test-application-form'>
                <ActiveComponent
                  setParentState={this.setStateValues}
                  componentIndex={this.state.activeComponentIndex}
                  {...props}
                />
              </div>
              <SaveResultSidebar {...{affordabilityData, property_value}} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mortgageApplicationData: state.mortgageApplicationData,
    affordabilityData: state.affordabilityData,
    errors: state.errors,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading(isLoading) {
      dispatch(setLoading(isLoading));
    },
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    },
    setErrors(errors) {
      dispatch(setErrors(errors));
    },
    setNhfRegNo(nhf_reg_number) {
      dispatch(setNhfRegNo(nhf_reg_number));
    },
    setAffordabilityData(affordabilityData) {
      dispatch(setAffordabilityData(affordabilityData));
    },
    setMortgageApplicationData(mortgageApplicationData) {
      dispatch(setMortgageApplicationData(mortgageApplicationData));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NhfEligibilityPage);
