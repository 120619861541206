import React from 'react';
import { Edit2 } from 'react-feather';
// import { toTitleCase } from '../../utils/dashboardUtils';
import { formatCurrencyInput } from '../../utils/currencyUtils';


const LoanInfoPreviewBox = ({ data, goToComponent }) => {
const {
  down_payment, loan_amount, property_value, /*have_employer_guarantor*/ property_title, property_address
} = data;
  return (
    <div
      data-label='Loan Information'
      className='fp-mortgage-landing-preview-card mt40'
    >
      <div className='row'>
        <div className='col-md-12 no-print'>
          <span
            className='fp-mortgage-application-action-btn float-right d-flex'
            onClick={ () => goToComponent(5) }
          >
            <Edit2
              color='#fff'
              size='18px'
              className='mr-1'
            />
            Edit
          </span>
        </div>
        <div className='col-md-6'>
          <span>Proposed Equity Contribution</span>
          <p>₦ { formatCurrencyInput(down_payment) }</p>
        </div>
        <div className='col-md-6'>
          <span>Loan Amount</span>
          <p>₦ { formatCurrencyInput(loan_amount) }</p>
        </div>
        {/* <div className='col-md-4'>
          <span>Employer's guarantee</span>
          <p>{ toTitleCase(have_employer_guarantor) }</p>
        </div> */}
        <div className='col-md-4'>
          <span>Property Value</span>
          <p>{ formatCurrencyInput(property_value) }</p>
        </div>
        <div className='col-md-4'>
          <span>Property Title</span>
          <p>{ property_title }</p>
        </div>
        <div className='col-md-4'>
          <span>Property Address</span>
          <p>{ property_address }</p>
        </div>
      </div>
    </div>
  );
}
 
export default LoanInfoPreviewBox;