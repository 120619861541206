import React from 'react';
import * as Icon from "react-feather";

import { formatCurrencyInput } from '../../utils/currencyUtils';
import { formatDate } from '../../utils/dashboardUtils';



const AdminDashboardApplicationListItem = ({ application, goToRoute }) => {
  const styles = {
    LenderImage: {
      backgroundImage: `url(${application.lender.lender_logo})`,
      // backgroundSize: '120px 40px'
    }
  };

  const {
    app_ref, firstname, lastname, loan_amount, created_at, mortgage_status_name, slug
  } = application;
  return (
    <li className='table-row'>
      <div className='col col-2' data-label='Reference'>
        { app_ref }
      </div>
      <div className='col col-2' data-label='Customer'>
        {
          `${firstname ? firstname : ''} ${lastname ? lastname : ''}`
        }
      </div>
      <div
        className='col col-2 fp-lender-bg-icon'
        data-label='Lender'
        style={styles.LenderImage}

      >{application.lender.lender_logo ? '' : <span style={{fontWeight: '600'}}>N/A</span>}</div>
      <div
        className='col col-2'
        data-label='Loan Amount'
      >
        ₦ { loan_amount ? formatCurrencyInput(loan_amount) : ''}
      </div>
      <div className='col col-2' data-label='Date'>
        { formatDate(created_at, 'medium') }
      </div>
      <div className='col col-2' data-label='Status'>
        <span
          className={`badge${mortgage_status_name ? ' ' + mortgage_status_name.toLowerCase() : ''}`}
        >{ mortgage_status_name }</span>
      </div>
      <div className='col col-1' data-label='Actions'>
        <Icon.Eye
          color='#555555'
          size='23'
          onClick={ () => goToRoute(`/admin/dashboard/application/view/${slug}`) }
        />
      </div>
    </li>
  );
}
 
export default AdminDashboardApplicationListItem;