import setAffordabilityData from "../actions/setAffordabilityData";
import http from "../../config/axios.config";


/**
 * Fetches data for affordability test from the server
 * @param {object} data
 * @param {function} doAfterSuccess
 */
export default (data, componentIndex, setParentComponentState) => async (dispatch, getState) => {
    try {
      const { data: result } = await http.post('/mortgage/eligibilitytest', data);
      setParentComponentState({
        serverResponse: result.data, activeComponentIndex: componentIndex + 1
      });
      const { affordabilityData } = getState();
      dispatch(setAffordabilityData({ ...affordabilityData, tenure: data.tenure }))
    } catch (error) {}
};
