import React, {Component} from "react";

import MultiStepFormButtons from "../MultiStepFormButtons";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import {toTitleCase} from "../../utils/dashboardUtils";
import Axios from "axios";
import { BASE_URL, DEFAULT_RADIO_VALUES } from "../../constants";
import { yobRegexString, phoneRegexString } from "../../utils/regexUtils";
import { requiredFieldErrorMessage } from "../../utils/validationMessageUtils";
import { handleSingleFieldBlur } from "../../utils/eligibilityFormUtils";
import ApplicationProgressBar from "./ApplicationProgressBar";

class EmploymentForm extends Component {
  state = {
    app_ref: this.props.app_ref,
    employer_name: this.props.employer_name,
    employer_address: this.props.employer_address,
    employer_phone: this.props.employer_phone,
    employer_email: this.props.employer_email,
    employment_present_position: this.props.employment_present_position,
    work_experience: this.props.work_experience,
    year_to_retirement: this.props.year_to_retirement,
    companies: [],
    showCompanies: false,
    employment_is_confirmed: this.props.employment_is_confirmed,
    employer_nhf_registration_number: this.props
      .employer_nhf_registration_number
    // other_employments: this.props.other_employments
  };

  async componentDidMount() {
    let companies;
    try {
      const {
        data: {data}
      } = await Axios.get(`${BASE_URL}/all-companies`);
      companies = data;
    } catch (error) {
      companies = [];
    } finally {
      this.setState({companies});
    }
  }

  static getDerivedStateFromProps({dob, app_ref}, state) {
    const data = {};
    if (dob) {
      const [d, m, y] = dob.split("/");
      const year_to_retirement =
        new Date([y, m, d].join("/")).getFullYear() -
        new Date().getFullYear() +
        60;
      data.year_to_retirement = year_to_retirement;
    }
    if (app_ref) data.app_ref = app_ref;
    if (Object.keys(data).length) return data;
    return null;
  }

  getCompaniesList = () => {
    const {companies} = this.state;
    const filteredCompanies = companies.filter(({name}) => {
      let {employer_name} = this.state;
      employer_name = employer_name.toLowerCase();
      return employer_name ? name.toLowerCase().includes(employer_name) : true;
    });

    return filteredCompanies.length ? (
      <div className='datalist-wrapper'>
        <div id='companies'>
          {filteredCompanies.map(({id, name}) => (
            <div onClick={this.setEmployer} key={id}>
              {name}
            </div>
          ))}
        </div>
      </div>
    ) : (
      ""
    );
  };

  saveNewEmployerName = async () => {
    const {companies, employer_name: name} = this.state;
    const companyExists = companies.find(
      ({name: company}) => company.toLowerCase() === name.toLowerCase()
    );
    try {
      if (name && !companyExists)
        await Axios.post(`${BASE_URL}/all-companies`, {name});
      // if (other_employments.length) {
      //   other_employments.forEach(async ({ company_name }) => {
      //     if (company_name && !(
      //       companies.includes(company_name) || companies.includes(company_name.toLowerCase()))) {
      //         await Axios.post(`${BASE_URL}/all-companies`, { name: company_name });
      //       }
      //   });
      // }
    } catch (error) {}
  };

  componentWillUnmount() {
    const data = {...this.state};
    this.saveNewEmployerName();
    delete data.companies;
    delete data.showCompanies;
    this.props.setParentState(data);
  }

  /**
   * Handles change event on the form's input fields
   * @param {object} event
   */
  handleChange = event => {
    const {
      target: {
        value,
        dataset: {stateName}
      }
    } = event;
    if (stateName === "work_experience") {
      const experience = parseInt(value, 10);
      if (experience < 1) return;
    }
    handleChange(event, this);
  };

  handleBlur = ({ target }) => handleSingleFieldBlur(target, this);

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = async event => {
    const invalidInput = validateForm(event);
    // let { other_employments, work_experience } = this.state;
    // work_experience = parseInt(work_experience);
    // other_employments = [...other_employments];
    // target.classList.add("was-validated");
    if (invalidInput) return invalidInput.focus();
    // let otherExperience = 0;
    // if (other_employments.length) {
    //   otherExperience = other_employments.reduce(
    //     (acc, { years_of_experience }) => {
    //       return years_of_experience
    //         ? acc + parseInt(years_of_experience, 10)
    //         : acc;
    //     },
    //     0
    //   );

    //   const lastEmployment = { ...other_employments[other_employments.length - 1] };
    //   if (lastEmployment) {
    //     delete lastEmployment.id;
    //     if (!Object.values(lastEmployment).join('')) {
    //       other_employments.pop();
    //       await this.setState({ other_employments });
    //     }
    //   }
    // }

    // const totalExperience = otherExperience + work_experience;
    // if (totalExperience < 3) return;

    await this.props.postStepApplication(...[this, {...this.state}, 2]);
  };

  setEmployer = ({target}) => {
    const {textContent: employer_name} = target;
    this.setState({employer_name});
  };

  // addEmployment = ({ target }) => {
  //   const { other_employments } = this.state;
  //   const inputsParent = target.closest(".other-employments-wrapper")
  //     .querySelector('.row.last-child');
  //   const invalidInput = inputsParent.querySelector("input:invalid");
  //   if (invalidInput) {
  //     inputsParent.classList.add("was-validated");
  //     return invalidInput.focus;
  //   }

  //   const lastEmployment = other_employments[other_employments.length - 1];
  //   if (!lastEmployment || !Object.values(lastEmployment).join("").trim()) return;
  //   this.setState({ other_employments: [...other_employments, { id: uuid() }] });
  // };

  render() {
    const {firstname} = this.props;
    const {showCompanies, employment_is_confirmed} = this.state;
    const isConfirmed = employment_is_confirmed === "yes";
    const isNhf = this.props.type === "nhf";
    // let otherExperience = 0;
    // if (other_employments.length) {
    //   otherExperience = other_employments.reduce(
    //     (acc, { years_of_experience }) => {
    //       return years_of_experience
    //         ? acc + parseInt(years_of_experience, 10)
    //         : acc;
    //     },
    //     0
    //   );
    // }
    // const totalExperience = otherExperience + parseInt(work_experience, 10);

    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <div className='row fp-mortgage-application'>
          <div className='col-md-10 offset-md-1'>
            <div className='fp-mortgage-landing-page-with-nhanla'>
              <ApplicationProgressBar
                step={this.props.componentIndex}
                parentClassName='col-md-10 offset-md-1'
              />
              <div className='row fp-personal-info-form'>
                <div className='col-md-10 offset-md-1'>
                  <span>Ordinary Mortgage | Personal Info</span>
                  <h2>
                    Nice to meet you {toTitleCase(firstname)}! Moving on, please
                    tell me about your current employment situation.
                  </h2>
                  <div className='form-group row'>
                    {/* <div className='col-md-6'>
                      <label>Profession</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Profession'
                        data-state-name='profession'
                        value={this.state.profession}
                        onChange={this.handleChange}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                    </div> */}
                    <div className={`col-md-${isNhf ? 4 : 6} employer-group`}>
                      <label>Company Name</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Employer Name'
                        data-state-name='employer_name'
                        value={this.state.employer_name}
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ showCompanies: true })}
                        onBlur={({ target }) => {
                          const timeout = setTimeout(() => {
                            this.handleBlur({ target });
                            this.setState({ showCompanies: false });
                            clearTimeout(timeout);
                          }, 180);
                        }}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                      {showCompanies ? this.getCompaniesList() : ""}
                    </div>
                    <div className={`col-md-${isNhf ? 4 : 6}`}>
                      <label>Company Email Address</label>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Email Address'
                        data-state-name='employer_email'
                        pattern='^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$'
                        value={this.state.employer_email}
                        onChange={this.handleChange}
                        required
                      />
                      <div className='invalid-feedback'>
                        Invalid or empty email address
                      </div>
                    </div>
                    {
                      isNhf ? (
                        <div className="col-md-4">
                          <label>Employer NHF Registration Number</label>
                          <input
                            type="text"
                            className='form-control'
                            name='employer_nhf_registration_number'
                            placeholder='Employer NHF reg. number'
                            data-state-name='employer_nhf_registration_number'
                            value={this.state.employer_nhf_registration_number}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            required
                          />
                          <div className="invalid-feedback">{requiredFieldErrorMessage}</div>
                        </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>
                        Address of Employer (If less than three years, give name
                        of previous employer)
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Address'
                        data-state-name='employer_address'
                        value={this.state.employer_address}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Employer Phone No</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Phone No'
                        data-state-name='employer_phone'
                        pattern={phoneRegexString}
                        value={this.state.employer_phone}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>
                        Phone number must be a sequence of digits
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>Present Position</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Present Position'
                        data-state-name='employment_present_position'
                        value={this.state.employment_present_position}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        required
                      />
                      <div className='invalid-feedback'>Value is required</div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Number of Years to Retirement</label>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Years to Retirement'
                        data-state-name='year_to_retirement'
                        disabled
                        value={this.state.year_to_retirement}
                        onChange={this.handleChange}
                        required
                      />
                      <div className='invalid-feedback'>
                        Invalid or empty value
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <label>Years at Current Employment</label>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Years Employed'
                        data-state-name='work_experience'
                        value={this.state.work_experience}
                        onChange={this.handleChange}
                        required
                      />
                      <div className='invalid-feedback'>
                        Invalid or empty value
                      </div>
                    </div>
                  </div>

                  {/* {(() => {
                    let { work_experience } = this.state;
                    work_experience = work_experience
                      ? parseInt(work_experience, 10)
                      : 0;
                    if (!work_experience || work_experience >= 3) return;

                    return (
                      <div className='other-employments-wrapper'>
                        <p className='mt-5 ml-0'>
                          At least 3 years of experience is required, please
                          enter the details of previous employments
                        </p>
                        <button
                            type='button'
                            className='btn btn-primary float-right'
                            onClick={this.addEmployment}
                          >
                            +
                          </button>
                        <OtherEmployersList
                          {...{
                            requireFields: totalExperience < 3,
                            other_employments: other_employments.length
                              ? [...other_employments]
                              : [{ id: uuid() }],
                            setOtherEmployments: employments => this.setState({ other_employments: employments }),
                            companies
                          }}
                        />
                      </div>
                    );
                  })()} */}

                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Has your employment been confirmed?</label>
                      <div className='fp-home-type-checkbox'>
                        <div className='col-md-6'>
                          <input
                            id='employment_is_confirmed_yes'
                            type='radio'
                            name='employment_is_confirmed'
                            value='yes'
                            data-state-name='employment_is_confirmed'
                            checked={isConfirmed}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='employment_is_confirmed_yes'>
                            Yes
                          </label>
                        </div>

                        <div className='col-md-6'>
                          <input
                            id='employment_is_confirmed_no'
                            type='radio'
                            name='employment_is_confirmed'
                            value='no'
                            data-state-name='employment_is_confirmed'
                            checked={
                              this.state.employment_is_confirmed === "no"
                            }
                            onChange={this.handleChange}
                          />
                          <label htmlFor='employment_is_confirmed_no'>No</label>
                        </div>
                      </div>
                      {!DEFAULT_RADIO_VALUES.includes(
                        this.state.employment_is_confirmed
                      ) ? (
                        <div>
                          <input
                            type='text'
                            className='form-control'
                            pattern={yobRegexString}
                            value={"bola"}
                            style={{display: "none"}}
                            onChange={() => {}}
                          />
                          <div className='invalid-feedback'>
                            {requiredFieldErrorMessage}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <MultiStepFormButtons
              type='Mortgage-Application'
              handleBackClick={this.props.goToPreviousComponent}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default EmploymentForm;
