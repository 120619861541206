import React from 'react';
import * as Icon from 'react-feather';
import PreviewOtherIncomeSourcesTableRow from './PreviewOtherIncomeSourcesTableRow';
import { formatCurrencyInput } from '../../utils/currencyUtils';
import { toTitleCase } from '../../utils/dashboardUtils';
import PreviewBankAccountsTableRow from './PreviewBankAccountTableRow';
import PreviewOutstandingLoansTableRow from './PreviewOutstandingLoansTableRow';
import PreviewAssetsTableRow from './PreviewAssetsTableRow';


const FinancialInfoPreviewBox = ({ data, goToComponent }) => {
  const {
    total_annual_pay, monthly_gross_pay, monthly_net_pay, other_source_of_income,
    monthly_expenses, have_existing_obligation, user_assets, bank_accounts, outstanding_loans
  } = data;
  console.log(other_source_of_income);

  let otherIncomeSourceTotal = 0;
  const otherSourceOfIncomeRows = other_source_of_income.map((source) => {
    otherIncomeSourceTotal += parseInt(source.amount, 10);
    return <PreviewOtherIncomeSourcesTableRow source={ source } key={ source.id } />
  });
  // const bankAccountRows = bank_accounts.map((account) => (
  //   <PreviewBankAccountsTableRow {...{ account }} key={ account.id } />
  // ));
  const outstandingLoansRows = outstanding_loans.map((loan) => (
    <PreviewOutstandingLoansTableRow {...{ loan }} key={ loan.id } />
  ));
  // const assetsTableRows = user_assets.map((asset) => (
  //   <PreviewAssetsTableRow {...{ asset }} key={ asset.id } />
  // ));

  return (
    <div
      data-label='Financial Information'
      className='fp-mortgage-landing-preview-card mt40'
    >
      <div className='row'>
        <div className='col-md-12 no-print'>
          <span
            className='fp-mortgage-application-action-btn float-right d-flex'
            onClick={ () => goToComponent(4) }
          >
            <Icon.Edit2
              color='#fff'
              size='18px'
              className='mr-1'
            />
            Edit
          </span>
        </div>
        <div className='col-md-6'>
          <span>
            Employment Income (<i>Total Annual Pay</i>)
          </span>
          <p>₦ { formatCurrencyInput(total_annual_pay) }</p>
        </div>
        <div className='col-md-6'>
          <span>
            Employment Income (<i>Monthly Gross Pay</i>)
          </span>
          <p>₦ { formatCurrencyInput(monthly_gross_pay) }</p>
        </div>
        <div className='col-md-6'>
          <span>
            Employment Income (<i>Monthly Net Pay</i>)
          </span>
          <p>₦ { formatCurrencyInput(monthly_net_pay) }</p>
        </div>
        <div className='col-md-6'>
          <span>Estimated Monthly Living Expenses</span>
          <p>₦ { formatCurrencyInput(monthly_expenses) }</p>
        </div>
        <div className='col-md-12'>
          <span>Other Source(s) of Income</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Source</th>
                <th>Amount/Year (N)</th>
              </tr>
            </thead>
            <tbody>
              {
                other_source_of_income.length ? otherSourceOfIncomeRows : (
                  <PreviewOtherIncomeSourcesTableRow source={{ source: '-', amount: '-' }} />
                )
              }
            </tbody>
          </table>
          <span>
            Total: ₦ { formatCurrencyInput(otherIncomeSourceTotal) }
          </span>
        </div>
        
        {/* <div className='col-md-12'>
          <span>Bank Accounts</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Bank</th>
                <th>Type</th>
                <th>Acc. Number</th>
                <th>Balance (N)</th>
              </tr>
            </thead>
            <tbody>
              { bank_accounts.length ? bankAccountRows : <PreviewBankAccountsTableRow account={{}} /> }
            </tbody>
          </table>
        </div> */}
        
        <div className='col-md-12'>
          <span>Outstanding Loans</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Lender</th>
                <th>Type</th>
                <th>Frequency</th>
                <th>Outstanding Amount (N)</th>
              </tr>
            </thead>
            <tbody>
              {
                outstanding_loans.length ? outstandingLoansRows : <PreviewOutstandingLoansTableRow loan={{}} />
              }
            </tbody>
          </table>
        </div>
        
        {/* <div className='col-md-12'>
          <span>Assets</span>
          <table className='table rtable w-100'>
            <thead>
              <tr>
                <th>Investment/Asset</th>
                <th>Estimated Value (N)</th>
                <th>Income/Year (N)</th>
              </tr>
            </thead>
            <tbody>
              {
                user_assets.length ? assetsTableRows : <PreviewAssetsTableRow asset={{}} />
              }
            </tbody>
          </table>
        </div> */}
        <div className='col-md-6'>
          <span>Outstanding loans (if any)</span>
          <p>{ toTitleCase(have_existing_obligation) }</p>
        </div>
      </div>
    </div>
  );
}
 
export default FinancialInfoPreviewBox;