import React, {Component} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Header from "../Header";
import "./EligibilityTestPage.css";
import LandingStepForm from "./LandingStepForm";
import LandingStepFormOne from "./LandingStepFormOne";
import EligibilitySuccess from "./EligibilitySuccess";
import EligibilityErrors from "./EligibilityErrors";
import SaveResultSidebar from "../SaveResultSidebar";
import fetchEligibilityData from "../../store/middlewares/fetchEligibilityData";
import setMortgageApplicationData from "../../store/actions/setMortgageApplicationData";
import setEarningData from "../../store/actions/setEarningData";
import {redirectUserWhenNotLoggedIn} from "../../utils/authFormUtils";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import BrowserStorage from "../../utils/browserStorageUtils";
import setAffordabilityData from "../../store/actions/setAffordabilityData";
import {
  MIN_EQUITY_PERCENTAGE,
  SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE
} from "../../constants";
import {clearErrorStore} from "../../utils/errorUtils";

class EligibilityTestPage extends Component {
  state = {
    activeComponentIndex: 0,
    property_value: this.props.mortgageApplicationData.property_value,
    monthly_income: this.props.monthly_income,
    additional_income: this.props.additional_income,
    down_payment: this.props.mortgageApplicationData.down_payment,
    loan_amount: this.props.affordabilityData.loan_amount,
    maxLoanAmount: this.props.affordabilityData.maxLoanableAmount,
    dob: this.props.affordabilityData.dob,
    tenure: this.props.affordabilityData.tenure || 10,
    equity_percentage:
      this.props.employment_type === "selfemployed"
        ? SELF_EMPLOYED_MIN_EQUITY_PERCENTAGE
        : MIN_EQUITY_PERCENTAGE,
    employment_type: "",
    serverResponse: null,
    errors: []
  };

  componentDidMount() {
    const token = BrowserStorage.getUserToken();
    if (!token)
      return redirectUserWhenNotLoggedIn(
        this.props.history,
        this.props.setIsAuthenticated
      );
  }

  componentWillUnmount() {
    const {
      loan_amount,
      down_payment,
      monthly_income,
      additional_income,
      property_value
    } = this.state;
    const {
      setEarningData,
      affordabilityData,
      mortgageApplicationData,
      setAffordabilityData,
      setMortgageApplicationData
    } = this.props;
    setAffordabilityData({...affordabilityData, loan_amount});
    setEarningData({monthly_income, additional_income});
    setMortgageApplicationData({
      ...mortgageApplicationData,
      down_payment,
      property_value
    });
    clearErrorStore();
  }

  /**
   * Sets new state values for the component
   * It allows child components to update the parent component's state.
   * @param {object} pairs
   */
  setStateValue = pairs => this.setState(pairs);

  getStateValues = () => {
    const data = {...this.state};
    delete data.activeComponentIndex;
    return data;
  };

  /**
   * Gets the active component of the parent (this) component
   * @returns {object} react component
   */
  getActiveComponent = () => {
    const {
      property_value,
      monthly_income,
      additional_income,
      loan_amount,
      maxLoanAmount,
      tenure,
      dob,
      down_payment,
      employment_type,
      serverResponse
    } = this.state;
    const {
      history: {push: goToRoute},
      setMortgageApplicationData,
      affordabilityData,
      setEarningData,
      setAffordabilityData
    } = this.props;
    const activeComponentProperties = [
      {
        component: LandingStepForm,
        props: {
          property_value,
          down_payment,
          loan_amount,
          maxLoanAmount,
          employment_type
        }
      },
      {
        component: LandingStepFormOne,
        props: {
          dob,
          tenure,
          setEarningData,
          monthly_income,
          additional_income,
          affordabilityData,
          setAffordabilityData,
          setMortgageApplicationData,
          getParentState: this.getStateValues,
          fetchEligibilityData: this.props.fetchEligibilityData
        }
      },
      // {
      //   component: LandingStepFormTwo,
      //   props: {
      //     yob,
      //     getParentState: this.getStateValues,
      //     fetchEligibilityData: this.props.fetchEligibilityData,
      //     setMortgageApplicationData,
      //     setEarningData,
      //     affordabilityData,
      //     setAffordabilityData
      //   }
      // },
      {
        component: EligibilitySuccess,
        props: {serverResponse: {...serverResponse, loan_amount}, goToRoute}
      },
      { component: EligibilityErrors, props: { errors: this.state.errors } }
    ][this.state.activeComponentIndex];
    const ActiveComponent = activeComponentProperties.component;
    const props = activeComponentProperties.props;

    return (
      <ActiveComponent
        setParentState={this.setStateValue}
        componentIndex={this.state.activeComponentIndex}
        {...props}
      />
    );
  };

  render() {
    const {affordabilityData} = this.props;
    const {property_value} = this.state;
    return (
      <div className='container-fluid px-0'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            Eligibility Test - Finance Plus | Real Estate, NHF, Mortgages &amp;
            Home Loans
          </title>
          <link rel='canonical' href='http://financeplus.ng/eligibility-test' />
        </Helmet>
        <Header history={this.props.history} type='others' />
        <section id='fp-eligibility-test-application'>
          <div className='container-fluid'>
            <div className='row fp-eligibility-test-application'>
              <div className='col-md-7 fp-eligibility-test-application-form'>
                {this.getActiveComponent()}
              </div>
              <SaveResultSidebar {...{affordabilityData, property_value}} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    affordabilityData: state.affordabilityData,
    monthly_income: state.earningData.monthly_income,
    additional_income: state.earningData.additional_income,
    errors: state.errors,
    mortgageApplicationData: state.mortgageApplicationData,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEligibilityData(data, componentIndex, setParentState) {
      dispatch(fetchEligibilityData(data, componentIndex, setParentState));
    },
    setMortgageApplicationData(mortgageApplicationData) {
      dispatch(setMortgageApplicationData(mortgageApplicationData));
    },
    setAffordabilityData(affordabilityData) {
      dispatch(setAffordabilityData(affordabilityData));
    },
    setEarningData(earningData) {
      dispatch(setEarningData(earningData));
    },
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibilityTestPage);
