import React, {Component} from "react";
import * as Icon from "react-feather";

import {handleCurrencyInputChange} from "../../utils/eligibilityFormUtils";
import {clearCommas, formatCurrencyInput} from "../../utils/currencyUtils";
import {handleChange, validateForm} from "../../utils/authFormUtils";
import { yobRegexString, currencyRegexString } from "../../utils/regexUtils";
import OverAgedModal from "./OverAgedModal";
import {
  currencyFieldErrorMessage,
  requiredFieldErrorMessage
} from "../../utils/validationMessageUtils";
import DatePicker from "../DatePicker";
import { getYearFromDOB } from "../../utils/formatDate";

const radioValues = ["yes", "no"];

class AffordabilityForm extends Component {
  /**
   * Handle click event on the "Check Again" button
   * Resets variables
   */
  handleCheckAgainClick = ({target}) =>
    this.props.handleCheckAgainClick({target});

  /**
   * Handles change event of the currency input fields
   * @param {DOMEvent} event
   */
  handleCurrencyInputChange = event =>
    handleCurrencyInputChange(event, this.props.parent);

  handleChange = event => handleChange(event, this.props.parent);

  handleDateChange = (dob) => this.props.parent.setState({ dob });

  /**
   * Handles change event on the "Borrowing with a partner" radio
   * @param {object} event
   */
  handleIsCoBorrowing = ({target: {value}}) => {
    const data = {co_borrower: value};
    if (value === "no") data.co_borrower_gross_income = "";
    this.props.parent.setState(data);
  };

  /**
   * Handles submit event for the form
   * @param {DOMEvent} event
   */
  handleSubmit = event => {
    const invalidInput = validateForm(event);
    const {target} = event;
    let {submitCount, location} = this.props.state;

    if (submitCount) return this.props.goToEligibility({target});
    if (invalidInput) return invalidInput.focus();

    const data = {...this.props.state};
    data.monthly_income = clearCommas(data.monthly_income);
    data.additional_income = clearCommas(data.additional_income);
    data.other_obligations = clearCommas(data.other_obligations);
    data.yob = getYearFromDOB(data.dob);
    delete data.submitCount;
    delete data.isNhf;
    delete data.maxLoanableAmount;

    const doAfterSuccess = () => {
      this.props.parent.setState({submitCount: submitCount + 1, location});
    };
    this.props.fetchAffordabilityData(data, doAfterSuccess);
  };

  render() {
    const {submitCount, dob, monthly_income} = this.props.state;
    const {isLoading, form} = this.props;
    let maxTenure = 30,
      minTenure = 1;
    if (dob) {
      const [dd, mm, yyyy] = dob.split('/');
      const yob = new Date([`${mm}/${dd}/${yyyy}`]).getFullYear();
      maxTenure = 60 - (new Date().getFullYear() - yob);
      if (!(maxTenure >= 1)) {
        maxTenure = 0;
        minTenure = 0;
      } else if (maxTenure >= 30) {
        maxTenure = 30;
      }
    }

    const monthlyIncomeSatisfiesMinimumWage =
      parseFloat(clearCommas(monthly_income)) >= 30000;
    const isCoBorrowing = this.props.state.co_borrower === "yes";
    const hasLoanObligations =
      this.props.state.have_other_obligations === "yes";
    const hasAdditionalIncome =
      this.props.state.have_additional_income === "yes";

    return (
      <div className='affordability-forms-wrapper'>
        <form ref={form} onSubmit={this.handleSubmit} noValidate>
          <div className='form-group row'>
            <div className='col-md-12'>
              <div className='d-flex justify-content-between fp-mortgage-application-tooltip'>
                <label>
                  What is your Net monthly income? <sup>*</sup>
                </label>
                {/* <span className=''>
                  <Icon.Info size='14px' color='#00b1ab' />
                </span> */}
                {/* <span className='tooltiptext'>
                  <Icon.AlertCircle
                    size='20px'
                    color='#00b1ab'
                    className='mr-2'
                  />
                  Calculate the home loan you qualify for, and how much you can
                </span> */}
              </div>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text px-4 bg-white'>₦</span>
                </div>
                <input
                  data-state-name='monthly_income'
                  type='text'
                  className='form-control'
                  value={formatCurrencyInput(this.props.state.monthly_income)}
                  maxLength='15'
                  pattern={currencyRegexString}
                  onChange={this.handleCurrencyInputChange}
                  required
                />
                <div className='input-group-append'>
                  <span className='input-group-text px-3 bg-theme-button-group'>
                    Monthly
                  </span>
                </div>
                <div className='invalid-feedback'>
                  {currencyFieldErrorMessage}
                </div>
              </div>
              {!monthlyIncomeSatisfiesMinimumWage ? (
                <div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={yobRegexString}
                    value={"bola"}
                    style={{display: "none"}}
                    onChange={() => {}}
                  />
                  <div className='invalid-feedback'>
                    Income must satisfy the minimum wage (₦ 30,000)
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <label>
                Do you have additional income? <sup>*</sup>
              </label>
              <div className='fp-home-type-checkbox'>
                <div className='col-md-6'>
                  <input
                    id='have_additional_income_yes'
                    type='radio'
                    name='have_additional_income'
                    value='yes'
                    data-state-name='have_additional_income'
                    checked={hasAdditionalIncome}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor='have_additional_income_yes'>Yes</label>
                </div>
                <div className='col-md-6'>
                  <input
                    id='have_additional_income_no'
                    type='radio'
                    name='have_additional_income'
                    value='no'
                    data-state-name='have_additional_income'
                    checked={this.props.state.have_additional_income === "no"}
                    onChange={this.handleChange}
                  />
                  <label htmlFor='have_additional_income_no'>No</label>
                </div>
              </div>
              {!radioValues.includes(
                this.props.state.have_additional_income
              ) ? (
                <div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={yobRegexString}
                    value={"bola"}
                    style={{display: "none"}}
                    onChange={() => {}}
                  />
                  <div className='invalid-feedback'>
                    {requiredFieldErrorMessage}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {hasAdditionalIncome ? (
            <div className='form-group row'>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between'>
                  <label>
                    How much additional income do you earn monthly?{" "}
                  </label>
                  <span>
                    <Icon.Info size='14px' color='#00b1ab' />
                  </span>
                </div>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text px-4 bg-white'>₦</span>
                  </div>
                  <input
                    data-state-name='additional_income'
                    type='text'
                    className='form-control'
                    value={formatCurrencyInput(
                      this.props.state.additional_income
                    )}
                    maxLength='15'
                    pattern={`${currencyRegexString}|0`}
                    onChange={this.handleCurrencyInputChange}
                    required
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text px-3 bg-theme-button-group'>
                      Monthly
                    </span>
                  </div>
                  <div className='invalid-feedback'>
                    {currencyFieldErrorMessage}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className='form-group row'>
            <div className='col-md-12'>
              <label>
                Do you have other loan obligations? <sup>*</sup>
              </label>
              <div className='fp-home-type-checkbox'>
                <div className='col-md-6'>
                  <input
                    id='have_other_obligations_yes'
                    type='radio'
                    name='have_other_obligations'
                    value='yes'
                    data-state-name='have_other_obligations'
                    checked={hasLoanObligations}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor='have_other_obligations_yes'>Yes</label>
                </div>
                <div className='col-md-6'>
                  <input
                    id='have_other_obligations_no'
                    type='radio'
                    name='have_other_obligations'
                    value='no'
                    data-state-name='have_other_obligations'
                    checked={this.props.state.have_other_obligations === "no"}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor='have_other_obligations_no'>No</label>
                </div>
              </div>
              {!radioValues.includes(
                this.props.state.have_other_obligations
              ) ? (
                <div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={yobRegexString}
                    value={"bola"}
                    style={{display: "none"}}
                    onChange={() => {}}
                  />
                  <div className='invalid-feedback'>
                    {requiredFieldErrorMessage}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {hasLoanObligations ? (
            <div className='form-group row'>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between'>
                  <label>Current Monthly Loan Repayments (if any)</label>
                  <span>
                    <Icon.Info size='14px' color='#00b1ab' />
                  </span>
                </div>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text px-4 bg-white'>₦</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    data-state-name='other_obligations'
                    pattern={`${currencyRegexString}`}
                    value={this.props.state.other_obligations}
                    onChange={this.handleCurrencyInputChange}
                    required
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text px-3 bg-theme-button-group'>
                      Monthly
                    </span>
                  </div>
                  <div className='invalid-feedback'>
                    {currencyFieldErrorMessage}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className='form-group row'>
            <div className='col-md-12'>
              <div className='d-flex justify-content-between'>
                <label>
                  Date of Birth
                  <sup>*</sup>
                </label>
                {/* <span>
                  <Icon.Info size='14px' color='#00b1ab' />
                </span> */}
              </div>
              {
                (() => {
                  const getAppendants = (toggleDatePickerDisplay) => [
                    (
                      <div key={0} className='input-group-append'>
                        <span className='input-group-text px-3 bg-theme-button-group'>
                          <Icon.Calendar color='#00b1ab' onClick={toggleDatePickerDisplay} />
                        </span>
                      </div>
                    ),
                    <div key={1} className='invalid-feedback'>Value is required</div>,
                    maxTenure ? (
                      ""
                    ) : (
                      <div key={2} className='too-old-option'>
                        <p>
                          You fall into a special mortgage category,
                          <a
                            className='fp-too-old-contact-modal text-danger'
                            href='#modalContactUS'
                            data-toggle='modal'
                          >
                            {" "}
                            Click here to continue
                          </a>
                        </p>
                      </div>
                    )
                  ];
                  const thisYear = new Date().getFullYear();
                  const minimumYear = thisYear - 59;
                  const maximumYear = thisYear - 10;
                  return (
                      <DatePicker
                        {...{
                          value: dob,
                          minimumYear,
                          maximumYear,
                          getAppendants,
                          requireValue: true,
                          format: "DD/MM/YYYY",
                          inputClassName: 'form-control',
                          parentClassName: 'input-group',
                          handleDateChange: this.handleDateChange,
                        }}
                      />
                  );
                })()
              }
            </div>
          </div>

          <div className='form-group row'>
            <div className='col-md-12'>
              <label>
                Are you borrowing with a partner? <sup>*</sup>
              </label>
              <div className='fp-home-type-checkbox'>
                <div className='col-md-6'>
                  <input
                    type='radio'
                    id='JointLoanUser'
                    name='loanType'
                    value='yes'
                    checked={isCoBorrowing}
                    onChange={this.handleIsCoBorrowing}
                  />
                  <label htmlFor='JointLoanUser' className='fp-radio-wrapper'>
                    {/* <div className='icon' style={styles.JointLoanUser}></div> */}
                    Yes, with a partner
                  </label>
                </div>
                <div className='col-md-6'>
                  <input
                    type='radio'
                    id='SingleLoanUser'
                    name='loanType'
                    value='no'
                    checked={this.props.state.co_borrower === "no"}
                    onChange={this.handleIsCoBorrowing}
                  />
                  <label htmlFor='SingleLoanUser' className='fp-radio-wrapper'>
                    {/* <div className='icon' style={styles.SingleLoanUser}></div> */}
                    No, I am borrowing alone
                  </label>
                </div>
              </div>
              {!radioValues.includes(this.props.state.co_borrower) ? (
                <div>
                  <input
                    type='text'
                    className='form-control'
                    pattern={yobRegexString}
                    value={"bola"}
                    style={{display: "none"}}
                    onChange={() => {}}
                  />
                  <div className='invalid-feedback'>
                    {requiredFieldErrorMessage}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className='form-group row'>
            {isCoBorrowing ? (
              <div className='co-borrowing-fields'>
                <div className='col-md-12 form-group group-2'>
                  <label>What is your partner's Net monthly income?</label>
                  <div className='input-group mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>₦</span>
                    </div>
                    <input
                      type='text'
                      className='form-control'
                      disabled={!isCoBorrowing}
                      autoFocus
                      pattern={currencyRegexString}
                      maxLength='19'
                      data-state-name='co_borrower_gross_income'
                      value={this.props.state.co_borrower_gross_income}
                      onChange={this.handleCurrencyInputChange}
                      required
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text px-5 bg-theme-button-group'>
                        Monthly
                      </span>
                    </div>
                    <div className='invalid-feedback invalid'>
                      {currencyFieldErrorMessage}
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`col-md-12 ${
                    this.state.co_borrower === "yes" ? "" : " hide"
                  } form-group group-3`}
                >
                  <span className='fp-form-numbering'>Q4B</span>
                  <label>
                    How much additional income does your partner earn monthly?
                  </label>
                  <div className='input-group mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text px-4 bg-white'>₦</span>
                    </div>
                    <input
                      type='text'
                      className='form-control'
                      disabled={this.state.co_borrower === "yes" ? false : true}
                      pattern={currencyRegexString}
                      maxLength='19'
                      data-state-name='co_borrower_additional_income'
                      value={this.state.co_borrower_additional_income}
                      onChange={this.handleCurrencyInputChange}
                      required
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text px-lg-5 bg-theme-button-group'>
                        Monthly
                      </span>
                    </div>
                    <div className='invalid-feedback invalid'>
                      Value is required and can only be a sequence of (comma
                      separated) digits
                    </div>
                  </div>
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          {maxTenure ? (
            <div className='form-group row'>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between'>
                  <label>
                    How long do you want this loan for?
                    <sup>*</sup>
                  </label>
                  {/* <span>
                    <Icon.Info size='14px' color='#00b1ab' />
                  </span> */}
                </div>
                <div className='range'>
                  <input
                    data-state-name='tenure'
                    type='range'
                    min={minTenure}
                    max={maxTenure}
                    value={this.props.state.tenure}
                    onChange={this.handleChange}
                  />
                  <div className='d-flex justify-content-between'>
                    <span>{minTenure}</span>
                    <span>{maxTenure}</span>
                  </div>
                  <div className='range-output'>
                    <output
                      className='output'
                      name='output'
                      htmlFor='range'
                      style={{
                        transform:
                          "translate(" +
                          (this.props.state.tenure - 1) * 17.806 +
                          "px, 20px)"
                      }}
                    >
                      {this.props.state.tenure} years
                    </output>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className='form-group row'>
            <div className='col-md-12'>
              <div className='d-flex justify-content-between'>
                <label>
                  Loan interest rate? <sup>*</sup>
                </label>
                {/* <span>
                  <Icon.Info size='14px' color='#00b1ab' />
                </span> */}
              </div>
              <div className='range'>
                <input
                  data-state-name='rate'
                  type='range'
                  min='0'
                  max='30'
                  step='0.5'
                  value={this.props.state.rate}
                  onChange={this.handleChange}
                />
                <div className='d-flex justify-content-between'>
                  <span>%</span>
                  <span>%</span>
                </div>
                <div className='range-output'>
                  <output
                    className='output'
                    name='output'
                    htmlFor='range'
                    style={{
                      transform:
                        "translate(" +
                        this.props.state.rate * 15.096 +
                        "px,20px)"
                    }}
                  >
                    {this.props.state.rate}% <sup>/yr</sup>
                  </output>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <div className='d-flex justify-content-between'>
                <label>Preferred Location</label>
                {/* <span>
                  <Icon.Info size='14px' color='#00b1ab' />
                </span> */}
              </div>
              <div className='input-group'>
                <input
                  data-state-name='location'
                  type='text'
                  className='form-control'
                  placeholder='eg Lekki, Ajah'
                  value={this.props.state.location}
                  onChange={this.handleChange}
                />
                <div className='input-group-append'>
                  <span className='input-group-text px-3 bg-theme-button-group'>
                    <Icon.MapPin color='#495057' />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12 fp-affordability-test-application-form-buttons'>
              <button
                type='button'
                className='btn fp-save-result-button'
                onClick={this.handleCheckAgainClick}
                disabled={isLoading}
              >
                <Icon.RefreshCw size='22px' color='#00b1ab' />
              </button>
              <button
                type='submit'
                className='btn fp-check-loan-availability'
                disabled={isLoading || !maxTenure}
              >
                {submitCount
                  ? "Check Loan Eligibility"
                  : "Check Loan Affordability"}
              </button>
            </div>
          </div>
        </form>
        <OverAgedModal {...{monthly_income, dob}} />
      </div>
    );
  }
}

export default AffordabilityForm;
