import React from 'react';


const Slide = ({ image, appRef }) => {
  return (
    <div className="mySlide">
      <div className="download-btn-wrapper">
          <a
            href={ image.src }
            className="download-btn"
            download={ `${image.name}-${appRef}` }
            >
            Download
          </a>
        </div>
      <img src={ image.src } alt="" style={{ width: '100%' }} />
    </div>
  );
}
 
export default Slide;