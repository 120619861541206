import React from "react";
import "./MultiStepFormButtons.css";
import "../Buttons/Buttons.css";
import { connect } from 'react-redux';


const MultiStepFormButtons = ({ handleBackClick, isLoading, submitText, type }) => {
  let wrapper;
  if (type === "Eligibility-Test") {
    wrapper = (
      <div className='fp-multi-step-form-wrapper half'>
        <div className='fp-multi-step-form-buttons'>
          <button
            type='button'
            className='fp-multi-step-button-white'
            onClick={handleBackClick}
            disabled={ isLoading }
          >
            Back
          </button>
          <button
            type='submit'
            className='fp-multi-step-button-theme'
            disabled={ isLoading }
          >
            Continue
          </button>
        </div>
      </div>
    );
  } else if (type === "Mortgage-Application") {
    wrapper = (
      <div className='fp-multi-step-form-wrapper full'>
        <div className='fp-multi-step-form-buttons'>
          <button
            type='button'
            className='fp-multi-step-button-white'
            onClick={handleBackClick}
            disabled={ isLoading }
          >
            Back
          </button>
          {/* <p className='fp-multi-step-form-text'>
            About <span className='fp-multi-step-number'>9</span>
            {""} loan options available
          </p> */}
          <button
            type='submit'
            className='fp-multi-step-button-theme'
            disabled={ isLoading }
          >
            { submitText ? submitText : 'Continue' }
          </button>
        </div>
      </div>
    );
  }
  return <div>{wrapper}</div>;
};
 

const mapStateToProps = (state, ownProps) => {
  return { isLoading: state.isLoading, ...ownProps };
};

export default connect(mapStateToProps)(MultiStepFormButtons);
