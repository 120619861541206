import React from 'react';

import AdminDashboardApplicationListItem from './AdminDashboardApplicationListItem';


const AdminDashboardApplicationList = ({ applications, goToRoute }) => {
  return (
    <ul className='fp-user-mortgage-applications-list responsive-table '>
      <li className='table-header'>
        <div className='col col-2'>Reference</div>
        <div className='col col-2'>Customer</div>
        <div className='col col-2'>Lender</div>
        <div className='col col-2'>Loan Amount</div>
        <div className='col col-2'>Date</div>
        <div className='col col-2'>Status</div>
        <div className='col col-1'></div>
      </li>
      {
        applications.length ? applications.map((application) => {
          return (<AdminDashboardApplicationListItem
            application={ application }
            goToRoute={ goToRoute }
            key={ application.app_ref }
          />)
        }) : ''
      }
    </ul>
  );
}
 

export default  AdminDashboardApplicationList;