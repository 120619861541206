import React, { Component } from 'react';
import { handleChange, validateForm } from '../../utils/authFormUtils';
import { logout } from '../../utils/dashboardUtils';
import http from '../../config/axios.config';


class UpdatePasswordForm extends Component {
  state = { new_password: '', repeat_password: '' };

  /**
   * Handles change event on the form fields
   * @param {object} event
   */
  handleChange = (event) => handleChange(event, this);

  /**
   * Handles submit event on the form
   * @param {object} event
   */
  handleSubmit = (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    this.changePassword();
  };

  /**
   * Handles server request to update user profile
   * @param {object} formElement
   */
  changePassword = async () => {
    const { new_password: password } = this.state;
    const { setToasterState } = this.props;
    try {
      await http.post('/changepassword', { password });
      setToasterState(true, 'Password changed successfully');
      const timeout = setTimeout(() => {
        setToasterState(false, '');
        logout(this.props.setIsAuthenticated, this.props.history);
        clearTimeout(timeout);
      }, 2000);      
    } catch (error) {}
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className='fp-mortgage-application-settings-form mt-4'>
        <h2 className='fp-mortgage-application-settings-form-title'>
          Password
        </h2>
        <p className='fp-mortgage-application-settings-form-subtitle'>
          Change your password.
        </p>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='form-group row'>
            <div className='col-md-6'>
              <label>New Password</label>
              <input
                type='password'
                className='form-control'
                data-state-name='new_password'
                value={ this.state.new_password }
                onChange={ this.handleChange }
                minLength='6'
                placeholder='Password'
                autoComplete='new-password'
                required
              />
              <div className='invalid-feedback'>
                Password must be at least 6 characters long
              </div>
            </div>
  
            <div className='col-md-6'>
              <label>Repeat New Password</label>
              <input
                type='password'
                className='form-control'
                data-state-name='repeat_password'
                value={ this.state.repeat_password }
                onChange={ this.handleChange }
                pattern={ `^(?:${this.state.new_password})$` }
                placeholder='Repeat password'
                autoComplete='new-password'
                required
              />
              <div className='invalid-feedback'>
                Password must match
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <button
                type='submit'
                disabled={isLoading}
                className='fp-change-account-password-button'
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
 
export default UpdatePasswordForm;