import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import DashboardBreadCrumb from "../DashboardBreadCrumb";
import CircularLoader from "../CircularLoader";
import "./LenderMortgageApplicationTable.css";

import * as Icon from "react-feather";
import setIsAuthenticated from "../../store/actions/setIsAuthenticated";
import { BASE_URL } from "../../constants";
import { extractErrors, clearErrorStore } from "../../utils/errorUtils";
import setLoading from "../../store/actions/setLoading";
import setErrors from "../../store/actions/setErrors";
import VerticalApplicationListItem from "../DashboardMortgageApplicationTable/VerticalApplicationListItem";
import { handleChange } from "../../utils/authFormUtils";
import { fetchApplications } from "../../utils/dashboardUtils";

class LenderMortgageApplicationTable extends Component {
  state = {
    applications: [],
    filterValue: ''
  };

  componentDidMount() {
    this.fetchApplications();
  }

  componentWillUnmount() {
    clearErrorStore();
  }

  handleChange = (event) => handleChange(event, this);

  /**
  * Fetches all applications from the server
  */
  fetchApplications = async () => {
    try {
      const applications = await fetchApplications('/lender/all-applications');
      this.setState({ applications });
    } catch (error) {}
  };

  render() {
    const {
      isLoading,
      history: { push },
      location: { search }
    } = this.props;
    const { applications } = this.state;

    return (
      <div className='container-fluid px-0'>
        <CircularLoader isLoading={isLoading} />
        <DashboardHeader history={this.props.history} />
        <DashboardSidebar type='User' />
        <div className='fp-mortgage-content-wrapper'>
          <DashboardBreadCrumb
            type='Mortgage Application'
            title='Mortgage Applications'
          />
          <div className='container'>
            <div className='fp-dashboard-section'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='fp-mortgage-applications-all'>
                    <div className='fp-mortgage-applications-multi-tab-actions-buttons'>
                      <div className='fp-mortgage-applications-multi-tab'>
                        <div className=''>
                          <Icon.Search
                            color='#bbbbbb'
                            className='fp-customer-search-icons'
                            size='20'
                          />
                          <input
                            type='text'
                            className='form-control fp-customer-search'
                            data-state-name='filterValue'
                            value={ this.state.filterValue }
                            onChange={ this.handleChange }
                          />
                        </div>
                      </div>
                      <div
                        className='fp-mortgage-applications-actions-buttons'
                        style={{ marginTop: "5px" }}
                      >
                        <Link
                          className='filter fp-dashboard-chat-message-actions'
                          to='/'
                        >
                          <Icon.Filter color='#8c8c8c' /> Filter
                        </Link>
                        <Link className='fp-dashboard-more-actions' to='/'>
                          Actions <Icon.MoreHorizontal color='#fff' />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='fp-user-mortgage-applications-wrapper no-border'>
                    <ul className='fp-user-mortgage-applications-list responsive-table '>
                      <li className='table-header'>
                        <div className='col col-2'>Reference</div>
                        <div className='col col-2'>Customer</div>
                        <div className='col col-2'>Phone</div>
                        <div className='col col-2'>Date</div>
                        <div className='col col-2'>Loan Amount</div>
                        <div className='col col-2'>Status</div>
                        <div className='col col-1'></div>
                      </li>
                      
                      {
                        applications.filter((application) => {
                          const queryValue = search.split('=')[1];
                          return !queryValue ? true : application.mortgage_status_name.toLowerCase() === queryValue;
                        }).filter((application) => {
                          const { filterValue } = this.state;
                          const { firstname, lastname, app_ref, loan_amount } = application;
                          const amount = parseFloat(filterValue);

                          return [
                            firstname.toLowerCase(), lastname.toLowerCase(), app_ref.toLowerCase()
                            ].join('').includes(filterValue.toLowerCase()) || (!isNaN(amount) && parseFloat(loan_amount) <= amount);
                        }).map((application) => {
                          return (
                            <VerticalApplicationListItem
                              { ...{ application, goToRoute: push } }
                              key={ application.app_ref }
                            />
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.isLoading,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsAuthenticated(isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated));
    },
    setLoading(isLoading) { dispatch(setLoading(isLoading)); },
    setErrors(errors) { dispatch(setErrors(errors)); }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LenderMortgageApplicationTable);
