import React, { useRef } from 'react';
import { File } from 'react-feather';
import LightBox from '../Lightbox';
import PDFViewer from '../PDFViewer';


const ApplicationFileIcon = ({ data: { doc_name, filename }, appRef }) => {
  const modalRef = useRef(null);
  const { length, [length - 1]: fileExt } = filename.split('.');
  return (
    <div
      className='fp-mortgage-loan-documents-uploads'
      onClickCapture={
        ({ currentTarget }) => {
          const displayStyle = fileExt === 'pdf' ? 'block' : 'flex';
          modalRef.current.style.display = displayStyle;
        }
      }
    >
      <File color='#555555' size={40} />
      <span className='fp-document-badge badge badge-primary'>
        { doc_name }
      </span>

      {
        (() => {
          return fileExt === 'pdf' ? (
            <PDFViewer
              file={{ filename, doc_name, appRef }}
              modalRef={ modalRef }
            />
          ) : (
            <LightBox
              image={{ src: filename, name: doc_name }}
              {...{ appRef, modalRef }}
            />
          );
        })()
      }
    </div>
  );
}
 
export default ApplicationFileIcon;