import React, {Component} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import * as Icon from "react-feather";

import Header from "../Header";
import "./AffordabilityTestPage.css";
import "./Responsive.css";
import "../Buttons/Buttons.css";
import AffordabilityForm from "./AffordabilityForm";
import fetchAffordabilityData from "../../store/middlewares/fetchAffordabilityData";
import setErrors from "../../store/actions/setErrors";
import {formatCurrencyInput} from "../../utils/currencyUtils";
import setAffordabilityData from "../../store/actions/setAffordabilityData";
import setEarningData from "../../store/actions/setEarningData";
import setMortgageApplicationData from "../../store/actions/setMortgageApplicationData";

import PropertySuggestionList from "../PropertySuggestionList";
import BrowserStorage from "../../utils/browserStorageUtils";
import {
  PENDING_DESTINATION,
  DEFAULT_AFFORDABILITY_DATA,
  DEFAULT_EARNING_DATA,
  DEFAULT_MORTGAGE_APPLICATION_DATA
} from "../../constants";
import { clearErrorStore } from "../../utils/errorUtils";


class AffordabilityTestPage extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.navbarLoginBtn = React.createRef();
  }

  defaultState = {
    ...this.props.affordabilityData,
    ...this.props.earningData,
    submitCount: 0,
    location: ""
  };
  state = {...this.defaultState};

  async componentDidMount() {
    const {
      location: {search: query},
      mortgageApplicationData,
      setMortgageApplicationData,
      affordabilityData: {isNhf}
    } = this.props;
    const [queryParam, property_value] = query.split("=");
    if (queryParam === "?amount" && property_value) {
      setMortgageApplicationData({
        ...mortgageApplicationData,
        property_value
      });
    }

    // Reset affordability to zeros
    if (isNhf) this.handleCheckAgainClick();
  }

  /**
   * Handle click event on the "Check Again" button
   * Resets variables
   */
  handleCheckAgainClick = async () => {
    if (this.form.current) this.form.current.classList.remove("was-validated");
    const {setAffordabilityData, setEarningData} = this.props;
    await setAffordabilityData({...DEFAULT_AFFORDABILITY_DATA});
    await setEarningData({...DEFAULT_EARNING_DATA});
    const {earningData, affordabilityData} = this.props;
    this.setState({
      ...affordabilityData,
      ...earningData,
      submitCount: 0,
      location: ""
    });
  };

  componentWillUnmount() {
    clearErrorStore();
  }

  componentDidUpdate(_, prevState) {
    const {dob, tenure: tenureString} = this.state;
    if (dob && prevState.dob !== dob) {
      const [dd, mm, yyyy] = dob.split('/');
      const yob = new Date([`${mm}/${dd}/${yyyy}`]).getFullYear();
      const maxTenure = 60 - (new Date().getFullYear() - yob);
      const tenure = parseInt(tenureString, 10);
      const newTenure =
        maxTenure < 1
          ? 0
          : tenure >= maxTenure
          ? maxTenure
          : tenure < 1
          ? 1
          : tenure;
      this.setState({tenure: newTenure});
    }
  }

  /**
   * Navigates to the eligibility component
   */
  goToEligibility = () => {
    if (!this.props.isAuthenticated) {
      BrowserStorage.setItem(PENDING_DESTINATION, "/eligibility-test");
      return this.navbarLoginBtn.current.click();
    } else this.props.history.push("eligibility-test");
  };

  render() {
    const {
      affordabilityData,
      isAuthenticated,
      fetchAffordabilityData,
      mortgageApplicationData,
      setMortgageApplicationData,
      setAffordabilityData,
      setEarningData,
      earningData,
      isLoading,
      setErrors,
      errors
    } = this.props;
    const {loan_amount, monthly_payment, properties} = affordabilityData;

    return (
      <div className='container-fluid px-0 affordability-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            Affordability Test - Finance Plus | Real Estate, Apartments,
            Mortgages &amp; Home Values
          </title>
          <link
            rel='canonical'
            href='http://financeplus.ng/affordability-test'
          />
        </Helmet>
        <Header
          history={this.props.history}
          type='others'
          loginButtonRef={this.navbarLoginBtn}
        />
        <section id='fp-affordability-test-application'>
          <div className='container-fluid'>
            <div className='row fp-affordability-test-application'>
              <div
                className={`col-md-5 fp-affordability-test-application-form push ${
                  properties && properties.properties.length ? "overlay" : ""
                }`}
              >
                <div className='fp-affordability-test-application-form-wrapper'>
                  <div className='row'>
                    <div className='col-md-10 offset-md-1 mt-2'>
                      <h2 className='fp-affordability-test-application-form-title'>
                        How much can I afford?
                      </h2>
                      <p className='fp-affordability-test-application-form-subtitle'>
                        Calculate the home loan you qualify for, and how much
                        you can expect to pay monthly on your home loan
                        repayments.
                      </p>

                      <AffordabilityForm
                        {...{
                          errors,
                          setErrors,
                          isLoading,
                          earningData,
                          setEarningData,
                          isAuthenticated,
                          affordabilityData,
                          form: this.form,
                          setAffordabilityData,
                          fetchAffordabilityData
                        }}
                        state={{...this.state}}
                        goToEligibility={this.goToEligibility}
                        parent={this}
                        handleCheckAgainClick={this.handleCheckAgainClick}
                        //type="NH"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`col-md-7 fp-nhf-affordability-eligibility-application-sidebar ${
                  properties && properties.properties.length ? "show" : ""
                }`}
              >
                {/* <div className='col-md-7 fp-nhf-affordability-eligibility-application-sidebar'> */}
                <div className='fp-nhf-affordability-eligibility-application-sidebar-wrapper'>
                  <div className='d-flex justify-content-between'>
                    <div className='fp-affordability-test-loanable-amount'>
                      <span className=''>Your maximum loanable Amount</span>
                      <h2>₦ {formatCurrencyInput(loan_amount)}</h2>
                    </div>
                    <div className='fp-affordability-test-monthly-repayment'>
                      <span>Estimated Monthly Repayment</span>
                      <h2>₦ {formatCurrencyInput(monthly_payment)}</h2>
                    </div>
                  </div>

                  {/* <div className='fp-affordability-test-loanable-amount'>
                    <span>Your maximum loanable Amount</span>
                    <h2>₦ {formatCurrencyInput(loan_amount)}</h2>
                  </div>
                  <hr></hr>
                  <div className='fp-affordability-test-monthly-repayment'>
                    <span>Estimated Monthly Repayment</span>
                    <h2>₦ {formatCurrencyInput(monthly_payment)}</h2>
                  </div> */}
                  <hr></hr>
                  {properties && properties.properties.length ? (
                    <div className='fp-affordability-test-result-summary'>
                      <div className='fp-affordability-test-result-summary-text'>
                        <h2>Property Suggestion</h2>
                        <p>
                          {properties.found
                            ? "Based on your input, we found you the properties below on newhomes.ng, and I think you’d absolutely love them!"
                            : "We couldn't find properties for your desired location but suggest you take a look at the following."}
                        </p>
                      </div>

                      <div className='fp-affordability-test-property-display'>
                        <PropertySuggestionList
                          {...{
                            properties,
                            mortgageApplicationData,
                            setMortgageApplicationData,
                            goToEligibility: this.goToEligibility
                          }}
                        />
                        <div className='row'>
                          <div className='col-md-12 result fp-affordability-test-application-form-buttons'>
                            <button
                              type='button'
                              className='btn fp-save-result-button'
                              onClick={this.handleCheckAgainClick}
                              disabled={isLoading}
                            >
                              <Icon.RefreshCw size='22px' color='#00b1ab' />
                            </button>

                            <button
                              type='submit'
                              className='btn fp-check-loan-availability'
                              onClick={() => {
                                setMortgageApplicationData(
                                  DEFAULT_MORTGAGE_APPLICATION_DATA
                                );
                                this.goToEligibility();
                              }}
                              disabled={isLoading}
                            >
                              Proceed to check Loan Eligibility
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className='fp-affordability-test-result-bg-pattern'></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    affordabilityData: state.affordabilityData,
    errors: state.errors,
    isLoading: state.isLoading,
    earningData: state.earningData,
    isAuthenticated: state.isAuthenticated,
    mortgageApplicationData: state.mortgageApplicationData,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAffordabilityData(data, doAfterSuccess) {
      dispatch(fetchAffordabilityData(data, doAfterSuccess));
    },
    setErrors(errors) {
      dispatch(setErrors(errors));
    },
    setAffordabilityData(data) {
      dispatch(setAffordabilityData(data));
    },
    setEarningData(data) {
      dispatch(setEarningData(data));
    },
    setMortgageApplicationData(mortgageApplicationData) {
      dispatch(setMortgageApplicationData(mortgageApplicationData));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffordabilityTestPage);
