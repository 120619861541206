import React, { Component } from "react";
import * as Icon from "react-feather";

import MultiStepFormButtons from "../MultiStepFormButtons";

import { DASHBOARD_URL } from "../../constants";
import PersonalInformationPreviewBox from "./PersonalInformationPreviewBox";
import OtherPersonalInfoPreviewBox from "./OtherPersonalInfoPreviewBox";
import EmploymentInfoPreviewBox from "./EmploymentInfoPreviewBox";
import FinancialInfoPreviewBox from "./FinancialInfoPreviewBox";
import LoanInfoPreviewBox from "./LoanInfoPreviewBox";
import ApplicationProgressBar from "./ApplicationProgressBar";
import http from "../../config/axios.config";

class ApplicationPreview extends Component {
  state = { ...this.props.submitData };

  componentDidMount() {
    this.props.setParentState({ gotToPreview: true });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { postStepApplication, goToRoute } = this.props;
    const doAfterSuccess = () => goToRoute(`${DASHBOARD_URL}/uploads`);
    // eslint-disable-next-line no-sparse-arrays
    await postStepApplication(...[this, {...this.state}, 5, true, , doAfterSuccess]);
  };

  postApplicationData = async data => {
    const { goToRoute, setEditApplicationData } = this.props;
    try {
      await http.post('/mortgage/application', data);      
      setEditApplicationData({});
      goToRoute(`${DASHBOARD_URL}/uploads`);
    } catch (error) {}
  };

  goToComponent = activeComponentIndex =>
    this.props.setParentState({ activeComponentIndex });

  getPreviewBoxes = () => {
    const {
      data: {
        firstname, //
        lastname, //
        middlename, //
        email, //
        phone, //
        dob, //
        place_of_birth, //
        sex, //
        nationality, //
        highest_education, //
        address, //
        state_of_origin, //
        profession, //
        employer_name, //
        employer_address, //
        employer_phone, //
        employer_email, //
        other_employments,
        // employment_present_position, //
        // work_experience, //
        // year_to_retirement, //
        current_apartment_status, //
        annual_rent_value, //
        marital_status, //
        spouse_name, //
        spouse_address,
        spouse_employer, //
        spouse_work_experience,
        spouse_profession, //
        spouse_annual_income, //
        children, //
        next_of_kin_name, //
        next_of_kin_relationship, //
        next_of_kin_age,
        next_of_kin_address, //
        total_annual_pay, //
        monthly_gross_pay, //
        monthly_net_pay, //
        monthly_expenses, //
        other_source_of_income, //
        user_assets,
        bank_accounts,
        outstanding_loans,
        have_existing_obligation, //
        down_payment,
        // agreed_to_terms,
        property_value,
        property_title,
        property_address,
        have_employer_guarantor, //
        loan_amount
      }
    } = this.props;

    return [
      (() => {
        const data = {
          firstname,
          lastname,
          middlename,
          address,
          email,
          phone,
          dob,
          sex,
          place_of_birth,
          nationality,
          state_of_origin,
          highest_education,
          profession
        };

        return (
          <PersonalInformationPreviewBox
            goToComponent={this.goToComponent}
            data={data}
          />
        );
      })(),
      (() => {
        const data = {
          current_apartment_status,
          annual_rent_value,
          marital_status,
          spouse_name,
          spouse_employer,
          spouse_annual_income,
          spouse_profession,
          spouse_address,
          spouse_work_experience,
          children,
          next_of_kin_address,
          next_of_kin_name,
          next_of_kin_age,
          next_of_kin_relationship
        };

        return (
          <OtherPersonalInfoPreviewBox
            goToComponent={this.goToComponent}
            data={data}
          />
        );
      })(),
      (() => {
        const data = {
          employer_name,
          employer_email,
          employer_address,
          employer_phone,
          other_employments
        };

        return (
          <EmploymentInfoPreviewBox
            goToComponent={this.goToComponent}
            data={data}
          />
        );
      })(),
      (() => {
        const data = {
          user_assets,
          bank_accounts,
          monthly_net_pay,
          monthly_expenses,
          total_annual_pay,
          monthly_gross_pay,
          outstanding_loans,
          other_source_of_income,
          have_existing_obligation
        };

        return (
          <FinancialInfoPreviewBox
            goToComponent={this.goToComponent}
            data={data}
          />
        );
      })(),
      (() => {
        const data = {
          loan_amount,
          down_payment,
          property_value,
          property_title,
          property_address,
          have_employer_guarantor
        };

        return (
          <LoanInfoPreviewBox goToComponent={this.goToComponent} data={data} />
        );
      })()
    ];
  };

  printForm = () => window.print();

  render() {
    return (
      <div className='container-fluid px-0'>
        {/* <Header /> */}
        <section id='fp-mortgage-application'>
          <div className='container-fluid'>
            <div className='row fp-mortgage-application'>
              <div className='col-md-10 offset-md-1'>
                <div className='fp-mortgage-landing-page-with-nhanla'>
                  <ApplicationProgressBar
                    step={this.props.componentIndex}
                    parentClassName='col-md-10 offset-md-1'
                  />
                  <div className='row'>
                    <form
                      onSubmit={this.handleSubmit}
                      className='col-md-10 offset-md-1 fp-personal-info-form'
                    >
                      <span className='no-print'>Ordinary Mortgage | Application Preview</span>
                      {/* <br />
                      <br /> */}
                      <div
                        className='fp-mortgage-application-preview-printer no-print'
                        onClick={this.printForm}
                      >
                        <Icon.Printer
                          size='30px'
                          color='#00b1ab'
                          className='mr-1'
                        />
                        Print
                      </div>

                      <div className='fp-mortgage-application-preview'>
                        {this.getPreviewBoxes()}
                      </div>

                      <div className='col-md-12 no-print'>
                        <MultiStepFormButtons
                          type='Mortgage-Application'
                          submitText='Submit'
                          handleBackClick={() => {
                            this.props.setParentState({ gotToPreview: false });
                            this.props.goToPreviousComponent();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ApplicationPreview;
